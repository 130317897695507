export default {
  common: {
    title: 'TF 대시보드',
    logout: '로그아웃',
    welcome_system: '관리 시스템에 오신 것을 환영합니다',
    changePassword: '비밀번호 변경',
    status: {
      OPEN: '열림',
      CLOSE: '닫힘',
      TEST: '테스트',
    },
  },
  affiliate: {
    commissionModel: {
      NORMAL: '일반',
      SIMPLE: '단순',
    },
    level: {
      JUNIOR_AFFILIATE: '플레이어',
      SUB_AFFILIATE: '매장',
      AFFILIATE: '부본사',
      SUPER_AFFILIATE: '본사',
      MASTER_AFFILIATE: '총판',
    },
    timeType: {
      MONTHLY: '월간',
      WEEKLY: '주간',
    },
    status: {
      APPLY: '신청',
      NORMAL: '정상',
      DISABLE: '비활성화',
    },
    belongType: {
      OFFICIAL: '공식',
      PACKAGE: '패키지',
    },
  },
  types: {
    NORMAL: '일반',
    TEMPORARY: '임시',
    PERMANENT: '영구',
    gameViolation: '게임 위반',
    memberRequest: '회원 요청',
    others: '기타',
    TEST: '테스트',
    PROMO_TEST: '프로모션 테스트',
    AFFILIATE: '제휴사',
    OUTSIDE: '외부',
  },
  reportName: {
    Aff_Month_Report: '제휴사_월간_보고서',
    Deposit_Record: '입금_기록',
    Withdraw_Record: '출금_기록',
    Privilege_Record: '특권_기록',
    Finance_Report: '재무_보고서',
    Summary_Record: '요약_기록',
    Summary_Active_Record: '활동_요약_기록',
    Summary_Register_Record: '등록_요약_기록',
    Summary_Fdp_Record: 'FDP_요약_기록',
    Summary_Withdraw_Record: '출금_요약_기록',
    Summary_Deposit_Record: '입금_요약_기록',
    Summary_Member_Record: '회원_요약_기록',
    Member_Bet_Record: '회원_베팅_기록',
    Member_Bet_Money_Change: '회원_베팅_금액_변경',
    Daily_Record: '일일_기록',
    Platform_Game_Record: '플랫폼_게임_기록',
  },
  dateType: {
    depositDate: '입금 날짜',
    finishDate: '완료 날짜',
    withdrawDate: '출금 날짜',
    paymentDate: '지불 날짜',
    regTime: '등록 시간',
    recordTime: '기록 시간',
  },
  depositStatus: {
    SUCCESS: '성공',
    SUPPLEMENT_SUCCESS: '보충 성공',
    CLOSED: '닫힘',
    PENDING: '보류 중',
  },
  withdrawStatus: {
    ALL: '전체',
    SUCCESS: '성공',
    FAIL: '실패',
    APPLY: '신청 중',
    STEP_1: '검토 중',
    STEP_2: '지불 대기 중',
    STEP_3: '지불 진행 중',
    AUTOPAY: '자동 지불',
    PENDING: '보류 중',
    REJECT: '거부됨',
    SENDING: '발송 중',
    WAITING_CALLBACK: '콜백 대기 중',
    PAYING: '지불 중',
    WAITING_AUTO_PAY: '자동 지불 대기 중',
  },
  withdrawConfirmStatus: {
    0: '미확인',
    1: '입금됨',
  },
  outboxStatus: {
    0: '아직 응답 없음',
    1: '응답 완료',
  },
  editCheckedStatus: {
    0: '미검토',
    1: '변경 완료',
    2: '검토 실패',
  },
  transfer: {
    status: {
      sending: '발송 중',
      success: '성공',
      fail: '실패',
    },
    type: {
      deposit: '입금',
      withdraw: '출금',
    },
  },
  moneyChange: {
    type: {
      DEPOSIT: '입금',
      WITHDRAW: '출금',
      PROMO: '프로모션',
      TRANSFER: '이체',
      BET: '베팅',
      WITHDRAW_FAIL: '출금 실패',
      ADJUST: '조정',
      PAYOUT: '지급',
      ROLLBACK: '롤백',
      PATCH: '패치',
      BET_N_PAYOUT: '베팅 및 지급',
      AFFILIATE_TRANSFER: '제휴 이체',
      VIP_REBATE: 'VIP 리베이트',
    },
    subType: {
      DEPOSIT: '지갑에서 게임으로',
      WITHDRAW: '게임에서 지갑으로',
      TRASNFER_IN: '이체 받기',
      TRANSFER_OUT: '이체 보내기',
      AFFILIATE_SETTLEMENT: '제휴 정산',
      AFFILIATE_DEPOSIT: '잔액에서 이체',
      AFFILIATE_COMMISSION: '커미션 잔액에서 이체',
    },
  },
  status: {
    monthSummary: {
      UNCLEAR: '불명확',
      CLEARING: '정리 중',
      CLEARED: '정리 완료',
    },
    settlement: {
      CHECKING: '확인 중',
      PAY: '지급',
      CLEARED: '정리 완료',
    },
    member: {
      NORMAL: '정상',
      FROZEN: '동결',
    },
    gameMatch: {
      ACTIVE: '진행 중',
      ENDED: '종료',
    },
    gameMatchRecord: {
      PENDING_MATCH: '경기 종료 대기 중',
      PENDING_SETTLE: '정산 대기 중',
      SETTLED: '정산 완료',
      CANCEL: '취소',
    },
    gameQuiz: {
      ONGOING: '진행 중',
      CANCEL: '취소',
      ENDED: '종료',
    },
    gameQuizAnswer: {
      PENDING: '대기 중',
      CANCEL: '취소',
      WIN: '승리',
      LOSE: '패배',
    },
    gift: {
      OPEN: '열림',
      CLOSE: '닫힘',
    },
    giftRecord: {
      PENDING: '대기 중',
      PROCESSING: '처리 중',
      COMPLETE: '완료',
      EXPIRED: '만료됨',
      FAILED: '실패',
    },
    piggyBank: {
      SAVING: '저축 중',
      CLAIMED: '청구 완료',
    },
    uefaMatch: {
      PENDING: '대기 중',
      ONGOING: '진행 중',
      CANCEL: '취소',
      ENDED: '종료',
    },
    uefaMatchRecord: {
      PENDING: '대기 중',
      LOSE: '패배',
      WIN_MATCH: '경기 승리',
      WIN_TEAM: '팀 승리',
      WIN_ALL: '전체 승리',
      CANCEL: '취소',
    },
  },
  betStatus: {
    BET: '베팅',
    UNSETTLED: '미정산',
    SETTLED: '정산 완료',
    CANCEL: '취소',
    GIVEREWARD: '보상',
  },
  giftType: {
    ENTITY: '실물',
    VIRTUAL: '가상',
  },
  dashboard: {
    memberCount: '회원 수',
    totalFirstDeposit: '총 첫 입금',
    totalDeposit: '총 입금',
    totalTransaction: '거래 횟수',
    totalTransfer: '총 이체',
    totalWithdraw: '총 출금',
    totalWinLoss: '총 승/패',
    totalValidBet: '총 유효 베팅',
    totalBonus: '총 보너스',
    totalProfit: '총 이익',
    returnAmount: '반환 금액',
    totalActiveMember: '총 활성 회원',
    newRegisterMember: '신규 등록 회원',
    totalVisit: '총 방문자',
    depositChartName: '총 입금 금액',
    depositAmount: '입금 금액',
    depositMemberCount: '입금한 회원 수',
    depositTransactionCount: '입금 거래 횟수',
    depositTransactionNumChartName: '회원 입금 횟수 / 입금 거래 횟수',
    financialLevelRatio: '재정 수준 비율',
    vipLevelRatio: 'VIP 수준 비율',
    paymentMethod: '결제 방법',
    ratio: '비율',
    fundAmount: '자금',
    paymentMethodSummary: '결제 방법 입금 요약',
    total: '총계',
    bet: '베팅',
    payout: '지급',
    merchantDepositSummary: '상인 입금 요약',
    onlineStatsCompareChartName: '일별 온라인 통계 비교',
    onlineStatsChartName: '온라인 통계',
    rfdRegisterCount: '회원 등록 수',
    rfdFDepositCount: '회원 첫 입금 수',
    rfdReport: '등록 및 첫 입금 보고서',
  },
  reportGame: {
    gamePlatform: '게임 플랫폼',
    gameMemberCount: '일일 베팅 회원 수',
    gameBetCount: '일일 베팅 거래 수',
    gameBetAmount: '일일 베팅 금액',
    gameWinLoss: '일일 승/패 금액',
    gameMemberCountTotal: '총 베팅 회원 수',
    gameBetCountTotal: '총 베팅 거래 수',
    gameBetAmountTotal: '총 베팅 금액',
    gameWinLossTotal: '총 승/패 금액',
  },
  gameType: {
    ALL: '전체',
    SLOT: '슬롯',
    LIVE: '라이브',
    FISH: '물고기',
    SPORT: '스포츠',
    ESPORT: '이스포츠',
    POKER: '포커',
    LOTTERY: '복권',
    CASUAL: '캐주얼',
    NBA: 'NBA',
    COCKFIGHT: '닭싸움',
    MSI: 'MSI',
  },
  homeBannerType: {
    HOME: '홈',
    LIVE: '라이브',
    SLOT: '슬롯',
    FISH: '물고기',
    POKER: '포커',
    PROMO: '프로모션',
    HOMEPROMO: '홈 프로모션',
    HOMEPOP: '홈 팝업',
    CENTERPROMO: '센터 프로모션',
    LOGIN: '로그인',
    REGISTER: '등록',
    VIP: 'VIP',
  },
  distributeStatus: {
    PENDING: '대기 중',
    IN_PROGRESS: '배포 중',
    DISTRIBUTED: '배포됨',
    CANCEL: '취소',
  },
  referFriendEvent: {
    INFO: '정보 완료',
    FIRST: '첫 입금',
    SECOND: '두 번째 입금',
  },
  rollover: {
    recordStatus: {
      ONGOING: '진행 중',
      COMPLETED: '완료됨',
      VOID: '무효',
      CANCEL: '취소',
    },
  },
  rebateLevel: {
    LEVEL_0: '레벨 0',
    LEVEL_1: '레벨 1',
    LEVEL_2: '레벨 2',
    LEVEL_3: '레벨 3',
    LEVEL_4: '레벨 4',
    LEVEL_5: '레벨 5',
    LEVEL_6: '레벨 6',
    LEVEL_7: '레벨 7',
    LEVEL_8: '레벨 8',
    LEVEL_9: '레벨 9',
    LEVEL_10: '레벨 10',
    LEVEL_11: '레벨 11',
    LEVEL_12: '레벨 12',
    LEVEL_13: '레벨 13',
  },
  gameQuiz: {
    questions: {
      1: '어느 팀이 승리했습니까?',
      2: '핸디캡 (연장 포함)',
      3: '총 라운드 킬 수 (연장 포함)',
      4: '양 팀의 총 라운드/킬 수',
      5: '가장 많은 킬을 기록한 팀?',
      6: '어느 팀이 첫 킬을 기록했습니까?',
    },
    answers: {
      CSGO: {
        handicapAbove: '두 팀은 4.5 이하의 차이가 있습니다',
        handicapBelow: '두 팀은 4.5 이상 차이가 있습니다',
        killBig: '총 라운드 21.5 이상',
        killSmall: '총 라운드 21.5 이하',
        killSignal: '단일',
        killDouble: '더블',
      },
      LOL: {
        handicapAbove: '두 팀은 4.5 이하의 차이가 있습니다',
        handicapBelow: '두 팀은 4.5 이상 차이가 있습니다',
        killBig: '총 킬 25.5 이상',
        killSmall: '총 킬 25.5 이하',
        killSignal: '단일',
        killDouble: '더블',
      },
      DOTA2: {
        handicapAbove: '두 팀은 10.5 이하의 차이가 있습니다',
        handicapBelow: '두 팀은 10.5 이상 차이가 있습니다',
        killBig: '총 킬 49.5 이상',
        killSmall: '총 킬 49.5 이하',
        killSignal: '단일',
        killDouble: '더블',
      },
    },
  },
  promoLabel: {
    new: '신규',
    hot: '인기',
    normal: '일반',
    recommend: '추천',
    daily: '일일',
    newbie: '초보',
    limit: '한정',
    featured: '특집',
  },
  messageType: {
    NOTIFICATION: '알림',
    ACTIVITY: '활동',
    ANNOUNCEMENT: '공지사항',
    PAYMENT: '결제',
  },
  sitePlatform: {
    followType: '유형',
    follow: '팔로우 전',
    new: '게임 유형 업데이트',
  },
  sortType: {
    ASC: '오름차순',
    DESC: '내림차순',
  },
  promoType: {
    WELCOME: '환영',
    SPORT: '스포츠',
    ESPORT: '이스포츠',
    FISH: '물고기',
    LIVECASINO: '라이브 카지노',
    SLOTGAME: '슬롯 게임',
    POKER: '포커',
    DAILY: '일일',
    FTD: '첫 입금',
    LOTTERY: '복권',
    OTHER: '기타',
    LIMITED: 'LIMITED',
    SLOTWELCOME: 'Slot - Welcome',
    SLOTDAILY: 'Slot - Daily Weekly',
    SLOTOTHER: 'Slot - Rebate & Other',
  },
  scheduleType: {
    NORMAL: '일반',
    BET_RECORDS: '베팅 기록',
  },
  summonFailReason: {
    PRIVI_IS_CLOSED: '특권이 종료되었습니다',
    PRIVI_HAS_ENDED: '특권이 만료되었습니다',
    PRIVI_HAVENT_START: '특권이 시작되지 않았습니다',
    SUMMONER_VIP_LEVEL_NOT_REACH: '소환자의 VIP 레벨이 미달입니다',
    MEMBER_VIP_LEVEL_EXCEED_1: '회원의 VIP 레벨이 초과되었습니다',
    MEMBER_HISTORY_DEPOSIT_AMOUNT_NOT_REACH: '회원의 입금 내역이 미달입니다',
    MEMBER_DEPOSIT_IN_PAST_15_DAYS: '회원이 최근 15일 내에 입금했습니다',
    MEMBER_BET_IN_PAST_15_DAYS: '회원이 최근 15일 내에 베팅했습니다',
    MEMBER_AND_SUMMONER_SAME_REGISTER_DETAIL:
      '회원과 소환자의 등록 정보가 동일합니다',
    MEMBER_ALREADY_SUMMONED: '회원이 이미 소환되었습니다',
  },
  smsSend: {
    template1: 'Welcome New Member',
    template2: 'Deposit Promotion',
    template3: 'Welcome Back',
  },
  displayLocation: {
    HOMEPAGE: '홈페이지',
    PROMO_PAGE: '프로모 페이지',
    DEPOSIT_PAGE: '입금 페이지',
    WITHDRAW_PAGE: 'WITHDRAW 페이지',
    PERSONAL_CENTRE: '개인 센터',
  },
  mode: {
    DYNAMIC: '페이지 부동',
    STATIC: '직접 표시',
  },
  autoplayStatus: {
    ON: '켜짐',
    OFF: '끄기',
  },
  valueRulesType: {
    INPUT: '입력',
    RADIO: '라디오 버튼',
    SELECT: '선택 상자',
    SWITCH: '스위치',
    CHECKBOX: '체크박스',
  },
  siteType: {
    main: 'MAIN',
    slot: 'SLOT',
  },
  fields: {
    account: '계정',
    accountHolder: '계정 소유자',
    accountInfo: '계정 정보',
    accountNumber: '계좌 번호',
    accountStatus: '계정 상태',
    accurate: '정확한 검색',
    action: '동작',
    actionType: '동작 유형',
    activeMember: '활동 회원',
    activeMemberCount: '활동 회원 수',
    activePlayer: '활동 플레이어',
    activeUser: '활동 사용자',
    actualRate: '플레이어 비교',
    add: '추가',
    addAds: '광고 팝업 추가',
    addAdjustmentReason: '조정 이유 추가',
    addAffiliate: '제휴사 추가',
    addAffiliateCommission: '제휴 커미션 추가',
    addAffiliateDomain: '제휴 도메인 추가',
    addAffiliateAnnouncement: '제휴사 공지 추가',
    addAffiliateAnnouncementType: '제휴사 공지 유형 추가',
    addAffiliateFinancialConfig: '제휴사 금융 설정 추가',
    addAnnouncement: '공지 추가',
    addAnnouncementType: '공지 유형 추가',
    addBank: '은행 추가',
    addBankCard: '은행 카드 추가',
    addBankToPayment: '결제 은행 추가',
    addBankToWithdrawPlatform: '출금 플랫폼에 은행 추가',
    addBanner: '배너 추가',
    addBbDacha: 'BB Dacha 추가',
    addRewardGroup: '보상 그룹 추가',
    addCurrency: '통화 추가',
    addCurrencyRate: '환율 추가',
    addDepositSetting: '입금 설정 추가',
    addDomain: '도메인 추가',
    addEmail: '이메일 추가',
    addFinancialLevel: '재무 수준 추가',
    addGame: '게임 추가',
    addGameMatch: '게임 매치 추가',
    addGameQuiz: '게임 퀴즈 추가',
    addGift: '선물 추가',
    addIpLabel: 'IP 라벨 추가',
    additionalTicketDetail: '추가 티켓 상세 정보',
    additionalKey: '추가 키',
    addJob: '작업 추가',
    addLimit: '제한 추가',
    addLotteryResult: '복권 결과 추가',
    addMember: '회원 추가',
    addMemberAmountAdjust: '회원 금액 조정 추가',
    addMemberPrivilegeBlacklist: '회원 특권 블랙리스트 추가',
    addMemberRebateRule: '회원 리베이트 규칙 추가',
    addMenu: '메뉴 추가',
    addNodes: '노드 추가',
    addPayType: '결제 유형 추가',
    addPlatform: '플랫폼 추가',
    addPlatformAccount: '플랫폼 계정 추가',
    addPrivilegeInfo: '특권 정보 추가',
    addQuestionnaire: '설문 조사 추가',
    addRecipient: '수신자 추가',
    addRemark: '비고 추가',
    addRiskLevel: '위험 수준 추가',
    addRole: '역할 추가',
    addSettings: '설정 추가',
    addSite: '사이트 추가',
    addSitePlatform: '사이트 플랫폼 추가',
    addStage: '단계 추가',
    addSystemMessage: '시스템 메시지 추가',
    addSyncDefault: '3일 이내 동기화 경쟁',
    addTelephone: '전화번호 추가',
    addTicket: '티켓 추가',
    addUser: '사용자 추가',
    addVip: 'VIP 추가',
    addVipRebateRule: 'VIP 리베이트 규칙 추가',
    addWithdrawPlatform: '출금 플랫폼 추가',
    addWithdrawSetting: '출금 설정 추가',
    addWithdrawalBankBlacklist: '출금 은행 블랙리스트 추가',
    address: '주소',
    adjustAmount: '금액 조정',
    adjustBetAmount: '베팅 금액 조정',
    adjustBy: '조정자',
    adjustReason: '조정 이유',
    adjustTime: '조정 시간',
    adjustType: '조정 유형',
    adjustmentReasonType: '조정 이유 유형',
    adjustmentReason: '조정 이유',
    adjustment: '조정',
    adjustmentType: '조정 유형',
    affiliate: '제휴사',
    affiliateBankCard: '제휴 은행 카드',
    affiliateBankCardRecord: '제휴 은행 카드 기록',
    affiliateCode: '제휴 코드',
    channelName: '채널 이름',
    affiliateDomainList: '제휴 도메인 목록',
    affiliateFinancialManagement: '제휴 금융 관리',
    affiliateFBRequestList: 'Affiliate Facebook Pixel List',
    affiliateFBRequestUrl: 'Affiliate URL',
    affiliateDepositChannel: '제휴 입금 채널',
    affiliateH5Link: '제휴 H5 링크',
    affiliateInfo: '제휴 정보',
    affiliateLevel: '제휴 레벨',
    affiliateLink: '제휴 링크',
    affiliateList: '제휴 목록',
    affiliateName: '제휴 이름',
    affiliateShortUrlPlatform: '제휴 짧은 URL 플랫폼',
    affiliateStatus: '제휴 상태',
    affiliateTransferAmount: '송금 금액',
    affiliateTransferRecord: '송금 기록',
    affiliateWallet: '송금 지갑',
    affiliateWebLink: '제휴 웹 링크',
    advancedSearch: '고급 검색',
    afterAmount: '이후 금액',
    afterBalance: '이후 잔액',
    afterEdit: '편집 후',
    amount: '금액',
    amountLimitPerRain: '비별 금액 제한',
    adjust: '금액 조정',
    alias: '별명',
    all: '전체',
    allBonus: '전체 보너스',
    allvip: '전체 VIP',
    allfinanciallevel: '전체 금융 상태',
    allprofit: '전체 이익',
    allreviewstatus: '전체 검토 상태',
    allrisklevel: '전체 위험 수준',
    allmembers: '전체 회원',
    allmembertype: '전체 회원 유형',
    announcement: '공고',
    announcementType: '공지 유형',
    answer: '답변',
    answerOne: '질문 하나 답변',
    answerTwo: '질문 둘 답변',
    answerThree: '질문 셋 답변',
    appVersion: '앱 버전',
    applying: '신청 중',
    approve: '승인',
    approveBy: '승인자',
    assigned: '할당됨',
    assignment: '할당',
    attachment: '첨부 파일',
    auditTime: '감사 시간',
    automaticPayment: '자동 결제',
    autopay: '자동 결제',
    autoplay: '자동재생',
    automaticPay: '자동 결제',
    autoWithdrawSetting: '자동 출금 설정',
    awards: '상',
    awayTeam: '원정 팀',
    awayTeamIcon: '원정 팀 아이콘',
    back: '뒤로',
    balance: '잔고',
    balanceUrl: '잔고 URL',
    bank: '은행',
    bankAccount: '은행 계좌',
    bankCard: '은행 카드',
    bankCardNumber: '은행 카드 번호',
    bankCode: '은행 코드',
    bankName: '은행 이름',
    bankNumber: '은행 번호',
    bankType: '은행 종류',
    batchCancel: '일괄 취소',
    bbDachaDetails: 'BB Dacha 세부 정보',
    bbdachaAnswers: 'BB Dacha 답변',
    bbdachaSetting: 'BB Dacha 설정',
    beanName: '빈 이름',
    beforeAmount: '조정 전 금액',
    beforeBalance: '조정 전 잔액',
    beforeEdit: '편집 전',
    bet: '베팅',
    betAmount: '베팅 금액',
    betId: '베팅 ID',
    belongType: '소속 유형',
    betMoneyChange: '회원 베팅 금액 변경',
    betMoreThan: '이상 베팅',
    betRebateDetails: '베팅 리베이트 기록 세부 정보',
    betRebateRecord: '베팅 리베이트 기록',
    betRecords: '베팅 기록',
    betStatus: '베팅 상태',
    betTime: '베팅 시간',
    betTotal: '총 베팅 금액',
    betWheelSettings: '베팅 휠 설정',
    betWheelRecords: '베팅 휠 기록',
    binded: '연결됨',
    birthday: '생년월일',
    bindTypeAll: '전체',
    bindTypeNew: '신규',
    bindTypeRemove: '제거',
    blacklisted: '블랙리스트 등록됨',
    bonus: '보너스',
    bonusAmount: '보너스 금액',
    bonusDays: '보너스 일수',
    bonusReceived: '받은 보너스',
    bonusType: '보너스 유형',
    byDepositDateDesc: '입금 날짜 내림차순 정렬',
    byDepositDateAsc: '입금 날짜 오름차순 정렬',
    byFinishDateDesc: '완료 날짜 내림차순 정렬',
    byFinishDateAsc: '완료 날짜 오름차순 정렬',
    byPaymentDateDesc: '지불 날짜 내림차순 정렬',
    byPaymentDateAsc: '지불 날짜 오름차순 정렬',
    byWithdrawDateDesc: '출금 날짜 내림차순 정렬',
    byWithdrawDateAsc: '출금 날짜 오름차순 정렬',
    byprofitasc: '이익 오름차순 정렬',
    byprofitdesc: '이익 내림차순 정렬',
    bydateasc: '날짜 오름차순 정렬',
    bydatedesc: '날짜 내림차순 정렬',
    bulkApprove: '대량 승인',
    callbackUrl: '콜백 URL',
    cancel: '취소',
    cancelBbDacha: 'BB Dacha 취소',
    cancelBySearch: '검색 취소',
    cancelDeposit: '입금 취소',
    cancelGameMatch: '게임 매치 취소',
    cancelGift: '선물 교환 취소',
    cancelMatch: '매치 취소',
    cancelQuiz: '퀴즈 취소',
    cancelRemark: '비고 취소',
    cancelRolloverRecord: '롤오버 레코드 취소',
    cancelAllRolloverRecord: '롤오버 모든 레코드 취소',
    cancelUpdate: '업데이트 취소',
    cancelType: '취소 유형',
    cardAccount: '카드 계정',
    cardAddress: '카드 주소',
    cardNumber: '카드 번호',
    cardTime: '작업 시간',
    cardTypeAll: '모두',
    cardTypeBank: '은행',
    cardTypeWallet: '전자지갑',
    cardTypeCrypto: '암호화폐',
    category: '카테고리',
    cause: '원인',
    changeAffiliate: '제휴사 변경',
    check: '검토',
    checkall: '모두 선택',
    checkBy: '검토자',
    checkDate: '검토 날짜',
    checkExclusiveUrl: '독점 URL 확인',
    checkFail: '검토 실패',
    checkTime: '검토 시간',
    choice: '선택지',
    choiceOne: '질문 1 선택지',
    choiceTwo: '질문 2 선택지',
    choiceThree: '질문 3 선택지',
    claimTime: '청구 시간',
    className: '클래스명',
    clearingSum: '정산 금액',
    clientType: '클라이언트 유형',
    close: '닫기',
    code: '코드',
    color: '색상',
    commission: '커미션',
    commissionBalance: '커미션 잔액',
    commissionModel: '커미션 모델',
    commissionPercent: '커미션 %',
    commissionRate: '커미션 비율',
    commissionReport: '커미션 보고서',
    commissionWallet: '커미션 지갑',
    commitTime: '커밋 시간',
    companyProfit: '회사 이익',
    configGroup: '구성 그룹',
    componentName: '컴포넌트 이름',
    componentPath: '컴포넌트 경로',
    composeMessage: '메시지 작성',
    consumingTime: '소비 시간',
    confirm: '확인',
    confirmAndImport: '확인 및 가져오기',
    confirmAndSend: 'Confirm and Send',
    confirmNewPassword: '새 비밀번호 확인',
    confirmPassword: '비밀번호 확인',
    confirmPay: '지불 확인',
    confirmStatus: '상태 확인',
    confirmBy: '확인자',
    content: '콘텐츠',
    contentList: '글머리 기호 목록',
    convertRate: '환율',
    copy: '복사',
    copyPayment: '지불 복사',
    copyWithdrawPlatform: '출금 플랫폼 복사',
    copyTo: '복사 대상',
    copyToOtherLevel: '다른 레벨로 복사',
    country: '국가',
    countryImage: '국기',
    createBy: '생성자',
    createTime: '생성 시간',
    createVote: '투표 생성',
    cronExpression: '크론 표현식',
    currency: '통화',
    currencyCode: '통화 코드',
    currencyName: '통화 이름',
    currencyRate: '환율',
    currencyWallet: '화폐 지갑',
    currentPassword: '현재 비밀번호',
    currentPlace: '현재 위치',
    customerSupportAddress: '고객 지원 주소',
    dailyDeposit: '일일 입금',
    dailyDepositAmount: '일일 입금액',
    dailyMin: '일일 최소 스핀',
    dailyMinRegLimit: '일일 최소 등록 한도',
    dailyWithdraw: '일일 인출',
    dailyWithdrawAmount: '일일 인출액',
    dailyFailAmount: '일일 실패액',
    dailyFailDeposit: '일일 입금 실패',
    dailyFirstDepositLimit: '일일 첫 입금 한도',
    dailyMinFirstDepositLimit: '일일 최소 첫 입금액 한도',
    dailyMaxFirstDepositLimit: '일일 최대 첫 입금액 한도',
    dailyFirstDepositProbability: '일일 첫 입금 확률',
    dailyMemberFailDeposit: '일일 회원 입금 실패',
    dailyFailWithdraw: '일일 인출 실패',
    dailyMax: '일일 최대 스핀',
    dailyMaxRegLimit: '일일 최대 등록 제한',
    dailyRegProbabilty: '일일 등록 확률',
    dailyMemberFailWithdraw: '일일 회원 인출 실패',
    dailyRainDuration: '레인 기간',
    dailyRefreshDuration: '새로 고침 기간',
    dailyRegLimit: '일일 등록 제한',
    dailySuccessAmount: '일일 성공액',
    dailySuccessDeposit: '일일 성공 입금',
    dailyMemberSuccessDeposit: '일일 회원 성공 입금',
    dailySuccessWithdraw: '일일 성공 인출',
    dailyMemberSuccessWithdraw: '일일 회원 성공 인출',
    dailySuccessPercentage: '일일 성공률',
    dailyTotalAmount: '일일 총액',
    dailyTotalRedeem: '일일 총 교환',
    dayWithdrawCount: '일일 인출 횟수',
    day1: '1일 차 잠금 해제',
    day2: '2일 차 잠금 해제',
    day3: '3일 차 잠금 해제',
    day4: '4일 차 잠금 해제',
    day5: '5일 차 잠금 해제',
    day6: '6일 차 잠금 해제',
    date: '날짜',
    data: '데이터',
    dataBefore: '이전 데이터',
    dataAfter: '이후 데이터',
    dateReceived: '수신 날짜',
    dateType: '날짜 유형',
    deduct: '차감',
    deductMemberAmountAdjust: '회원 금액 조정 차감',
    defaultBonus: '기본 보너스',
    defaultFinancialLevel: '기본 재정 수준',
    defaultAgentFinancialLevel: '기본 제휴사 재정 수준',
    defaultRiskLevel: '기본 리스크 수준',
    defaultAgentRiskLevel: '기본 제휴사 리스크 수준',
    defaultVip: '기본 VIP',
    defaultAgentVip: '기본 제휴사 VIP',
    delete: '삭제',
    runAtOnce: '즉시 실행',
    deleteRole: '역할 삭제',
    deliver: '전달',
    delivered: '전달됨',
    deliverGift: '선물 전달',
    deposit: '입금',
    depositAmount: '입금액',
    depositAverage: '평균 입금액',
    depositBettingAmount: '입금/베팅액',
    depositCount: '입금 횟수',
    depositDate: '입금 날짜',
    deposited: '입금됨',
    depositExchangeRate: '입금 환율',
    depositMemberCount: '입금 회원 수',
    depositPerSpin: '스핀당 입금',
    depositSerialNo: '입금 시리얼 번호',
    depositStatus: '입금 상태',
    depositWithdrawalProfit: '입금 인출 이익',
    describe: '설명',
    description: '설명',
    desktopImage: '데스크탑 이미지',
    desktopBackgroundImage: '데스크탑 배경 이미지',
    desktopBanner: '데스크탑 배너',
    details: '세부 정보',
    device: '장치',
    dif: '입금 차이',
    disable: '비활성화',
    disableAffiliate: '제휴사 비활성화',
    disableAll: '모두 비활성화',
    disableType: '비활성화 유형',
    displayUrl: '표시 URL',
    distributed: '분배됨',
    distributeBy: '분배자',
    distributePrivilege: '권한 분배',
    distributeRebate: '리베이트 분배',
    distributeRefer: '추천 보너스 분배',
    distributeTime: '분배 시간',
    domain: '도메인',
    domainCount: '도메인 수',
    domainList: '도메인 목록',
    done: '완료',
    doneBy: '완료자',
    downgradeToApplying: '신청으로 다운그레이드',
    downgradeToWaitPay: '결제 대기 중으로 다운그레이드',
    downlineAffiliate: '하위 제휴사',
    downlineAffiliatePaymentChannel: '하위 제휴사 결제 채널',
    downlineCommission: '하위 커미션',
    downlineCommissionRate: '하위 커미션 비율',
    downlineMember: '하위 회원',
    downlineProfit: '하위 이익',
    downloadTemplate: '템플릿 다운로드',
    draw: '추첨',
    edit: '편집',
    editAds: '광고 팝업 편집',
    editAdjustmentReason: '조정 이유 편집',
    editAffiliateCommission: '제휴사 커미션 편집',
    editAffiliateDomain: '제휴사 도메인 편집',
    editAffiliateAnnouncement: '제휴사 공지사항 편집',
    editAffiliateAnnouncementType: '제휴사 공지사항 유형 편집',
    editAffiliateFinancialConfig: '제휴사 재무 구성 편집',
    editAmount: '금액 편집',
    editAnnouncement: '공지사항 편집',
    editAnnouncementType: '공지사항 유형 편집',
    editBank: '은행 편집',
    editBankCard: '은행 카드 편집',
    editBanner: '배너 편집',
    editBelongType: '소속 유형 편집',
    editConfig: '구성 편집',
    editCurrency: '통화 편집',
    editCurrencyRate: '통화 환율 편집',
    editDepositSetting: '입금 설정 편집',
    editDetails: '세부 정보 편집',
    editEmail: '이메일 편집',
    editFinancialLevel: '재무 수준 편집',
    editGame: '게임 편집',
    editGift: '선물 편집',
    editIpLabel: 'IP 라벨 편집',
    editJob: '직업 편집',
    editMemberRebateRule: '회원 리베이트 규칙 편집',
    editMenu: '메뉴 편집',
    editPayType: '결제 유형 편집',
    editPlatform: '플랫폼 편집',
    editPlatformAccount: '플랫폼 계정 편집',
    editPrivilegeInfo: '권한 정보 편집',
    editQuestionnaire: '질문지 편집',
    editRemark: '비고 편집',
    editRewardGroup: '보상 그룹 편집',
    editRiskLevel: '리스크 수준 편집',
    editRole: '역할 편집',
    editSettings: '설정 편집',
    editSite: '사이트 편집',
    editSitePlatform: '사이트 플랫폼 편집',
    editStage: '단계 편집',
    editTelephone: '전화 번호 편집',
    editTimeType: '시간 유형 편집',
    editType: '유형 편집',
    editUser: '사용자 편집',
    editVip: 'VIP 편집',
    editVipRebateRule: 'VIP 리베이트 규칙 편집',
    editWithdrawPlatform: '출금 플랫폼 편집',
    editWithdrawSetting: '출금 설정 편집',
    email: '이메일',
    endBbDacha: 'BB Dacha 종료',
    endDate: '종료 날짜',
    endMatch: '매치 종료',
    endQuiz: '퀴즈 종료',
    endTime: '종료 시간',
    estimateRate: '예약 환율',
    event: '이벤트',
    exchangeRate: '환율',
    exclusiveUrlWeb: '독점 URL(웹)',
    exclusiveUrlWap: '독점 URL(앱)',
    exclusiveUrlApp: '독점 URL',
    exclusiveUrlCashier: '독점 URL',
    exclusiveUrl: '독점 URL',
    exportToExcel: 'Excel로 내보내기',
    expressCompany: '택배 회사',
    externalUrl: '외부 URL',
    extraParam: '추가 매개변수',
    fail: '실패',
    failReason: '실패 사유',
    fbid: 'Facebook Pixel ID',
    fbToken: 'Facebook Pixel Token',
    fdConvertRate: 'FTD 환율',
    features: '기능',
    fee: '수수료',
    feedbackType: '피드백 유형',
    feedbackTypeAll: '전체',
    feedbackTypeDeposit: '입금',
    feedbackTypeWithdraw: '출금',
    feedbackStatus: '피드백 상태',
    feedbackStatusAll: '전체',
    feedbackStatusPending: '대기 중',
    feedbackStatusSuccess: '성공',
    feedbackTime: '피드백 시간',
    feeRate: '수수료율',
    subtractAmount: '금액 차감',
    field: '필드',
    finalSum: '최종 합계',
    financialLevel: '재무 수준',
    financeType: '유형',
    financeRemark: '재무 비고',
    financeStatus: '상태',
    financePhotos: '사진',
    finishDate: '완료 날짜',
    firstDeposit: '첫 입금',
    firstTicket: '첫 티켓 (베팅/입금)',
    firstDepositMemberCount: '첫 입금 회원 수',
    firstDepositMember: '첫 입금 회원',
    freeze: '동결',
    freezeMember: '회원 동결',
    unfreezeMember: '회원 동결 해제',
    freezeType: '동결 유형',
    frequency: '빈도',
    fromWallet: '메인 지갑에서 조정',
    fromCommission: '커미션에서 조정',
    ftd: 'FTD',
    ftdAverage: 'FTD 평균',
    ftdAmount: 'FTD 금액',
    ftdCount: 'FTD 횟수',
    ftdTime: 'FTD 시간',
    ftdTxn: 'FTD 거래',
    fundingInfo: '자금 조달 정보',
    gameAccountName: '게임 계정 이름',
    gameCode: '게임 코드',
    domainEasy: 'Domain 쉽다',
    videoShiPin: 'Video URL',
    gameConsolidateReport: '게임 통합 보고서',
    gameMatch: '게임 경기',
    gameMatchRecord: '게임 경기 기록',
    gameName: '게임 이름',
    gameQuiz: '게임 퀴즈',
    gameQuizAnswer: '게임 퀴즈 답변',
    gameStepsRecords: '게임 스텝 기록',
    gameStepsSettings: '게임 스텝 설정',
    gameType: '게임 유형',
    generateSummary: '요약 생성',
    groupId: '그룹 ID',
    gift: '선물',
    giftCode: '선물 코드',
    giftName: '선물 이름',
    giftRecord: '선물 기록',
    hasPromo: '프로모션 보유 여부',
    hidden: '숨김',
    homeTeam: '홈 팀',
    homeTeamIcon: '홈 팀 아이콘',
    icon: '아이콘',
    pIcon: '특전 아이콘',
    id: 'ID',
    identifyCode: '식별 코드',
    imageSize: '이미지 크기',
    import: '가져오기',
    inbox: '받은 편지함',
    ineligible: '자격 없음',
    innerSetting: '내부 설정',
    ip: '접속 IP',
    isDeposit: '입금 표시',
    isMultiple: '다중 선택 여부',
    itsYou: '당신입니다',
    job: '작업',
    jobLog: '작업 로그',
    jobName: '작업 이름',
    jobType: '작업 유형',
    label: '라벨',
    labelCancel: '취소',
    lastDepositTime: 'Last Deposit Time',
    lastDigit: '마지막 숫자',
    lastDigitMinDayDeposit: '마지막 숫자 레드 패킷을 위한 최소 일일 입금 금액',
    lastDigitRules: '마지막 숫자 레드 패킷 규칙',
    lastLogin: '마지막 로그인',
    lastLoginAddress: '마지막 로그인 주소',
    lastLoginIp: '마지막 로그인 IP',
    lastLoginTime: '마지막 로그인 시간',
    lastMonth: '지난 달',
    last3Months: '지난 3개월',
    last6Months: '지난 6개월',
    last5Min: '지난 5분',
    last15Min: '지난 15분',
    last30Min: '지난 30분',
    last1Hour: '지난 1시간',
    lastWeek: '지난 주',
    latestFetchBetRecordTime: '최신 베팅 기록 가져오기 시간',
    level: '레벨',
    levelUpCredit: '레벨 업 크레딧',
    limitNumber: '한도 숫자',
    localCurrencyAmount: '지역 통화 금액',
    lockStatus: '잠금 상태',
    login: '로그인',
    loginAddress: '로그인 주소',
    loginFailedReason: '로그인 실패 사유',
    loginInfo: '로그인 정보',
    loginIp: '로그인 IP',
    loginName: '로그인 이름',
    loginNameSeparateComma: '로그인 이름 (쉼표로 구분)',
    loginStatus: '로그인 상태',
    loginTime: '로그인 시간',
    loginUrl: '로그인 URL',
    loginWay: '로그인 방법',
    logoutPlayer: '로그아웃 플레이어',
    lotteryRecordList: '복권 기록 목록',
    lotteryResultList: '복권 결과 목록',
    lowBalance: '최소 잔액',
    maintenanceEndTime: '유지보수 종료 시간',
    maintenanceStartTime: '유지보수 시작 시간',
    maintenanceTime: '유지보수 시간',
    mallCode: '몰 코드',
    mallExtraKey: '몰 추가 키',
    mallKey: '몰 키',
    mallName: '몰 이름',
    manualPay: '수동 지불',
    massImport: '대량 가져오기',
    messageAffiliateDepositDisplay:
      '새로운 제휴 업체에 대한 입금 표시 설정을 설정하십시오.',
    matchTime: '경기 시간',
    matchTitle: '매치 제목',
    matureTime: '확장 시간',
    maxBonus: '최대 보너스',
    maxDailyWithdraw: '일일 최대 인출 금액',
    maxDailyWithdrawTimes: '일일 인출 최대 횟수',
    maxDeposit: '최대 입금',
    maxMemberClaimCountPerRain: '비 전체 인원당 최대 청구 횟수',
    maxMemberClaimCountPerDay: '하루에 최대 회원 청구 횟수',
    maxTicket: '최대 티켓',
    maxWithdrawAmount: '최대 인출 금액',
    mediaDesc: '미디어 이름',
    mediaUrl: '미디어 주소',
    member: '회원',
    memberBankCard: '회원 은행 카드',
    memberBankCardRecord: '회원 은행 카드 기록',
    memberBetRecord: '회원 베팅 기록',
    memberBetRecordByPlatform: '플랫폼 베팅 요약',
    memberCommission: '회원 수수료',
    memberConsolidateReport: '회원 통합 보고서',
    memberDetailDeposit: '총 입금 금액',
    memberDetailDepositCount: '총 입금 회수',
    memberDetailPrivilege: '총 특전 금액',
    memberDetailWithdraw: '총 인출 금액',
    memberFeedbackType: '피드백 유형',
    memberId: '회원 ID',
    memberInfo: '회원 정보',
    memberList: '회원 목록',
    memberMessage: '회원 메시지',
    memberName: '회원 이름',
    memberPlatformAction: '작업 (수동 등록/삭제)',
    memberPlatformUpdate: '비밀번호 동기화',
    memberPrivilegeBlacklist: '회원 특전 블랙리스트',
    memberProfitDownlineProfitUnsettleCommission:
      '회원 이익 / 하부 이익 / 미정산 수수료',
    memberReport: '회원 보고서',
    memberType: '회원 유형',
    memberWithdrawLog: '회원 인출 로그',
    menuGame: '메뉴 게임 순서',
    menuIcon: '메뉴 아이콘',
    menuTitle: '메뉴 제목',
    menuType: '메뉴 유형',
    menuOnlineUser: '온라인 사용자',
    message: '메시지',
    min: '최소 이익',
    minAmount: '최소 금액',
    max: '최대 이익',
    maxAmount: '최대 금액',
    minno: '최소값을 입력하세요',
    maxno: '최대값을 입력하세요',
    memberRemark: '회원 비고',
    minActiveMemberCount: '최소 활동 회원 수',
    minBalance: '최소 잔액',
    minDeposit: '최소 입금',
    minTotalCommission: '최소 총 수수료',
    minWithdrawAmount: '최소 인출 금액',
    mobile: '모바일',
    mobileImage: '모바일 이미지',
    mobileBackgroundImage: '모바일 배경 이미지',
    mobileBanner: '모바일 배너',
    mobileMessage: '모바일 메시지',
    mobileWay: '모바일',
    mode: '모드',
    moneyChange: '자금 이동',
    month: '월',
    mqLogProcessWithTitle: '제목 (SummaryDoris)',
    mqLogProcessTopic: '주제',
    mqLogProcessTimestamp: '현재 타임스탬프',
    mqLogTimestampWithTimezone: '사이트 타임스탬프',
    name: '이름',
    needRegister: '등록 필요',
    needSpecify: '지정 필요',
    netProfit: '순이익',
    netPhone: '전화 통화 설정',
    newMember: '신규 회원',
    newMemberCount: '신규 회원 수',
    newPassword: '새 비밀번호',
    nextActivationTime: '다음 활성화 시간',
    nextGetBetEndTime: '다음 베팅 종료 시간',
    nextGetBetIndex: '다음 베팅 인덱스',
    nextGetBetPage: '다음 베팅 페이지',
    nextGetBetStartTime: '다음 베팅 시작 시간',
    nextLevel: '다음 레벨',
    nextLevelPoint: '다음 레벨 포인트',
    no: '아니오',
    noData: '데이터 없음',
    noDeposit: '입금 없음',
    noLimit: '제한 없음',
    noOfDeduction: '차감 횟수',
    noOfDepositTimes: '입금 횟수',
    noOfPrivilegeClaimed: '청구된 특전 수',
    noOfReimbursement: '보상 횟수',
    noOfWinners: '당첨자 없음',
    noOfWithdrawTimes: '인출 횟수',
    notBinded: '연결되지 않음',
    notRegister: '등록되지 않음',
    notice: '공지',
    notRead: '읽지 않음',
    notReplied: '답장하지 않음',
    number: '번호',
    oneTimeSpin: '한 번의 스핀',
    onlyDeposit: '입금 내역 표시',
    onlyFake: '회원이 아닌 기록만 표시',
    onlyReal: '회원 기록만 표시',
    open: '열기',
    operate: '조작',
    operator: '운영자',
    operateTime: '조작 시간',
    operationTime: '운영 시간',
    operationType: '작업 유형',
    orderBy: '정렬 기준',
    orderNo: '주문 번호',
    orderTrackingNo: '주문 추적 번호',
    outerSetting: '외부 바퀴 설정',
    param: '매개 변수',
    parentSite: '상위 사이트',
    password: '비밀번호',
    pause: '일시 정지',
    pay: '지불',
    payBy: '지불 방법',
    payMessage: '지불 메시지',
    payMessageMobile: '모바일 지불 메시지',
    payResultType: '지불 결과 유형',
    payTime: '지불 시간',
    payType: '지불 유형',
    payTypeCode: '지불 유형 코드',
    payTypeName: '지불 유형 이름',
    payment: '결제',
    paymentArea: '결제 지역',
    paymentBy: '결제자',
    paymentCard: '결제 카드',
    paymentChannel: '결제 채널',
    paymentDate: '결제 날짜',
    paymentDisplay: '결제 표시',
    paymentFee: '결제 수수료',
    paymentMethod: '결제 방법',
    paymentName: '결제 이름',
    paymentOnGoing: '결제 진행 중',
    paymentType: '결제 유형',
    payout: '지급',
    payoutTotal: '총 지급 금액',
    return_ratio: '환급률',
    return_ratio_total: '총 수익률',
    pcWay: 'PC',
    pending: '보류',
    pendingTransaction: '보류 중인 거래',
    permission: '권한',
    permissionAssignment: '권한 할당',
    pgroup: '특전 그룹',
    photo: '사진',
    piggyBankRecords: '저금통 기록',
    piggyBankSettings: '저금통 설정',
    place: '장소',
    placeTime: '시간 절약',
    planDays: '계획일',
    planOdds: '계획 확률',
    planLimitMin: '계획 최소 한도',
    planLimitMax: '계획 최대 한도',
    planTimeLimit: '계획 시간 제한',
    platform: '플랫폼',
    platformAccount: '플랫폼 계정',
    platformId: '플랫폼 ID',
    platformBalance: '플랫폼 잔액',
    platformCode: '플랫폼 코드',
    platformFee: '플랫폼 수수료',
    platformFundTransfer: '플랫폼 자금 이체',
    platformName: '플랫폼 이름',
    playerName: '플레이어 이름',
    pleaseChoose: '선택해주세요',
    pleaseInput: '입력해주세요',
    poolAmount: '풀 금액',
    previousLevel: '이전 레벨',
    previousLevelName: '이전 레벨 이름',
    previousPlace: '이전 장소',
    privilege: '특전',
    privilegeCode: '특전 코드',
    privilegeId: '특전 ID',
    privilegeName: '특전 이름',
    privilegeRedeemed: '특전 교환됨',
    privilegeSerialNo: '특전 일련 번호',
    privilegeType: '특전 유형',
    probability: '확률',
    profit: '이익',
    profitpositive: '양의 이익',
    profitnegative: '음의 이익',
    promo: '프로모션',
    promoApplication: '프로모션 적용',
    promoCode: '프로모션 코드',
    promoPages: '프로모션 페이지',
    promoStyle: '프로모션 스타일',
    promoType: '프로모션 유형',
    queryNumber: '질의 번호',
    queryOption: '질의 옵션',
    queryRestriction: '질의 제한',
    question: '질문',
    questionOne: '질문 1',
    questionTwo: '질문 2',
    questionThree: '질문 3',
    quizDetails: '퀴즈 세부 정보',
    quizTime: '퀴즈 시간',
    quizTitle: '퀴즈 제목',
    randomMember: '랜덤 회원',
    rate: '비율',
    realName: '실명',
    englishName: '영문 이름',
    realVirtualVotes: '실제 투표 + 가상 투표',
    reason: '이유',
    reasonType: '이유 유형',
    reasonTemplate: '이유 템플릿',
    rebate: '리베이트',
    rebateDistributeTime: '리베이트 분배 시간',
    rebateLevel: '리베이트 레벨',
    rebatePercentage: '리베이트 %',
    regEndDate: '등록 종료 날짜',
    regStartDate: '등록 시작 날짜',
    regTime: '등록 시간',
    redPacketMinDayDeposit: '빨간색 패킷 비 오는 데 필요한 최소 일일 입금 금액',
    redPacketMinTotalDeposit: '빨간색 패킷 비 오는 데 필요한 최소 총 입금 금액',
    redPacketMinDayBetAmount:
      '빨간색 패킷 비 오는 데 필요한 최소 일일 베팅 금액',
    rule: '규칙',
    maxRebate: '최대 리베이트',
    maxBalance: '최대 잔액',
    nickName: '별명',
    rebateAmount: '리베이트 금액',
    receiveTime: '수신 시간',
    receiveType: '수신 유형',
    recipient: '수취인',
    recipientTelephone: '수취인 전화번호',
    records: '기록',
    recordDate: '기록 날짜',
    recordTime: '기록 시간',
    redeemCash: '현금 교환',
    redeemedBy: '교환자',
    redeemPoints: '포인트 교환',
    redirect: '리디렉트',
    reenterPassword: '비밀번호 재입력',
    referrer: '추천인',
    referFriendRebateDetails: '친구 추천 리베이트 세부 정보',
    referFriendRebateRecords: '친구 추천 리베이트 기록',
    register: '등록',
    registered: '등록됨',
    registerAddress: '등록 주소',
    registerCount: '등록 횟수',
    registerDevice: '등록 장치',
    registerHost: '등록 호스트',
    registerIp: '등록 IP',
    registerMember: '등록 회원',
    registerTime: '등록 시간',
    registrationDate: '등록 날짜',
    referredFriends: '추천된 친구',
    referFriendRecord: '친구 추천 기록',
    remark: '비고',
    remove: '제거',
    reply: '답장',
    replyTitle: '답장 제목',
    replyContent: '답장 내용',
    requestUrl: '요청 URL',
    requestParam: '요청 매개변수',
    responseBody: '응답 본문',
    resend: '재전송',
    reset: '초기화',
    resettleBbDacha: 'BB 다차 재설정',
    resettleGameQuiz: '게임 퀴즈 재설정',
    resettleMatch: '경기 재설정',
    result: '결과',
    resultDate: '결과 날짜',
    resultNumber: '결과 번호',
    resultTime: '결과 시간',
    resume: '재개',
    revenueShare: '수익 공유',
    review: '리뷰',
    reviewby: '리뷰자',
    reviewno: '리뷰 없음',
    reviewsuccess: '정확함',
    reviewstatus: '리뷰 상태',
    reviewfail: '잘못됨',
    rewardGroup: '보상 그룹',
    rewardGroupName: '그룹 이름',
    rewardGroupDownlineQuota: '하위 쿼터 보상 그룹',
    riskControl: '리스크 제어',
    riskLevel: '위험 수준',
    riskPaymentChannel: '위험 지불 채널',
    role: '역할',
    roleList: '역할 목록',
    roleName: '역할 이름',
    rollover: '롤오버',
    rolloverAmount: '롤오버 금액',
    rolloverRecord: '롤오버 기록',
    gameTypeRollover: '게임 유형 롤오버',
    router: '라우터',
    round: '무료 라운드',
    rules: '규칙',
    ruleParam: '규칙 매개변수',
    ruleType: '규칙 유형',
    run: '실행',
    save: '저장',
    saveAsNewPayment: '새 결제로 저장',
    scheduleTime: '일정 시간',
    schemaName: '스키마 이름',
    search: '검색',
    searchCondition: '검색 조건',
    securityQuestion: '보안 질문',
    secondTicket: '두 번째 티켓 (베팅/입금)',
    selectedMembers: '선택된 회원',
    sendTime: '발송 시간',
    sendSuccess: '전송 성공',
    sendFail: '전송 실패',
    select: '선택',
    selectNodeAddTo: '추가할 노드 선택',
    selected: '선택됨',
    selectedNumber: '선택된 번호',
    selectedTeam: '선택된 팀',
    send: '전송',
    sequence: '순서',
    serialNo: '일련 번호',
    settle: '결제',
    settleAmount: '결제 금액',
    settleGameMatch: '게임 매치 결제',
    settlement: '결제',
    settleTime: '결제 시간',
    settleView: '보기',
    settlePay: '결제',
    settleEdit: '편집',
    show: '표시',
    showAll: '모두 표시',
    sid: 'SID',
    site: '사이트',
    siteCode: '사이트 코드',
    siteList: '사이트 목록',
    siteName: '사이트 이름',
    siteType: 'Site Type',
    sn: '일련 번호',
    smsPhone: '전화번호',
    smsContent: '내용',
    smsStatus: '상태',
    smsSendTime: '전송 시간',
    smsType: '유형',
    sorting: '정렬',
    sortType: '정렬 유형',
    sourceType: '소스 유형',
    sportBetResult: '스포츠 베팅 결과',
    stage: '단계',
    state: '상태',
    startCopy: '복사 시작',
    startMatch: '매치 시작',
    startUpdate: '업데이트 시작',
    startDate: '시작 날짜',
    startTime: '시작 시간',
    status: '상태',
    steps: '단계',
    stepBonus: '단계 보너스',
    stopAfterFailure: '실패 후 중지',
    subject: '제목',
    subSiteCount: '하위 사이트 수',
    subtotal: '소계',
    success: '성공',
    successRate: '거래 성공률',
    successAmounntRate: '금액 성공률',
    successfullyExport: '성공적으로 내보내기',
    summaryAdjustment: '조정',
    summaryBonus: '보너스',
    summaryCompanyWinLoss: '회사 수익/손실',
    summaryProfit: '이익',
    summaryReport: '요약 보고서',
    summaryTable: '요약 테이블',
    summaryTotalBet: '총 베팅 회원 수',
    superiorAffiliateInfo: '상위 제휴사 정보',
    superiorAffiliateName: '상위 제휴사 이름',
    superiorAffiliateCode: '상위 제휴사 코드',
    superiorAffiliateLevel: '상위 제휴사 레벨',
    superiorCategory: '상위 카테고리',
    superiorAffiliatePaymentChannel: '상위 제휴사 입금 채널',
    supplement: '보충',
    supplementDeposit: '보충 입금',
    supportType: '지원 유형',
    sureWin: '확실한 승리',
    sureWinBonus: '확실한 승리 보너스',
    sureWinMinSpin: '확실한 승리 최소 스핀',
    suspend: '중지',
    switch: '전환',
    sync: '동기화',
    szCnyControl: 'SZCny 출금 제어',
    switchPayment: '결제 전환',
    systemPayment: '시스템 결제',
    targetType: '대상 유형',
    targetId: '대상 ID',
    targetName: '대상 이름',
    teamA: '팀 A',
    teamB: '팀 B',
    teamC: '팀 C',
    teamD: '팀 D',
    teamE: '팀 E',
    teamF: '팀 F',
    team16: '16강',
    team8: '8강',
    team4: '4강',
    team2: '우승',
    teamGroup: '팀 그룹',
    teamName: '팀 이름',
    teamNameEn: '팀 이름 (영문)',
    teamNameLocal: '팀 이름 (로컬)',
    teamOne: '팀 1',
    teamOneIcon: '팀 1 아이콘',
    teamTwo: '팀 2',
    teamTwoIcon: '팀 2 아이콘',
    teamBackgroundImage: '팀 배경 이미지',
    teamBackgroundImageDark: '팀 어두운 배경 이미지',
    telephone: '전화번호',
    thirtyDaysdw: '30일 입금 - 출금',
    thirdParty: '제3자',
    thirdPartyName: '제3자 이름',
    thirdPartyTotalBet: '제3자 총 베팅',
    thirdPartyTotalPayout: '제3자 총 지급',
    thirdPartyTotalWin: '제3자 총 승리',
    thirdSerialNo: '제3 시리얼 번호',
    thirdTicket: '제3 티켓 (베팅/입금)',
    thisMonth: '이번 달',
    thisWeek: '이번 주',
    ticketSetting: '티켓 설정',
    tips: '팁',
    title: '제목',
    toApplying: '신청 중',
    toBeforePaid: '결제 예정',
    toBePaid: '결제 예정',
    toChecking: '확인 중',
    today: '오늘',
    toPay: '결제하기',
    toPaymentOnGoing: '결제 진행 중',
    topic: '주제',
    toreview: '검토 중',
    toSuspend: '중단 예정',
    total: '합계',
    totalActualRate: '총 이자 금액',
    totalAdjustment: '총 조정',
    totalBalance: '총 잔액',
    totalBet: '총 베팅',
    totalBetAmount: '총 베팅 금액',
    totalBetCount: '총 베팅 회수',
    totalBetMemberCount: '총 회원 베팅 수',
    totalBetRecords: '총 베팅 기록',
    totalBonus: '총 보너스',
    totalCommission: '총 커미션',
    totalCommissionProfit: '총 커미션 수익',
    totalCompanyProfit: '총 회사 수익',
    totalDeductAmount: '총 공제 금액',
    totalDeposit: '총 입금',
    totalDepositAmount: '총 입금 금액',
    totalDepositCount: '총 입금 회수',
    totalDepositMemberCount: '총 회원 입금 수',
    totalDepositedAmount: '총 입금액',
    totalDepositNumber: '총 입금 회원 수',
    totalDownlineAffiliates: '총 하부 제휴사',
    totalDownlineMembers: '총 하부 회원',
    totalFailDeposit: '총 입금 실패',
    totalFailDepositAmount: '총 입금 실패 금액',
    totalFailWithdraw: '총 출금 실패',
    totalFailWithdrawAmount: '총 출금 실패 금액',
    totalFirstDeposit: '총 첫 입금',
    totalMemberDepositAmount: '총 회원 입금 금액',
    totalMemberDepositCount: '총 회원 입금 회수',
    totalNoOfDeposits: '총 입금 회수',
    totalNoOfWithdrawals: '총 출금 회수',
    totalPayout: '총 지급',
    totalPlaceAmount: '총 입금 금액',
    totalPlaceMemberCount: '총 인원 수',
    totalPrivilegeAmount: '총 특전 금액',
    totalPrivilegeClaimAmount: '총 특전 청구 금액',
    totalPromo: '총 프로모션',
    totalProfit: '총 이익',
    totalRebateAmount: '총 리베이트 금액',
    totalRedeem: '총 교환',
    totalRegister: '총 등록',
    totalRegisterCount: '총 등록 회수',
    totalReimburseAmount: '총 환불 금액',
    totalSuccessDeposit: '총 성공 입금',
    totalSuccessDepositAmount: '총 성공 입금 금액',
    totalSuccessDepositPercentage: '총 성공 입금 비율',
    totalSuccessWithdraw: '총 성공 출금',
    totalSuccessWithdrawAmount: '총 성공 출금 금액',
    totalTime: '총 시간',
    totalTransferIn: '총 입금',
    totalTransferOut: '총 출금',
    toUnderReview: '검토 중',
    totalValidBet: '총 유효 베팅',
    totalWithdraw: '총 출금',
    totalWithdrawAmount: '총 출금 금액',
    totalWithdrawCount: '총 출금 회수',
    totalWithdrawnAmount: '총 출금액',
    transaction: '거래',
    transactionStatus: '거래 상태',
    transactionTime: '거래 시간',
    transactionId: '거래 ID',
    transferDate: '이체 날짜',
    transferId: '이체 ID',
    transferIn: '입금',
    transferOut: '출금',
    transferTime: '이체 시간',
    transferType: '이체 유형',
    triggerType: '트리거 유형',
    turnoverMultiple: '회전률',
    txnId: '거래 ID',
    txnTime: '거래 시간',
    type: '유형',
    uefaMatchRecord: 'UEFA 매치 기록',
    uefaMatchSetting: 'UEFA 매치 설정',
    uefaTeamRecord: 'UEFA 팀 기록',
    uefaTeamSetting: 'UEFA 팀 설정',
    undefined: '정의되지 않음',
    underMaintenance: '유지 보수 중',
    underReview: '심사 중',
    unlockMember: '회원 잠금 해제',
    unlockSetting: '설정 잠금 해제',
    unlockUser: '사용자 잠금 해제',
    url: '접속 URL',
    unsettleCommission: '미결 제휴 수수료',
    update: '업데이트',
    updateAlias: '별명 업데이트',
    updateAwards: '상을 업데이트',
    updateBy: '업데이트된 사용자',
    updateCommissionModel: '수수료 모델 업데이트',
    updateCommissionRate: '수수료율 업데이트',
    updateFinancialLevel: '금융 등급 업데이트',
    updateMaintenanceTime: '유지 보수 시간 업데이트',
    updatePassword: '비밀번호 업데이트',
    updatePlatformFee: '플랫폼 수수료 업데이트',
    updatePaymentFee: '결제 수수료 업데이트',
    updateRiskLevel: '위험 수준 업데이트',
    updateStatus: '상태 업데이트',
    updateTime: '업데이트 시간',
    updateVIPLevel: 'VIP 레벨 업데이트',
    upgradeToCheck: '심사 중으로 업그레이드',
    upgradeToPaymentOnGoing: '결제 진행 중으로 업그레이드',
    upLineLoginName: '상위 라인 로그인 이름',
    upload: '업로드',
    upperName: '상위 이름',
    usdtControl: 'USDT 출금 제어',
    usdtDepositAmount: '디지털 화폐 입금액',
    usdtDepositCount: '디지털 통화 예금자 수',
    username: '사용자 이름',
    userType: '사용자 유형',
    validate: '유효성 검사',
    validity: '유효성',
    validBet: '유효한 베팅',
    validBetPerSpin: '회전당 유효 베팅',
    value: '값',
    vcallName: '유선 전화 번호',
    view: '보기',
    viewChoice: '설문 조사 선택 보기',
    viewDetails: '세부 정보 보기',
    viewLog: '로그 보기',
    viewLoginName: '로그인 이름 보기',
    vipLevel: 'VIP 레벨',
    vipRebateDetails: 'VIP 리베이트 기록 세부 정보',
    vipRebateRecord: 'VIP 리베이트 기록',
    vipRules: 'VIP 규칙',
    vipWheelSettings: 'VIP 휠 설정',
    vipWheelRecords: 'VIP 휠 기록',
    virtualVotes: '가상 투표',
    visitNumber: '방문 번호',
    voteTime: '투표 시간',
    walletType: '지갑 유형',
    way: '방법',
    web: '웹',
    webMessage: '웹 메시지',
    winnerTeam: '승자',
    withdraw: '인출',
    withdrawableBalance: '인출 가능 잔액',
    withdrawArea: '출금 지역',
    withdrawChannel: '출금 채널',
    withdrawExchangeRate: '출금 환율',
    withdrawalFailureCause: '출금 실패 원인',
    withdrawalFailureType: '출금 실패 유형',
    withdrawAmount: '출금 금액',
    withdrawCard: '출금 카드',
    withdrawCode: '출금 코드',
    withdrawCount: '출금 횟수',
    withdrawDate: '출금 날짜',
    withdrawMemberCount: '출금 회원 수',
    withdrawName: '출금 이름',
    withdrawPayoutBonus: '출금/지급/보너스/조정',
    withdrawPlatform: '출금 플랫폼',
    withdrawPlatformList: '출금 플랫폼 목록',
    withdrawPlatformName: '출금 플랫폼 이름',
    withdrawStatus: '출금 상태',
    yes: '예',
    yesterday: '어제',
    tigerCardType: '카드 유형',
    tigerCardOpeType: '작업 유형',
    tigerCardSource: '출처/대상',
    tigerCardBeforeCount: '이전 카운트',
    tigerCardRealCount: '실제 타이거 카드 수',
    tigerCardVirtualCount: '가상 카드 수',
    tigerCardPeriod: '기간',
    tigerCardTime: '복권 시간',
    tigerCardBonusTime: '수령 시간',
    timeType: '시간 유형',
    count: '개수',
    host: '호스트',
    port: '포트',
    user: '사용자',
    from: '발신지',
    addEmailSetting: '이메일 설정 추가',
    editEmailSetting: '이메일 설정 편집',
    signName: '서명 이름',
    secretId: '비밀 ID',
    secretKey: '비밀 키',
    setUndefined: '미정으로 설정',
    appId: '애플리케이션 ID',
    templateId: '템플릿 ID',
    addSmsSetting: 'SMS 설정 추가',
    editSmsSetting: 'SMS 설정 편집',
    requestExportToExcel: '엑셀로 내보내기 요청',
    downloadManagerRecord: '다운로드 관리자 레코드',
    downloadExportedExcel: '내보낸 엑셀 다운로드',
    redPacketAmountAfterReachingLimit: '한도 도달 후 빨간색 패킷 금액',
    requestBy: '요청자',
    requestTime: '요청 시간',
    progress: '진행 상황',
    download: '다운로드',
    updateProgress: '진행 상황 업데이트',
    add_new: '새로 추가',
    date_added: '날짜 범위 추가됨.',
    please_add_at_least_one_date_time:
      '적어도 1개의 날짜와 1개의 시간을 추가하십시오.',
    multiple_rain_added: '다수의 일일 비 간격이 추가되었습니다.',
    date_already_exists: '날짜가 이미 존재합니다.',
    time_already_exists: '시간 범위가 이미 존재합니다.',
    add_single_daterange: '단일 날짜 범위 추가',
    adde_multiple_daterange: '다중 날짜 범위 추가',
    add_single_date_steps:
      '단계: 날짜 및 시간 범위를 선택한 다음 `추가`를 클릭합니다.',
    add_multiple_date_steps:
      '단계: 날짜 및 시간 범위의 모든 조합을 선택하고 "날짜 추가" 및 "시간 추가"를 클릭한 다음 "추가"를 클릭하여 추가하십시오.',
    pick_date: '날짜 선택',
    pick_start_date: '시작 날짜/시간 선택',
    pick_end_date: '종료 날짜/시간 선택',
    please_select_datetime: '시작 날짜 및 종료 날짜를 선택하십시오',
    add_date: '날짜 추가',
    add_time: '시간 추가',
    dates: '날짜:',
    timeranges: '시간 범위:',
    timeZone: '시간대',
    image: '이미지',
    imageName: '이미지 이름',
    addImage: '이미지 추가',
    editImage: '이미지 편집',
    browse: '찾아보기',
    imageSite: '이미지 사이트',
    imageRemark: '이미지 설명',
    selectedImage: '선택된 이미지',
    poster: '포스터',
    posterType: '포스터 유형',
    createConfig: '구성 생성',
    defaultConfigHint: '기본값',
    defaultConfigSearchBarHint: '검색',
    configCode: '구성 코드',
    configValue: '구성 값',
    configDescribes: '구성 설명',
    s3Url: 'S3 URL',
    allSites: '모든 사이트',
    allSource: '모든 소스 유형',
    rank: '순위',
    processResult: '처리 결과',
    winner: '승자',
    teamIcon: '팀 아이콘',
    other: '기타',
    dailyDetail: '일일 상세정보',
    dailySummary: '일일 요약',
    officialWebData: '공식 웹 데이터',
    packageWebData: '패키지 웹 데이터',
    payoutAmount: '지급 금액',
    indBet: '베팅 금액',
    indProfit: '이익',
    memberDepositCount: '입금 횟수',
    indBonusAmount: '보너스 금액',
    indRebateAmount: '리베이트 금액',
    indAdjustAmount: '조정 금액',
    grossProfit: '총수익',
    football: '축구',
    basketball: '농구',
    esport: '전자 스포츠',
    competitionType: '대회 유형',
    competitionName: '대회 이름',
    competitionTime: '대회 시간',
    addCompetition: '대회 추가',
    editCompetition: '대회 편집',
    addCompetitionCode: '대회 코드 추가',
    editCompetitionCode: '대회 코드 편집',
    addMemberTreasureKey: '회원 보물 열쇠 추가',
    deductMemberTreasureKey: '회원 보물 열쇠 차감',
    keyQuantity: '열쇠 수량',
    keyQuantityBefore: '이전 열쇠 수량',
    keyQuantityAfter: '이후 열쇠 수량',
    checkInDate: '체크인 날짜',
    consecutiveDay: '연속된 날 수',
    summoner: '소환사',
    memberSummon: '회원 소환',
    summonBonus: '소환 보너스',
    depositBonus: '입금 보너스',
    depositDayCount: '일 수',
    redirectUrlApp: '앱 리디렉션 URL',
    redirectUrlWeb: '웹 리디렉션 URL',
    redirectUrlH5: 'H5 리디렉션 URL',
    popUpDurationSeconds: '팝업 지속 시간 (초)',
    popUpTime: '팝업 시간',
    notificationType: '알림 유형',
    notificationContent: '알림 내용',
    unbind: '언바인드',
    winloss: '승패',
    profit2: '입출금',
    batchDistributeRebate: '일괄 배분 리베이트',
    distribute: '분배하다',
    affiliateBetRebateRecord: '제휴 베팅 리베이트 기록',
    affiliateBetRebateDetails: '제휴 베팅 리베이트 세부정보',
    adjustRebateAmount: '리베이트 금액 조정',
    whitelist: '화이트리스트',
    blacklist: '블랙리스트',
    accessType: '액세스 유형',
    domainType: '도메인 이름 유형',
    valueType: '값 유형',
    ipList: 'IP 그룹',
    ipRange: 'IP 범위',
    countryCode: '국가 코드',
    lower: '하한',
    upper: '상한',
    updateAffiliateLevel: '제휴사 레벨 변경',
    memberMoney: '회원 돈',
    memberPoint: '회원 포인트',
    eggs: '달걀',
    memberInquiry: '회원문의',
    depositApplication: '충전신청유저수',
    withdrawApplication: '환전신청',
    affiliateApplication: '에이전트인증',
    memberApplication: '회원인증',
    memberInbox: '문의',
    totalMembers: '전체 회원',
    currentMembers: '현재 회원',
    todayDepositAmount: '총 입금액',
    todayWithdrawAmount: '총 인출 금액',
    todayWithdrawDepositProfit: '오늘의 입출금 수익',
    manualMoney: '수동 돈',
    manualPoint: '수동 포인트',
    totalGrossProfit: '총 총 이익',
    casinoBetAmount: '카지노 베팅 금액',
    casinoProfit: '카지노 내기 이익',
    casinoRollingAmount: '카지노 롤링 금액',
    casinoGrossProfit: '카지노 총 이익',
    slotBetAmount: '전자 베팅',
    slotProfit: '전자 이익',
    slotRollingAmount: '전자 흐름도',
    slotGrossProfit: '전자순이익',
    sportBetAmount: '스포츠 베팅',
    sportProfit: '스포츠 이익',
    sportRollingAmount: '스포츠 흐름',
    sportGrossProfit: '스포츠 순이익',
    miniGameBetAmount: '소규모 게임 베팅',
    miniGameProfit: '미니게임 수익',
    miniGameRollingAmount: '미니게임 흐름',
    miniGameGrossProfit: '미니게임 순이익',
    memberShareRatio: '플레이어 리베이트 비율',
    updateShareRatio: '리베이트 비율 수정',
    affiliateBalance: '총판잔액',
    memberBalance: '하부총잔액',
    affiliatePoint: '내포인트',
    memberCount: '회원수',
    affiliateWithdrawAmount: '총판입금금액',
    withdrawTransferDiff: '충환전손익',
    liveRolling: '하부총카지노롤링',
    slotRolling: '하부총슬롯롤링',
    sportRolling: '하부총스포츠롤링',
    recycleMoney: '머니회수',
    shareRatio: '지분율',
    createMemberPointRecord: '회원 포인트 기록 생성',
    claimed: '청구됨',
    notClaimed: '보류 중',
    displayAmount: '표시금액',
    activate: '활성화하다',
    withdrawPassword: '비밀번호 철회',
    updateWithdrawPassword: '출금 비밀번호 업데이트',
    self: '(본인)',
  },
  message: {
    startenddatemore3months: '시작 및 종료 날짜는 3개월 이상일 수 없습니다',
    addRemarkSuccess: '비고 추가 성공',
    addSuccess: '추가 성공',
    adjustSuccess: '조정 금액 성공',
    affiliateApproved: '제휴 승인됨',
    affiliateDisabled: '제휴 비활성화됨',
    autopaySuccess: '자동 지급 성공',
    cancelBbDachaSuccess: 'BB Dacha 취소 성공',
    cancelSuccess: '취소 성공',
    cancelDepositSuccess: '입금 취소 성공',
    cancelQuizSuccess: '퀴즈 취소 성공',
    cancelRolloverRecordSuccess: '롤오버 레코드 취소 성공',
    changeAffiliateSuccess: '제휴 변경 성공',
    confirmUnbindAffiliateAccesss: '상위 대리인 연결 해제 확인',
    changeSuccess: '변경 성공',
    commissionPaySuccess: '커미션 지급 성공',
    confirmToAdjust: '조정하시겠습니까? 확인 후 조정이 불가능합니다.',
    confirmCancelBbDacha:
      '이 BB Dacha를 취소하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmCancelMatch:
      '이 경기를 취소하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmCancelQuiz:
      '이 퀴즈를 취소하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmCancelRebate:
      '리베이트 기록을 취소하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmCopy: '복사하시겠습니까? ',
    confirmCopyTo: '로 복사',
    confirmDelete:
      '이 데이터를 삭제하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmRunAtOnce:
      '이 작업을 지금 실행하시겠습니까? (비활성화 상태일 때도 실행됩니다.)',
    confirmDelivered: '이 선물을 배송했습니까? 이 작업은 취소할 수 없습니다.',
    confirmEndMatch:
      '이 경기를 종료하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmRebate:
      '모든 리베이트를 분배하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmBatchRebate:
      '선택한 리베이트를 배포할 것인지 확인하세요. 작업은 취소할 수 없습니다.',
    confirmSingleRebate:
      '이 리베이트를 배포할 것인지 확인하세요. 작업은 취소할 수 없습니다.',
    confirmRedeem:
      '이 선물을 현금으로 교환하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmRefer:
      '모든 추천인 프로모션을 분배하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmReset:
      '이 데이터를 재설정하시겠습니까? 이 작업은 취소할 수 없습니다.',
    confirmSettlement: '결제를 진행하시겠습니까?',
    confirmStartMatch: '경기를 시작하시겠습니까?',
    confirmToChecking: '결제를 검토로 되돌리시겠습니까?',
    confirmToPay: '지급을 진행하시겠습니까?',
    confirmToCheck: '업데이트하시겠습니까?',
    confirmUpdate: '업데이트하시겠습니까?',
    confirmUpdatePayment: '결제를 업데이트하시겠습니까?',
    confirmUnbind: '은행 카드를 언바인드하시겠습니까?',
    copyToClipboard: '이(가) 클립보드로 복사되었습니다.',
    copySuccess: '복사 성공',
    deleteSuccess: '삭제 성공',
    runAtOnceSuccess: '작업 실행 성공',
    dailyMinRegLimitCannotExceedMax:
      '일일 최소 등록 제한은 최대 등록 제한을 초과할 수 없습니다',
    dailyMinFirstDepositCannotExceedMax:
      '일일 최소 입금액은 최대 입금액을 초과할 수 없습니다',
    depositSuccess: '입금 성공',
    editAmountSuccess: '금액 편집 성공',
    editRemarkSuccess: '비고 편집 성공',
    editSuccess: '편집 성공',
    editMemberDetail: '회원 세부 정보 편집',
    endMustAfterStart: '종료 시간은 시작 시간 이후여야 합니다.',
    enterMemberDetails: '회원 세부 정보 입력',
    failedToUploadImage: '이미지 업로드 실패',
    failedToUploadApp: '앱 업로드 실패',
    freezeMemberSuccess: '회원 얼림 성공',
    unfreezeMemberSuccess: '회원 해제 성공',
    found: '발견 ',
    gameMatchEnded: '게임 매치 종료됨',
    giftDelivered: '선물 배송됨',
    giftNoCashRedeemAmount:
      '선물에 현금 교환 금액이 없습니다. 먼저 선물의 현금 교환 금액을 설정하십시오',
    giftOutForDelivery: '선물이 배송 중입니다',
    importSuccess: '가져오기 성공',
    inputDigits: '숫자를 입력하십시오',
    inputPassword: '비밀번호를 입력하십시오',
    inputPasswordAgain: '비밀번호를 다시 입력하십시오',
    invalidFileType: '잘못된 파일 유형 ',
    length6To12: '길이는 6자에서 12자여야 합니다',
    levelUpCreditMoreThan: '레벨 업 신용은 반드시 다음보다 많아야 합니다 ',
    logoutPlayerSuccess: '플레이어 로그아웃 성공',
    maxGreaterThanMin: '최대값은 최소값보다 커야 합니다',
    memberNotInSite: '이 사이트에서 회원을 찾을 수 없습니다',
    affiliateNotInSite: '이 사이트에서 제휴사를 찾을 수 없습니다',
    memberFreeGameLogin:
      '로그인 이름, 하나 이상일 경우 쉼표로 구분 (최대 200명)',
    memberFreeGameRound: '무료 게임 라운드',
    memberFreeGameIds:
      '게임 ID를 사용할 수 있으며 하나 이상일 경우 쉼표로 구분됩니다. 최대 길이(쉼표 포함)는 200입니다',
    multipleQuerySeparatedBySpace: '여러 쿼리 조건은 공백으로 구분됩니다',
    multiwheelprizeform: '대상 상으로 0을 삽입합니다',
    noAvailablePrivilege: '현재 사용 가능한 특전이 없습니다',
    passwordLength: '비밀번호는 6자 미만이거나 12자 이상일 수 없습니다',
    paymentUpdate:
      '다른 방법이나 레벨에 복사하시겠습니까? 그렇지 않으면 업데이트를 누르십시오',
    paySuccess: '결제 성공',
    pleaseEnterAffiliateNameOrAffiliateCode:
      '제휴 이름 또는 제휴 코드를 입력하세요',
    promoDistributionSuccess: '프로모션 분배 성공',
    redeemCashSuccess: '현금 교환 성공',
    reenterPassword: '비밀번호를 다시 입력하십시오',
    rebateSuccess:
      'VIP 리베이트 분배가 진행 중입니다. 나중에 기록을 새로 고쳐 확인하십시오.',
    registerSuccess: '등록 성공',
    registerSuccessInd:
      '등록 성공. 새로운 제휴사의 입금 표시 설정을 설정하십시오.',
    referSuccess: '추천인 프로모션 분배 성공',
    replySuccess: '답장 성공',
    removePreviousLevel: '이전 레벨을 제거하십시오',
    removeRemarkSuccess: '비고 제거 성공',
    replyFeedback: '피드백 답장',
    resetSuccess: '재설정 성공',
    resendSuccess: '재전송 성공',
    selectDateNotMoreThan3Month:
      '날짜 범위는 3개월을 초과할 수 없습니다. 다시 선택하십시오',
    selectPreviousLevel: '이전 레벨을 선택하십시오',
    selectSiteFirst: '먼저 사이트를 선택하십시오',
    selectSiteAndGameTypeFirst: '먼저 사이트와 게임 유형을 선택하십시오',
    selectPaymentType: '결제 유형을 선택하십시오',
    selectMobilePayment: '모바일 결제를 선택하십시오',
    selectWebPayment: '웹 결제를 선택하십시오',
    settlementToChecking: '검토 중으로 업데이트됨',
    settlementToPay: '결제로 업데이트됨',
    settled: '성공적으로 처리됨',
    startMustBeforeEnd: '시작 시간은 종료 시간 이전이어야 합니다',
    success: '성공',
    summarySuccess:
      '입력 기간에 대한 요약이 생성됩니다. 나중에 기록을 확인하십시오',
    transferSuccess: '이체 성공',
    twoPasswordNotMatch: '두 암호가 일치하지 않습니다',
    unbindSuccess: '회원 은행 카드 해제 성공',
    unlockMemberSuccess: '회원 잠금 해제 성공',
    unlockUserSuccess: '사용자 잠금 해제 성공',
    updateBelongTypeSuccess: '웹 소속 유형 업데이트 성공',
    updateCommissionModelSuccess: '커미션 모델 업데이트 성공',
    updateCommissionRateSuccess: '커미션 비율 업데이트 성공',
    updateFinancialLevelSuccess: '재정 수준 업데이트 성공',
    updatePasswordSuccess: '비밀번호 업데이트 성공',
    updatePayment: '결제 업데이트',
    updateProceed: '지불 편집을 진행하려면 노드 업데이트',
    updateRiskLevelSuccess: '위험 수준 업데이트 성공',
    updateSuccess: '업데이트 성공',
    updateWithdraw: '인출 업데이트',
    updateTimeTypeSuccess: '시간 유형 업데이트 성공',
    updateToApplySuccess: '신청 업데이트 성공',
    updateToBeforePaidSuccess: '지불 전에 업데이트 성공',
    updateToFailSuccess: '실패로 업데이트 성공',
    updateToPaySuccess: '지불 진행 업데이트 성공',
    updateToSuspendSuccess: '일시 중지 업데이트 성공',
    updateToUnderReviewSuccess: '심사 중으로 업데이트 성공',
    updateUserTypeSuccess: '사용자 유형 업데이트 성공',
    updateVIPLevelSuccess: 'VIP 레벨 업데이트 성공',
    useCommaToSeparateDomain: '여러 도메인을 삽입하는 경우 ","를 사용하십시오',
    validateAdsTypeRequired: '광고 유형이 필요합니다',
    validateActionRequired: '작업이 필요합니다',
    validateActivePlayerRequired: '활성 플레이어가 필요합니다',
    validateAdjustAmountRequired: '조정 금액이 필요합니다',
    validateAdjustReasonRequired: '조정 이유가 필요합니다',
    validateAdjustTypeRequired: '조정 유형이 필요합니다',
    validateAffiliateFbIdRequired: 'Facebook pixel ID is required',
    validateAffiliateFbTokenRequired: 'Facebook pixel token is required',
    validateAffiliateFbUrlRequired: 'Facebook pixel url is required',
    validateAffiliateCodeRequired: '제휴 코드가 필요합니다',
    validateAffiliateLevelRequired: '제휴 수준이 필요합니다',
    validateAffiliateNameRequired: '제휴 이름이 필요합니다',
    validateAliasRequired: '별칭이 필요합니다',
    validateAllBonusRequired: '모든 보너스가 필요합니다',
    validateAlphaNumericOnly: '문자와 숫자만 입력하세요',
    validateAmountRequired: '금액이 필요합니다',
    validateAnnouncementTypeRequired: '공지 유형이 필요합니다',
    validateAnswerOneRequired: '질문 1의 답변이 필요합니다',
    validateAnswerOneAtLeastTwoChoices:
      '질문 1의 답변은 최소한 두 개의 선택지가 있어야 합니다',
    validateAnswerTwoRequired: '질문 2의 답변이 필요합니다',
    validateAnswerTwoAtLeastTwoChoices:
      '질문 2의 답변은 최소한 두 개의 선택지가 있어야 합니다',
    validateAnswerThreeRequired: '질문 3의 답변이 필요합니다',
    validateAnswerThreeAtLeastTwoChoices:
      '질문 3의 답변은 최소한 두 개의 선택지가 있어야 합니다',
    validateAppTypeRequired: '앱 유형이 필요합니다',
    validateAtLeastSixChar: '최소 6자를 입력하세요',
    validateAwardsRequired: '상은 필요합니다',
    validateAwayTeamRequired: '원정팀이 필요합니다',
    validateAwayTeamIconRequired: '원정팀 아이콘이 필요합니다',
    validateBankRequired: '은행이 필요합니다',
    validateBankCardNumberRequired: '은행 카드 번호가 필요합니다',
    validateBankCodeRequired: '은행 코드가 필요합니다',
    validateBankNameRequired: '은행 이름이 필요합니다',
    validateBankTypeRequired: '은행 유형이 필요합니다',
    validateBeanNameRequired: '빈 이름이 필요합니다',
    validateBetAmountRequired: '베팅 금액이 필요합니다',
    validateBonusRequired: '보너스가 필요합니다',
    validateBonusAmountRequired: '보너스 금액이 필요합니다',
    validateBonusAmountRatioRequired: '보너스 금액 비율이 필요합니다',
    validateBonusTypeRequired: '보너스 유형이 필요합니다',
    validateCardAccountRequired: '카드 계정이 필요합니다',
    validateCardAddressRequired: '카드 주소가 필요합니다',
    validateCardNumberRequired: '카드 번호가 필요합니다',
    validateCategoryRequired: '카테고리가 필요합니다',
    validateCauseRequired: '원인이 필요합니다',
    validateChoiceOneRequired: '홈 팀과 원정 팀을 입력하세요',
    validateChoiceTwoRequired: '게임 유형을 선택하세요',
    validateChoiceThreeRequired: '게임 유형을 선택하세요',
    validateChoiceRequired: '선택이 필요합니다',
    validateClassNameRequired: '클래스 이름이 필요합니다',
    validateCodeRequired: '코드가 필요합니다',
    validateCodeExist:
      '코드가 이미 존재합니다. 다른 코드를 입력하세요. - 기존 코드: ',
    validateColorRequired: '색상이 필요합니다',
    validateCommissionRequired: '수수료가 필요합니다',
    validateCommissionFormat: '수수료는 0에서 1 사이여야 합니다',
    validateCommissionModelRequired: '수수료 모델이 필요합니다',
    validateConfigGroupRequired: '구성 그룹이 필요합니다',
    validateConfirmPasswordRequired: '비밀번호 확인이 필요합니다',
    validateConfirmPasswordSize: '비밀번호 확인은 6~12자여야 합니다',
    validateContentRequired: '콘텐츠가 필요합니다',
    validateCopyFinancialLevel: '복사할 최소 1개의 수준을 선택하세요',
    validateCountryImageRequired: '국가 이미지가 필요합니다',
    validateCronExpressionFormat: 'Cron 표현식의 형식이 잘못되었습니다',
    validateCronExpressionRequired: 'Cron 표현식이 필요합니다',
    validateCurrencyRequired: '통화가 필요합니다',
    validateCurrencyCodeRequired: '통화 코드가 필요합니다',
    validateCurrencyNameRequired: '통화 이름이 필요합니다',
    validateCustomerSupportAddressFormat:
      '고객 지원 주소 JSON 문자열이 유효하지 않습니다',
    validateDailyMinRequired: '하루 최소 회전수가 필요합니다',
    validateDailyMaxRequired: '하루 최대 회전수가 필요합니다',
    validateDateRequired: '날짜가 필요합니다',
    validateDayWithdrawCountRequired: '하루 출금 회수가 필요합니다',
    validateDefaultBonusRequired: '기본 보너스가 필요합니다',
    validateDepositExchangeRateRequired: '입금 환율이 필요합니다',
    validateDepositPerSpinRequired: '회전당 입금이 필요합니다',
    validateDesktopImageRequired: '데스크탑 이미지가 필요합니다',
    validateDesktopBannerRequired: '데스크탑 배너가 필요합니다',
    validateDeviceRequired: '장치가 필요합니다',
    validateDomainRequired: '도메인이 필요합니다',
    validateEmailRequired: '이메일이 필요합니다',
    validateEmailFormat: '올바른 이메일 주소 형식을 입력하세요',
    validateEndDateRequired: '종료 날짜가 필요합니다',
    validateEndTimeRequired: '종료 시간이 필요합니다',
    validateExchangeRateRequired: '환율이 필요합니다',
    validateExpressCompanyRequired: '택배 회사가 필요합니다',
    validateFailReasonRequired: '실패 사유가 필요합니다',
    validateFeeRateRequired: '수수료율이 필요합니다',
    validateFreezeTypeRequired: '동결 유형이 필요합니다',
    validateFinancialLevelRequired: '재정 수준이 필요합니다',
    validateFileRequired: '앱 파일이 필요합니다',
    validateFrequencyRequired: '빈도가 필요합니다',
    validateGameCodeRequired: '게임 코드가 필요합니다',
    validateGameNameRequired: '게임 이름이 필요합니다',
    validateGameTypeRequired: '게임 유형이 필요합니다',
    validateHomeTeamRequired: '홈 팀이 필요합니다',
    validateHomeTeamIconRequired: '홈 팀 아이콘이 필요합니다',
    validateIconRequired: '아이콘이 필요합니다',
    validateIdentifyCodeRequired: '식별 코드가 필요합니다',
    validateIpRequired: 'IP가 필요합니다',
    validateJobNameRequired: '작업 이름이 필요합니다',
    validateLabelRequired: '레이블이 필요합니다',
    validateLevelRequired: '레벨이 필요합니다',
    validateLevelNameRequired: '레벨 이름이 필요합니다',
    validateLevelUpCreditRequired: '레벨 업 크레딧이 필요합니다',
    validateLimitNumberRequired: '한도 수가 필요합니다',
    validateLoginNameRequired: '로그인 이름이 필요합니다',
    validateLoginNameSize: '로그인 이름은 6~12자 여야 합니다',
    validateMaintenanceTimeRequired: '유지 보수 시간이 필요합니다',
    validateMallCodeRequired: '몰 코드가 필요합니다',
    validateMallKeyRequired: '몰 키가 필요합니다',
    validateMallNameRequired: '몰 이름이 필요합니다',
    validateMatchTitleRequired: '매치 제목이 필요합니다',
    validateMatchTimeRequired: '매치 시간이 필요합니다',
    validateMaxDailyWithdrawRequired: '하루 최대 출금액이 필요합니다',
    validateMaxDailyWithdrawNumber:
      '하루 최대 출금액은 0보다 작거나 같을 수 없습니다',
    validateMaxDailyWithdrawGreater:
      '최대 하루 출금액이 최대 출금액보다 커야 합니다',
    validateMaxDailyWithdrawTimesRequired: '하루 최대 출금 횟수가 필요합니다',
    validateMaxDailyWithdrawTimesNumber:
      '하루 최대 출금 횟수는 0보다 작거나 같을 수 없습니다',
    validateMaxDepositRequired: '최대 입금액이 필요합니다',
    validateMaxDepositGreater: '최대 입금액은 최소 입금액보다 커야 합니다',
    validateMaxWithdrawAmountRequired: '최대 출금 금액이 필요합니다',
    validateMaxWithdrawAmountNumber:
      '최대 출금 금액은 0보다 작거나 같을 수 없습니다',
    validateMaxWithdrawAmountGreater:
      '최대 출금 금액은 최소 출금 금액보다 커야 합니다',
    validateMemberTypeRequired: '회원 유형이 필요합니다',
    validateMemberIdRequired: '회원 ID가 필요합니다',
    validateMenuTitleRequired: '메뉴 제목이 필요합니다',
    validateMinBalanceRequired: '최소 잔액이 필요합니다',
    validateMinDepositRequired: '최소 입금액이 필요합니다',
    validateMinDepositLesser:
      '최소 입금 금액은 최대 입금 금액보다 작아야 합니다',
    validateMinWithdrawRequired: '최소 출금액이 필요합니다',
    validateMinWithdrawNumber: '최소 출금액은 0보다 작거나 같을 수 없습니다',
    validateMinWithdrawLesser:
      '최소 출금 금액은 최대 출금 금액보다 작아야 합니다',
    validateMobileImageRequired: '모바일 이미지가 필요합니다',
    validateMobileBannerRequired: '모바일 배너가 필요합니다',
    validateMobileMessageRequired: '모바일 메시지가 필요합니다',
    validateNameRequired: '이름이 필요합니다',
    validateNeedRegisterRequired: '등록이 필요합니다',
    validateNetProfitRequired: '순이익이 필요합니다',
    validateNextActivationTimeRequired: '다음 활성 시간이 필요합니다',
    validateNextGetBetEndTime: '다음 베팅 종료 시간이 필요합니다',
    validateNextGetBetPage: '다음 베팅 페이지가 필요합니다',
    validateNextGetBetStartTime: '다음 베팅 시작 시간이 필요합니다',
    validateNoNullValue: '빈 값은 허용되지 않습니다',
    validateNoRepeatAmount: '동일한 금액이 존재할 수 없습니다',
    validateNumberOnly: '숫자만 입력하세요',
    validateNumberFourDecimalOnly:
      '숫자를 입력하세요. 0보다 크고 소수점 이하 4자리까지만 허용됩니다',
    validateOneTimeSpinRequired: '한 번 회전이 필요합니다',
    validateOnlyThreeChar: '3자리 문자만 입력하세요',
    validateOrderTrackingNoRequired: '주문 추적 번호가 필요합니다',
    validateOsRequired: '운영 체제를 하나 이상 선택하세요',
    validateParamRequired: '매개 변수가 필요합니다',
    validatePasswordRequired: '비밀번호가 필요합니다',
    validatePasswordSize: '비밀번호는 6~12자 여야 합니다',
    validatePaymentFeeFormat: '지불 수수료는 0에서 1 사이여야 합니다',
    validatePaymentNameRequired: '지불 이름이 필요합니다',
    validatePaymentTypeRequired: '지불 유형이 필요합니다',
    validatePayResultTypeRequired: '결제 결과 유형이 필요합니다',
    validatePayTypeRequired: '결제 유형이 필요합니다',
    validatePayTypeCodeRequired: '결제 유형 코드가 필요합니다',
    validatePayTypeNameRequired: '결제 유형 이름이 필요합니다',
    validatePayTypeNameCodeDifferent:
      '결제 유형 이름과 결제 유형 코드는 동일한 값이 될 수 없습니다',
    validatePlatformRequired: '플랫폼이 필요합니다',
    validatePlatformAccountRequired: '플랫폼 계정이 필요합니다',
    validatePlatformCodeRequired: '플랫폼 코드가 필요합니다',
    validatePlatformFeeFormat: '플랫폼 수수료는 0에서 1 사이여야 합니다',
    validatePlatformNameRequired: '플랫폼 이름이 필요합니다',
    validatePrivilegeRequired: '특권 이름이 필요합니다',
    validatePromoCodeRequired: '프로모션 코드가 필요합니다',
    validatePromoTypeRequired: '프로모션 유형이 필요합니다',
    validateQueryNumberRequired: '쿼리 번호가 필요합니다',
    validateQueryRestrictionRequired: '쿼리 제한이 필요합니다',
    validateQuestionOneRequired: '질문 1이 필요합니다',
    validateQuestionRequired: '질문이 필요합니다',
    validateQuizTimeRequired: '퀴즈 시간이 필요합니다',
    validateQuizTitleRequired: '퀴즈 제목이 필요합니다',
    validateRateRequired: '비율이 필요합니다',
    validateRealNameRequired: '실명이 필요합니다',
    validateReasonRequired: '이유가 필요합니다',
    validateReasonTypeRequired: '이유 유형이 필요합니다',
    validateRebatePercentageRequired: '리베이트 비율이 필요합니다',
    validateMaxRebateRequired: '최대 리베이트가 필요합니다',
    validateRecipientRequired: '수취인이 필요합니다',
    validateRecordTimeRequired: '기록 시간이 필요합니다',
    validateRedeemPointsRequired: '적립 포인트가 필요합니다',
    validateRedirectRequired: '리디렉션이 필요합니다',
    validateReenterPasswordRequired: '비밀번호를 다시 입력하세요',
    validateRemarkRequired: '비고가 필요합니다',
    validateRequestUrlRequired: '요청 URL이 필요합니다',
    validateResultDateRequired: '결과 날짜가 필요합니다',
    validateResultNumber: '3 자리 숫자가 필요합니다',
    validateRebateFormat: '리베이트는 0에서 1 사이여야 합니다',
    validateRevenueShareFormat: '수익 공유는 0에서 1 사이여야 합니다',
    validateRewardGroupRequired: '보상 그룹이 필요합니다',
    validateRewardGroupNameRequired: '보상 그룹 이름이 필요합니다',
    validateRewardGroupDownlineQuotaRequired: '하위 쿼터가 필요합니다',
    validateRewardGroupDownlineQuotaFormat: '하위 쿼터는 0보다 커야 합니다',
    validateRiskLevelRequired: '위험 수준이 필요합니다',
    validateRoleRequired: '역할이 필요합니다',
    validateRoleNameRequired: '역할 이름이 필요합니다',
    validateRolloverRequired: '롤오버가 필요합니다',
    validateRoundRequired: '라운드가 필요합니다',
    validateRulesRequired: '규칙이 필요합니다',
    validateSchemaNameRequired: '스키마 이름이 필요합니다',
    validateSequenceRequired: '시퀀스가 필요합니다',
    validateSiteRequired: '사이트가 필요합니다',
    validateSiteCodeRequired: '사이트 코드가 필요합니다',
    validateStageRequired: '단계가 필요합니다',
    validateStartTimeRequired: '시작 시간이 필요합니다',
    validateStateRequired: '상태가 필요합니다',
    validateStatusRequired: '상태가 필요합니다',
    validateStepsRequired: '단계 설정이 필요합니다',
    validateStepBonusRequired: '보너스 설정이 필요합니다',
    validateStopAfterFailureRequired: '실패 후 중지가 필요합니다',
    validateSubjectRequired: '제목이 필요합니다',
    validateSuccess: '유효성 검사 성공',
    validateSupportTypeRequired: '지원 유형이 필요합니다',
    validateSureWinBonusRequired: '확실한 승리 보너스가 필요합니다',
    validateSureWinMinSpinRequired: '확실한 승리 최소 회전이 필요합니다',
    validateTeamGroupRequired: '팀 그룹이 필요합니다',
    validateTeamIconRequired: '팀 아이콘이 필요합니다',
    validateTeamNameRequired: '팀 이름이 필요합니다',
    validateTeamNameEnRequired: '영어 팀 이름이 필요합니다',
    validateTeamNameLocalRequired: '로컬 팀 이름이 필요합니다',
    validateTeamOneRequired: '팀 1이 필요합니다',
    validateTeamOneIconRequired: '팀 1 아이콘이 필요합니다',
    validateTeamTwoRequired: '팀 2가 필요합니다',
    validateTeamTwoIconRequired: '팀 2 아이콘이 필요합니다',
    validateTelephoneRequired: '전화번호가 필요합니다',
    validateThirdSerialNumberRequired: '세 번째 일련 번호가 필요합니다',
    validateThreeDigitsNumbeRequired: '3 자리 숫자가 필요합니다',
    validateTitleRequired: '제목이 필요합니다',
    validateTimeTypeRequired: '시간 유형이 필요합니다',
    validateTransactionIdRequired: '거래 ID가 필요합니다',
    validateTransactionStatusRequired: '거래 상태가 필요합니다',
    validateTriggerTypeRequired: '트리거 유형이 필요합니다',
    validateTypeRequired: '유형이 필요합니다',
    validateUrlFormat: '올바른 URL을 입력하세요',
    validateUsernameRequired: '사용자 이름이 필요합니다',
    validateUsernameSize: '사용자 이름은 6-12 자여야 합니다',
    validateUserTypeRequired: '사용자 유형이 필요합니다',
    validateValidBetPerSpinRequired: '스핀 당 유효 베팅이 필요합니다',
    validateValueRequired: '값이 필요합니다',
    validateVIPRequired: 'VIP가 필요합니다',
    validateWalletTypeRequired: '지갑 유형이 필요합니다',
    validateWayRequired: '방법이 필요합니다',
    validateWebMessageRequired: '웹 메시지가 필요합니다',
    validateWholeNumberOnly: '정수만 입력하세요',
    validateWinnerRequired: '우승자가 필요합니다',
    validateWithdrawExchangeRateRequired: '출금 환율이 필요합니다',
    validateWithdrawPlatformNameRequired: '출금 플랫폼 이름이 필요합니다',
    validateTigerCardPeriodRequired: '기간이 필요합니다',
    validateTigerCardTimeRequired: '복권 시간이 필요합니다',
    validateTigerCardBonusTimeRequired: '보너스 수령 시간이 필요합니다',
    validateTigerSumAwardRequired: '총 보너스가 필요합니다',
    validateVirtualCountRequired: '개수가 필요합니다',
    validateHostRequired: '호스트가 필요합니다',
    validatePortRequired: '포트가 필요합니다',
    validateSignNameRequired: '서명 이름이 필요합니다',
    validateSearchCondition: '최소한 하나의 쿼리 조건을 입력하세요',
    validateSecretIdRequired: '비밀 ID가 필요합니다',
    validateSecretKeyRequired: '비밀 키가 필요합니다',
    validateAppIdRequired: '애플리케이션 ID가 필요합니다',
    validateTemplateIdRequired: '템플릿 ID가 필요합니다',
    requestExportToExcelDone1:
      '요청이 성공적으로 전송되었습니다. 진행 상황을 확인하고 다운로드하려면 ',
    requestExportToExcelDone2: '를 클릭하세요.',
    validateImageRequired: '이미지가 필요합니다',
    validateImageNameRequired: '이미지 이름이 필요합니다',
    validatePosterTypeRequired: '포스터 유형이 필요합니다',
    validateConfigCodeRequired: '구성 코드가 필요합니다',
    validateConfigValueRequired: '구성 유형이 필요합니다',
    validateConfigTypeRequired: 'Config Type is required',
    resultCalculateSuccess: '결과가 성공적으로 계산되었습니다',
    buildIsInQueue: '빌드가 대기열에 있습니다',
    roleMustOnlyOne: '권한이 필요한 역할을 하나만 선택하세요',
    validateCompetitionTypeRequired: '경기 유형이 필요합니다',
    validateCompetitionNameRequired: '경기 이름이 필요합니다',
    validateCompetitionTimeRequired: '경기 시간이 필요합니다',
    validateKeyQuantityRequired: '키 수량이 필요합니다',
    validateMonthRequired: '연도/월이 필요합니다',
    validateTimeRequired: '시간이 필요합니다',
    syncMemberDetailSuccess: '회원 세부 정보 동기화 성공',
    timeExist: '시간이 이미 존재합니다',
    wrongPopupTime: '선택한 팝업 시간이 유효하지 않습니다',
    selectPopupTime: '팝업 시간을 선택하세요.',
    updateAffiliateLevelSuccess: '가맹점 레벨 변경 성공',
    updateShareRatioSuccess: '공유율 업데이트 성공',
    validateShareRatioFormat: '공유 비율은 0에서 1 사이여야 합니다.',
    validateMinDayRegLimitRequired: '최소 일일 등록 제한이 필요합니다',
    validateMaxDayRegLimitRequired: '최대 일일 등록 제한이 필요합니다',
    validateMinDayDepositAmountRequired: '일일 최소 입금액이 필요합니다',
    validateMaxDayDepositAmountRequired: '일일 최대 입금액이 필요합니다',
    validateDayRegProbabilityRequired: '일일 등록 확률이 필요합니다',
    validateDayDepositAmountProbabilityRequired:
      '일일 첫 입금 확률이 필요합니다',
    validateMediaDescRequired: '미디어 설명 필요',
    validateMediaUrlRequired: '동영상 링크가 필요합니다',
    validateDisplayUrlRequired: '비디오 디스플레이 링크가 필요합니다',
    validateModeRequired: '모드 필요',
    validateAutoplayRequired: '자동재생 필요',
    validatePlanOddsRequired: '투자 이자율 필요',
    validatePlanDaysRequired: '필요한 투자 기간',
    validatePlanLimitMinRequired: '필요한 최소 투자 한도',
    validatePlanLimitMaxRequired: '필요한 최대 투자 한도',
    affiliateReactivated: '활성화 성공',
    required_6_digits_code: '6자리 숫자로 구성',
    required_only_digits_and_alphabet: '숫자와 알파벳으로 구성되어야 합니다.',
  },
  menu: {
    undefined: '',
    Dashboard: '대시보드',
    Member: '회원',
    'Member List': '회원 목록',
    'Member List ID': '회원 목록',
    'Member Details': '회원 세부 정보',
    'Member Edit Log': '회원 편집 로그',
    'Member Feedback': '회원 피드백',
    'Member Freeze Record': '회원 동결 기록',
    'Member Amount Adjust': '회원 금액 조정',
    'Member Privilege Blacklist': '회원 특권 블랙리스트',
    'Member Bank Card History': '회원 은행 카드 이력',
    'Member Sms': '회원 SMS 이력',
    'Import Bet Records': '베팅 기록 가져오기',
    'Member Transfer Record': '회원 이체 기록',
    'Member VIP': '회원 VIP',
    'Member Bet Record': '회원 베팅 기록',
    'PG Pending': 'PG 대기 중인 기록',
    'Member Privilege': '회원 특권',
    'Member Rollover Record': '회원 롤오버 기록',
    'Member Distribute Privilege': '회원 특권 배포',
    'Member Profit Ranking': '회원 수익 순위',
    'Member Affiliate Change Log': 'Member Affiliate Change Log',
    'Member Group Send SMS': 'Member Group Send SMS',
    Affiliate: '제휴사',
    'Affiliate Amount Adjust': '제휴사 금액 조정',
    'Affiliate Summary': '제휴사 요약',
    'Affiliate Monthly Settlement': '제휴사 월간 정산',
    'Affiliate Monthly Settlement Detail': '제휴사 월간 정산 세부 정보',
    'Affiliate Settlement Checking': '제휴사 정산 확인',
    'Affiliate Settlement Payment': '제휴사 정산 지급',
    'Affiliate Domain Management': '제휴사 도메인 관리',
    'Affiliate List': '제휴사 목록',
    'Affiliate Details': '제휴사 세부 정보',
    'Affiliate Financial Management': '제휴사 재무 관리',
    'Affiliate Facebook Pixel Management':
      'Affiliate Facebook Pixel Management',
    'Affiliate Deposit Display': '제휴사 입금 표시',
    'Site Affiliate Commission': '사이트 제휴사 수수료',
    'Aff Announcement Management': '제휴사 공지 관리',
    'Affiliate Announcement': '제휴사 공지',
    'Affiliate Announcement Type': '제휴사 공지 유형',
    'App Version': '앱 버전 관리',
    'Auto Withdraw Setting': '자동 출금 설정',
    'Deposit Management': '입금 관리',
    'Online Deposit': '온라인 입금',
    'Deposit Record': '입금 기록',
    'Withdrawal Auto Process': '자동 출금 처리',
    'Withdrawal Management': '출금 관리',
    'Withdrawal Process': '출금 처리',
    'Withdrawal Affiliate Process': '제휴사 출금 처리',
    'Withdrawal Record': '출금 기록',
    'Withdrawal Affiliate Record': '제휴사 출금 기록',
    'Withdrawal Platform Balance': '출금 플랫폼 잔액',
    'Withdraw Review': '출금 검토',
    'Withdrawal Bank Blacklist': '출금 은행 블랙리스트',
    'Withdraw Tips Setting': '출금 팁 설정',
    'Reward Group': '보상 그룹',
    Applying: '신청 중',
    'Affiliate Applying': '제휴사 신청 중',
    'Affiliate Under review': '제휴사 심사 중',
    BW1: 'BW1',
    BW2: 'BW2',
    BW3: 'BW3',
    BW4: 'BW4',
    BW5: 'BW5',
    'To be affiliate paid': '제휴사 지급 예정',
    'Affiliate Payment on going': '제휴사 지급 진행 중',
    'Crypto Affiliate Payment on going': '암호화폐 제휴사 지급 진행 중',
    'Ewallt Affiliate Payment on going': '전자 지갑 제휴사 지급 진행 중',
    'Alipay Affiliate Payment on going': '알리페이 제휴사 지급 진행 중',
    'Affiliate Automatic Payment': '제휴사 자동 지급',
    'Affiliate Suspend': '제휴사 정지',
    'Under review': '심사 중',
    'To be paid': '지급 예정',
    'Payment on going': '지급 진행 중',
    'Crypto Payment on going': '암호화폐 지급 진행 중',
    'Ewallt Payment on going': '전자 지갑 지급 진행 중',
    'Alipay Payment on going': '알리페이 지급 진행 중',
    'Automatic Payment': '자동 지급',
    'Waiting Auto Pay': '자동 지급 대기 중',
    'AutoWithdraw Under review': '자동 출금 심사 중',
    'AutoWithdraw Withdrawal Record': '자동 출금 출금 기록',
    Suspend: '정지',
    Promotion: '프로모션',
    'Privilege Info': '특권 정보',
    'Home Banner': '홈 배너',
    'Promotion pages': '프로모션 페이지',
    'Edit Promo': '프로모션 수정',
    'Multi Wheel': '멀티 휠',
    'Multi Wheel List': '멀티 휠 회원 목록',
    'Multi Wheel Setting': '멀티 휠 설정',
    'Multi Wheel Additional List': '멀티 휠 추가 목록',
    'Team Votes': '팀 투표',
    'Team Votes Records': '팀 투표 기록',
    'Team Votes Settings': '팀 투표 설정',
    'Tiger Card': '호랑이 카드',
    'Telephone Numbers': '전화 번호',
    'Ads Popout': '광고 팝업',
    'Add Ads Popout': '광고 팝업 추가',
    'Edit Ads Popout': '광고 팝업 수정',
    'Red Packet Rain': '빨간색 포켓 비',
    'Red Packet Rain List': '빨간색 포켓 비 목록',
    'Member Free Game': '회원 무료 게임',
    Lottery: '로또',
    'Lottery Record List': '로또 기록 목록',
    'Lottery Result List': '로또 결과 목록',
    'Vietnam Lottery Record List': '베트남 로또 기록 목록',
    'Vietnam Lottery Result List': '베트남 로또 결과 목록',
    'VIP Rebate Record': 'VIP 리베이트 기록',
    'Bet Rebate Record': '베팅 리베이트 기록',
    'Refer Friend': '친구 추천',
    'Refer Friend Rebate Record': '친구 추천 리베이트 기록',
    Gift: '선물 관리',
    'Gift Info': '선물 정보',
    'Gift Record': '선물 기록',
    'Promo Application': '프로모션 신청',
    'Game Steps': '게임 스텝',
    'BB Dacha': 'BB 다차',
    'VIP Wheel': 'VIP 휠',
    'Bet Wheel': '베팅 휠',
    'Piggy Bank': '돼지 저금통',
    'Site Management': '사이트 관리',
    Message: '메시지',
    Announcement: '공지',
    'Announcement Type': '공지 유형',
    'VIP Rebate': 'VIP 리베이트',
    VIP: 'VIP',
    Game: '게임',
    'Game Tag': '게임 태그',
    'Financial Level': '금융 레벨',
    'Risk Level': '리스크 레벨',
    'Ip Label': 'IP 라벨',
    'Site Platform Management': '사이트 플랫폼 관리',
    'Game Match': '게임 매치',
    'Game Quiz': '게임 퀴즈',
    'Member Rebate': '회원 리베이트',
    Questionnaire: '설문지',
    UEFA: 'UEFA',
    'UEFA Team': 'UEFA 팀',
    'UEFA Match': 'UEFA 매치',
    'Refer Friend Manager': '친구 추천 관리자',
    Permission: '권한',
    Menu: '메뉴',
    Roles: '역할',
    User: '사용자',
    Report: '보고서',
    'Privilege Record': '특권 기록',
    'Summary Report': '요약 보고서',
    'Privilege Report': '특권 보고서',
    'Deposit Report': '입금 보고서',
    'Withdraw Report': '출금 보고서',
    'Finance Report': '금융 보고서',
    'Affiliate Report': '제휴사 보고서',
    'Affiliate Deposit Members': '제휴사 입금 회원',
    'Platform Game Report': '플랫폼 게임 보고서',
    'Site Member Report': '사이트 회원 보고서',
    'Deposit Member Detail': '입금 회원 상세',
    System: '시스템',
    'Operator Log': '운영자 로그',
    'Member Platform': '회원 플랫폼',
    Site: '사이트',
    Config: '설정',
    Currency: '통화',
    Platform: '플랫폼',
    'Site Platform': '사이트 플랫폼',
    Schedule: '일정',
    'Platform Account': '플랫폼 계정',
    'Data Dictionary': '데이터 사전',
    'Manual Summary': '수동 요약',
    'Affiliate Manual Summary': '제휴사 수동 요약',
    'Payment Management': '지불 관리',
    'Payment Display List': '지불 디스플레이 목록',
    'Bank Info': '은행 정보',
    'Bank Card': '은행 카드',
    'Payment Type': '지불 유형',
    Payment: '지불',
    'Add Payment': '지불 추가',
    'Edit Payment': '지불 수정',
    CurrencyRate: '통화 환율',
    'Payment Display': '지불 디스플레이',
    'Withdraw Platform': '출금 플랫폼',
    'Withdraw Setting': '출금 설정',
    'Site Withdraw Platform': '사이트 출금 플랫폼',
    'Deposit Setting': '입금 설정',
    'Withdraw Display': '출금 디스플레이',
    'Payment Bank Management': '지불 은행 관리',
    'Withdraw Platform Bank Management': '출금 플랫폼 은행 관리',
    'Summary Register Report': '요약 등록 보고서',
    'Summary Fdp Report': '요약 Fdp 보고서',
    'Summary Deposit Report': '요약 입금 보고서',
    'Summary Withdraw Report': '요약 출금 보고서',
    'Summary Active Report': '요약 활동 보고서',
    'Summary Member Report': '요약 회원 보고서',
    'Finance Feedback': '금융 피드백',
    'Email Setting': '이메일 설정',
    'SMS Setting': 'SMS 설정',
    DownloadManager: '다운로드 관리자',
    'Error Queue': '오류 메시지 대기열',
    GoogleAuth: 'Google 인증',
    'Image Setting': '이미지 설정',
    'Privi Christmas': '메리 크리스마스',
    'Affiliate APK Build': '제휴사 APK 빌드',
    'Channel Summary': '채널 요약',
    'Adjustment Reason': '조정 사유',
    'User Login Log': '사용자 로그인 로그',
    'Online Stats': '온라인 통계',
    'Promotion Activity': '프로모션 활동',
    'Platform Competition': '플랫폼 경쟁',
    'Platform Competition Code': '플랫폼 경쟁 코드',
    'Event Treasure Key': '이벤트 보물 열쇠 기록',
    'Event Check In Record': '이벤트 체크인 기록',
    'User Action Log': '사용자 작업 로그',
    'Competition Management': '경쟁 관리',
    'Competition List': '경쟁 목록',
    'Competition Code': '경쟁 코드',
    'Affiliate Recalculate': '제휴사 재계산',
    Utils: '유틸리티',
    'Ip Location': 'IP 위치',
    'Member Summon Reward Record': '회원 소환 보상 기록',
    'Redirect Notification': '리디렉션 알림',
    'Member Summon Event': '회원 소환 이벤트',
    'Member Summon List': '회원 소환 목록',
    'Affiliate Bet Rebate Record': '제휴 베팅 리베이트 기록',
    'KRW Stats Report': '한국 사이트 데이터 보고서',
    'MQ Log': 'MQ 로그',
    'System Config': '시스템 설정',
    'Promotion Coupon': '쿠폰',
    'Member Invite Limit': '회원 초대 제한',
    'Member Point Record': '회원 포인트 기록',
    'Affiliate Change Log': '제휴 부모 변경 로그',
    'Media Display Settings': '미디어 디스플레이 설정',
    'Vip Report': 'VIP 보고서',
    'System Value Rules': '설정 규칙',
    'Sites Config': '사이트 설정',
  },
  google: {
    google_auth_menu: 'Google 인증자',
    download_install: '앱 다운로드',
    add_key: '보안 키 추가',
    bind_verify: 'Google 인증 바인딩',
    popup_title: 'Google 인증자',
    popup_msg:
      'Google 인증자는 자동으로 30초마다 새로 고침되는 동적 비밀번호 도구입니다. 자금 이체, 출금 또는 프록시의 백엔드에서 보안 설정을 조정하는 등의 민감한 작업을 수행할 때 인증을 위해 이 6자리 신원 확인 코드를 입력해야 합니다.',
    popup_hint: '신원 인증자는 프록시 계정과 함께 사용해야 합니다.',
    i_know: '알겠습니다',
    download_way: '다운로드 방법：',
    download_through_way: '1. 다운로드 링크를 통해：',
    ios_download: 'iOS 다운로드',
    android_download: 'Android 다운로드',
    ios_scan_download: 'iOS 다운로드 스캔',
    android_scan_download: 'Android 다운로드 스캔',
    if_cannot_download_you_can:
      '2. 다운로드할 수 없는 경우 Apple App Store에서 검색할 수 있습니다."',
    or_search_in_google: '", 또는 Android App Store에서 검색할 수 있습니다.',
    download_and_install: '"로 다운로드 및 설치。',
    next_step: '다음',
    keyin_pass: '암호 입력 & 다음',
    keyin_your_password: '로그인 암호 입력',
    tips_installed:
      '팁: 다운로드 및 설치가 완료되면 "다음"을 클릭하여 계속 진행하십시오.',
    qr_code: 'QR 코드',
    secret_key: '비밀 키',
    add_step: '추가 단계',
    add_step_desc:
      'Google 인증자를 열고 오른쪽 아래 모서리의 "+"를 클릭한 다음 "키 수동 입력"을 선택하여 위에서 언급한 비밀 키와 바인딩할 어떤 계정을 입력하십시오. (QR 코드를 스캔하면 자동으로 추가될 수 있습니다.)',
    check_example: '예제 보기',
    back: '뒤로',
    small_tips: '팁:',
    small_tips_info_1:
      '1. 핸드폰을 잃어버리거나 인증 앱을 삭제한 경우 키를 사용하여 인증자를 복구할 수 있습니다. 키를 안전하게 보관하십시오.',
    small_tips_info_2:
      '2. 계정의 보안을 위해 바인딩 과정에서 프록시 계정과 프록시 백엔드 주소를 표시하지 마십시오.',
    keyin_6_digit_google: 'Google 6자리 인증 코드를 입력하세요.',
    auth_code: 'Google 인증자',
    bind_now: '지금 바인딩',
    please_enter_password: '암호를 입력하세요.',
    added_success: 'Google 인증 코드가 성공적으로 추가되었습니다.',
    if_not_google_auth_then:
      'Google 인증자를 바인딩하지 않았다면 작성할 필요가 없습니다.',
    google_auth_code: '6자리 Google 인증자 코드를 입력하세요.',
    register_count: '오늘 등록된 수',
    register_limit: '오늘 등록 가능한 수',
  },
  posterType: {
    overall: '전체 추천',
    app: '앱 추천',
    sponsor: '스폰서 추천',
    gift: '선물 추천',
    competition: '대회 추천',
    event: '이벤트 추천',
    crypto: '암호화폐 추천',
    affiliate: '제휴 혜택',
  },
  siteAppVersion: {
    version: '버전',
    os: '운영 체제',
    appType: '앱 유형',
    apkType: '앱 비밀 키',
    fileUploadedSuccessfully: '파일 업로드 성공',
    IOS: 'iOS',
    ANDROID: '안드로이드',
    ALL_SITE: '모든 사이트',
    SPORT: '스포츠 앱',
    ESPORT: '이스포츠 앱',
    NORMAL: '일반',
    NEW_KEY: '신규 키',
    appUpload: '앱 업로드',
    editAppUpload: '앱 업로드 편집',
  },
  affiliateApk: {
    currentVersion: '현재 버전',
    buildStatus: '빌드 상태',
    latestVersion: '최신 버전',
    buildApk: 'APK 빌드',
    editParam: '매개 변수 편집',
    status: {
      IN_QUEUE: '대기 중',
      IN_PROGRESS: '진행 중',
      SUCCESS: '성공',
    },
  },
  priviEvent: {
    status: '기록 상태',
    cancel: '취소됨',
    active: '활성',
    settled: '해결됨',
  },
  realtimeStatistics: {
    APP: '앱',
    H5: 'H5',
    Mobile: '모바일',
    PC: 'PC',
    APPLY_WITHDRAW: '출금 신청',
  },
  loginDevice: {
    WEB: '웹',
    H5: 'H5',
    ANDROID: '안드로이드',
    IOS: 'iOS',
  },
  result: {
    win: '승',
    draw: '무',
    loss: '패',
  },
  freeze: {
    NORMAL: '일반',
    TEMPORARY: '일시적',
    PERMANENT: '영구적',
    UNFREEZE: '해제',
  },
  reviewStatus: {
    PENDING: '대기 중',
    APPROVED: '승인됨',
    REJECTED: '거부됨',
  },
  week: {
    monday: '월요일',
    tuesday: '화요일',
    wednesday: '수요일',
    thursday: '목요일',
    friday: '금요일',
    saturday: '토요일',
    sunday: '일요일',
  },
  domainType: {
    LANDING: '방문 페이지',
    DOWNLOAD: '다운로드 페이지',
    AFFILIATE: '연기',
    HOME: '첫 장',
  },
  affiliateShareRatio: {
    AGENT_SHARE_BET_RATIO_FISH: '배팅비율-FISH',
    AGENT_SHARE_BET_RATIO_SLOT: '배팅비율-SLOT',
    AGENT_SHARE_BET_RATIO_ESPORT: '배팅비율-ESPORT',
    AGENT_SHARE_BET_RATIO_LIVE: '배팅비율-LIVE',
    AGENT_SHARE_BET_RATIO_SPORT: '배팅비율-SPORT',
    AGENT_SHARE_BET_RATIO_CASUAL: '배팅비율-CASUAL',
    AGENT_SHARE_BET_RATIO_POKER: '배팅비율-POKER',
    AGENT_SHARE_PROFIT_RATIO_FISH: '이익률-FISH',
    AGENT_SHARE_PROFIT_RATIO_SLOT: '이익률-SLOT',
    AGENT_SHARE_PROFIT_RATIO_ESPORT: '이익률-ESPORT',
    AGENT_SHARE_PROFIT_RATIO_LIVE: '이익률-LIVE',
    AGENT_SHARE_PROFIT_RATIO_SPORT: '이익률-SPORT',
    AGENT_SHARE_PROFIT_RATIO_CASUAL: '이익률-CASUAL',
    AGENT_SHARE_PROFIT_RATIO_POKER: '이익률-POKER',
  },
  error: {
    400: '잘못된 요청',
    401: '인증되지 않은 요청',
    403: '금지됨',
    404: '리소스를 찾을 수 없음',
    405: '허용되지 않는 메서드',
    500: '시스템 오류',
    501: '중복된 요청 ID',
    502: '서버를 찾을 수 없음',
    504: '너무 자주 요청함',
    601: '토큰 확인 오류',
    602: '토큰이 만료됨',
    603: '토큰이 누락됨',
    604: '계정이 이미 로그인됨',
    605: '계정이 이미 로그아웃됨',
    606: '선택한 역할이 이미 사용자에게 할당되었습니다. 다시 시도하기 전에이 역할을 할당 해제하십시오',
    607: '사용자 클래스가 사이트 관리자가 아닐 때 사이트를 비워 둘 수 없습니다',
    700: '이전 암호와 새 암호는 같을 수 없습니다',
    701: '매개 변수가 이미 존재함',
    707: '잔액이 부족함',
    800: '캡차 확인 오류',
    801: '캡차 코드가 만료됨',
    900: '회원 세부 정보를 찾을 수 없음',
    901: '제휴 기록을 찾을 수 없음',
    922: '귀하의 계정은 vcall 구성에 링크되어 있지 않습니다',
    1000: '로그인 실패, 사용자 이름 또는 비밀번호가 잘못되었습니다',
    1001: '로그인 실패, 계정이 비활성화되었습니다',
    1005: '로그인 실패, 계정이 잠겼습니다. 내일 다시 시도하십시오',
    1006: '로그인 실패, 계정이 존재하지 않습니다',
    1007: '출금 주문이 제출되었습니다. 20 분 후 다시 시도하십시오',
    1008: 'Google Authenticator가 이미 바인딩되어 있습니다. Google 인증 코드를 입력하십시오.',
    1009: 'Google 인증 코드가 잘못 입력되었습니다.',
    1100: '관리자는 로그인이 제한됩니다',
    1101: '로그인 계정이이 사이트에 속하지 않습니다',
    9000: '예약 된 작업 생성 실패',
    9001: '예약 된 작업 업데이트 실패',
    9002: '예약 된 작업 삭제 실패',
    10009: '최소한 하나의 쿼리 조건을 입력하십시오',
    10010: '시스템 공지 유형 이름이 이미 존재합니다',
    10011: '시스템 공지 제목이 이미 존재합니다',
    10012: '시스템 공지 유형이 존재하지 않습니다',
    10013: '공지 유형이 기존 공지에서 사용 중입니다. 기존 공지를 삭제하십시오',
    10020: '배너 제목이 이미 존재합니다',
    10021: '배너 ID를 찾을 수 없습니다',
    10030: '프로모션 제목이 이미 존재합니다',
    10031: '프로모션 ID를 찾을 수 없습니다',
    10032: '이미지의 잘못된 파일 유형',
    10033: '이미지 배너 업로드 실패',
    10034: '이미지 프로모션 업로드 실패',
    1004: 'Google Authenticator가 이미 추가되었습니다. Google 인증 코드를 입력하십시오.',
    10040: '시스템 위험 수준 이름이 이미 존재합니다',
    10041: '시스템 위험 수준 색상이 이미 존재합니다',
    10050: '시스템 IP 레이블이 이미 존재합니다',
    10051: '시스템 IP 레이블이 존재하지 않습니다',
    10110: '플랫폼 검색 보류중인 거래 오류',
    10111: '플랫폼 업데이트 보류중인 거래 오류',
    11000: '제휴사가 존재하지 않음',
    11006: '사이트 제휴 수수료가 이미 존재합니다',
    11007: '사이트 제휴 수수료가 존재하지 않습니다',
    11008: '제휴 기록이 존재하지 않습니다',
    11009: '제휴 수수료는 자식 제휴 수수료보다 커야합니다:',
    11010: '추천 제휴는 하위 제휴를 가질 수 없습니다',
    11011: '우수 대리점이 리베이트 비율을 설정하지 않았습니다.',
    11012: '우수 에이전트는 게임에 대한 리베이트 비율을 설정하지 않았습니다.',
    11013: '하위 에이전트의 리베이트 비율은 상위 에이전트의 리베이트 비율을 초과할 수 없습니다.',
    11014: '하위 대리점의 총 리베이트 비율은 상위 대리점의 리베이트 비율을 초과할 수 없습니다.',
    11015: '본 에이전트는 리베이트 비율을 설정하지 않습니다.',
    11016: '플레이어의 리베이트 비율은 에이전트의 리베이트 비율을 초과할 수 없습니다.',
    12000: '시스템 권한 정보 이름이 이미 존재합니다',
    12001: '시스템 권한 정보 코드가 이미 존재합니다',
    12002: '권한 그룹을 삽입하십시오',
    12010: '통화 이름이 이미 존재합니다',
    12011: '이 사이트에 대해 이미 통화 환율이 설정되었습니다',
    12020: "현재 레코드의 상태가 '보내는 중'이 아닙니다",
    12030: '사이트 이름이 이미 존재합니다',
    12031: '사이트 코드가 이미 존재합니다',
    12032: '이 사이트는 상위 사이트입니다',
    12033: '상위 사이트를 변경할 수 없습니다',
    12034: '결제 이름이 이미 존재합니다',
    12035: '결제 코드가 이미 존재합니다',
    12036: '시스템 권한 정보가 존재하지 않습니다',
    12037: '시스템 결제 ID가 존재하지 않습니다',
    12038: '금액이 최대 보너스를 초과합니다',
    12039: '회원이 이미 권한에 블랙리스트에 올려져 있습니다',
    12040: '시스템 결제 이름이 존재하지 않습니다',
    12041: '몰 이름이 이미 존재합니다',
    12042: '몰 코드가 이미 존재합니다',
    12050: '시스템 은행이 존재하지 않습니다',
    12051: '시스템 은행 이름이 이미 존재합니다',
    12052: '시스템 은행 코드가 이미 존재합니다',
    12053: '시스템 은행 카드 식별 코드가 이미 존재합니다',
    12054: '시스템 은행 카드가 이미 존재합니다',
    12055: '사이트 이미지가 사용 중입니다',
    14000: '로그인 이름이 이미 존재합니다',
    14001: '전화번호가 이미 존재합니다',
    14002: '이메일이 이미 존재합니다',
    14003: '제휴 코드가 존재하지 않습니다',
    14004: '제휴 코드가이 사이트에 속하지 않습니다',
    14005: '제휴 수수료는 상위 제휴 수수료보다 작아야합니다:',
    14006: '제휴 수입은 상위 제휴 수입보다 작아야합니다',
    14007: '제휴 등록 상태가 아직 대기 중입니다',
    14008: '제휴 수수료는 상위 제휴 수수료보다 커야합니다:',
    14009: '제휴 수입은 상위 제휴 수입보다 커야합니다',
    14011: '제휴가 이미 존재합니다',
    14012: '낮은 레벨의 제휴만 생성 가능',
    14013: '제휴사에는 다운라인이 있으므로 더 낮은 수준으로 설정할 수 없습니다.',
    14100: '제휴에이 다운 라인 회원이 없습니다',
    14101: '이 제휴는이 작업을 수행할 수있는 권한이 없습니다',
    14110: '동일한 이름의 플랫폼 및 사이트가 이미 존재합니다',
    14111: '삭제하기 전에 상태는 하루 동안 CLOSE되어야합니다',
    14112: '이 사이트 플랫폼의 상태가 하루 동안 CLOSE되어 있으므로 하루 후에 삭제하십시오',
    14120: '동일한 이름의 금융 수준, 통화, 사이트 및 장치가 이미 존재합니다',
    14121: '최소 인출 금액은 0보다 작을 수 없습니다',
    14122: '최대 인출 금액은 0보다 작을 수 없습니다',
    14123: '최대 인출 횟수는 0보다 작을 수 없습니다',
    14124: '일일 최대 인출 금액은 0보다 작을 수 없습니다',
    14125: '최소 인출 금액은 최대 금액보다 작을 수 없습니다',
    14126: '최대 인출 금액은 최소 금액보다 작을 수 없습니다',
    14127: '일일 최대 인출 금액은 최대 인출 금액보다 작을 수 없습니다',
    14130: '플랫폼 계정이 존재하지 않습니다',
    14131: '다른 사이트 플랫폼에서 사용 중인 플랫폼 계정',
    15000: 'VIP 이름이 이미 사이트에 존재합니다',
    15001: 'VIP가 존재하지 않습니다',
    15002: '기존 VIP에서 사용 중이므로 VIP를 삭제할 수 없습니다',
    15003: '주어진 VIP 수준 및 게임 유형에 대한 VIP 할인 규칙이 이미 있습니다',
    15004: 'VIP 할인 규칙이 존재하지 않습니다',
    15005: 'VIP 할인이 이미 배포되었습니다',
    15008: '주어진 로그인 이름 및 게임 유형에 대한 회원 할인 규칙이 이미 있습니다',
    15009: '회원 할인 규칙이 존재하지 않습니다',
    15010: '사이트에서 이미 금융 수준 이름이 있습니다',
    15012: '금융 수준이 존재하지 않습니다',
    15020: '예금 기록이 존재하지 않습니다',
    15021: '동일한 이름의 금융 수준, 지불 유형, 사이트 및 방법이 이미 존재합니다',
    15030: 'JSON 데이터를 처리할 수 없습니다',
    15040: '인출 표시 이름이 이미 존재합니다',
    15041: '인출 표시가 존재하지 않습니다',
    15050: '지불에서 이미 은행이 존재합니다',
    15051: '코드가 이미 주어진 결제에 존재합니다',
    15060: '인출 플랫폼 ID가 존재하지 않습니다',
    15061: '다른 사이트 플랫폼에서 사용 중인 은행',
    15062: '다른 사이트 플랫폼에서 사용 중인 코드',
    15063: '출금 레코드가 존재하지 않습니다',
    15064: '출금 플랫폼이 존재하지 않습니다',
    15065: '출금 카드가 존재하지 않습니다',
    15066: '출금 플랫폼 이름이 이미 존재합니다',
    16000: '3 자리 숫자가 필요합니다',
    16001: '숫자는 3 자리 여야합니다',
    16002: '선택한 날짜에 복권 결과가 발표되었습니다',
    16010: '영어 팀 이름이 이미 존재합니다',
    16011: '팀이 존재하지 않습니다',
    16012: '상을 찾을 수 없습니다',
    17010: '투표 레코드가 존재하지 않습니다',
    17011: '투표 레코드가 이미 취소되었습니다',
    18000: '도메인이 이미 존재합니다',
    18001: '제휴 도메인이 존재하지 않습니다',
    18002: 'Facebook Pixel does not exist',
    18003: 'This affiliate already set facebook pixel',
    19000: '은행 카드가 이미 블랙리스트에 올라가 있습니다',
    19002: '적색 패킷 비 오버랩',
    19003: '새로 고침 기간이 겹침',
    19004: '마지막 숫자가 중복됩니다',
    19101: '광고 제목이 이미 존재합니다.',
    19102: '광고 ID를 찾을 수 없습니다.',
    19103: '사이트에 대한 광고가 이미 켜져 있습니다. 기존 광고를 끄십시오',
    20000: 'SMS 설정이 이미 사이트에 존재합니다',
    20100: '이메일 설정이 이미 사이트에 존재합니다',
    20201: '잘못된 유형',
    20202: '‘正常’ / ‘冻结’를 입력하십시오',
    20203: '생년월일 형식 오류 (yyyy-MM-dd)',
    20204: '위험 수준을 찾을 수 없습니다. 올바른 위험 수준 (예 : 1)을 입력하십시오',
    20205: '금융 수준을 찾을 수 없습니다. 올바른 금융 수준 (예 : 1)을 입력하십시오',
    20206: '‘测试’ / ’普通‘ / ’三方‘ / ’代理‘를 입력하십시오',
    20400: '롤오버 레코드가 존재하지 않습니다',
    21000: '게임이 존재하지 않습니다',
    21100: '이 회원의 티켓이 최대 티켓 한도에 도달했습니다',
    21101: '이 사이트에 대한 멀티 휠을 찾을 수 없습니다',
    22001: '회원 VIP 레벨 최대 보너스는 $',
    21005: '동일한 사이트, OS, 앱 유형 및 APK 유형으로 앱이 이미 존재합니다',
    21006: '선택한 파일 및 OS가 일치하지 않습니다',
    30000: 'PG 업데이트 트랜잭션 상태 업데이트 실패',
    24003: '결과가 이미 처리되었습니다',
    27000: '키 수량이 충분하지 않습니다',
  },
}
