export default {
  common: {
    title: 'TF แดชบอร์ด',
    welcome_system: 'ยินดีต้อนรับสู่ระบบหลังบ้าน',
    logout: 'ออกจากระบบ',
    changePassword: 'เปลี่ยนรหัสผ่าน',
  },
  affiliate: {
    commissionModel: {
      NORMAL: 'ปกติ',
      SIMPLE: 'ทั่วไป',
    },
    level: {
      AFFILIATE: 'พันธมิตร',
      SUPER_AFFILIATE: 'พันธมิตรระดับซุปเปอร์',
      MASTER_AFFILIATE: ' พันธมิตรระดับมาสเตอร์ ',
      CHIEF_AFFILIATE: 'หัวหน้าพันธมิตร',
    },
    timeType: {
      MONTHLY: 'รายเดือน',
      WEEKLY: 'รายสัปดาห์',
    },
    status: {
      APPLY: 'ส่งตรวจสอบ',
      DISABLE: 'ปิดการใช้งาน',
      TEMPORARY: 'ชั่วคราว',
      PERMANENT: 'ถาวร',
      GAME_VIOLATION: 'การละเมิดเกม',
      MEMBER_REQUEST: 'คำขอเป็นสมาชิก',
      OTHERS: 'อื่นๆ',
      TEST: 'ทดสอบ',
      OUTSIDE: 'ด้านนอก',
    },
    belongType: {
      OFFICIAL: 'เว็บอย่างเป็นทางการ',
      PACKAGE: 'แพ็คเกจเว็บ',
    },
  },
  types: {
    NORMAL: 'ปกติ',
    TEMPORARY: 'ชั่วคราว',
    PERMANENT: 'ถาวร',
    gameViolation: 'การละเมิดเกม',
    memberRequest: 'คำขอเป็นสมาชิก',
    others: 'อื่นๆ',
    TEST: 'ทดสอบ',
    AFFILIATE: 'พันธมิตร',
    OUTSIDE: 'ด้านนอก',
  },
  reportName: {
    Aff_Month_Report: 'รายงานพันธมิตรรายเดือน',
    Deposit_Record: 'บันทึกการเติมเงิน',
    Withdraw_Record: 'บันทึกการฝากเงิน',
    Privilege_Record: 'บันทึกโบนัสที่ได้รับ',
    Finance_Report: 'บันทึกการเงิน',
    Summary_Record: 'สรุปบันทึก',
    Summary_Active_Record: 'สรุปบันทึกที่ใช้งานอยู่',
    Summary_Register_Record: 'สรุปบันทึกการลงทะเบียน',
    Summary_Fdp_Record: 'สรุปบันทึกการเติมเงินครั่งแรก',
    Summary_Withdraw_Record: 'สรุปบันทึกการถอนเงิน',
    Summary_Deposit_Record: 'สรุปบันทึกการเติมเงิน',
    Summary_Member_Record: 'สรุปบันทึกสมาชิก',
    Member_Bet_Record: 'บันทึกการเดิมพันของสมาชิก',
    Member_Bet_Money_Change: 'บันทึกการเดิมพันทางการเงิน',
    Daily_Record: 'บันทึกประจำวัน',
    Platform_Game_Record: 'บันทึกเกมแพลตฟอร์ม',
  },
  dateType: {
    depositDate: 'วันที่ฝากเงิน',
    finishDate: 'วันที่เสร็จสิ้น',
    withdrawDate: 'วันที่ถอนเงิน',
    paymentDate: 'วันที่จ่ายเงิน',
    regTime: 'เวลาลงทะเบียน',
    recordTime: 'เวลาบันทึก',
  },
  depositStatus: {
    SUCCESS: 'สำเร็จ',
    SUPPLEMENT_SUCCESS: 'สถานะเพิ่มเติม',
    CLOSED: 'ปิด',
    PENDING: 'กำลังดำเนินการ',
  },
  withdrawStatus: {
    ALL: 'ทั้งหมด',
    SUCCESS: 'สำเร็จ',
    FAIL: 'ล้มเหลว',
    APPLY: 'กำลังส่งเรื่อง',
    STEP_1: 'กำลังตรวจสอบ',
    STEP_2: 'กำลังจ่าย',
    STEP_3: 'กำลังดำเนินการจ่าย',
    AUTOPAY: 'การชำระเงินอัตโนมัติ',
    PENDING: 'ระงับ',
    REJECT: 'ปฏิเสธ',
    SENDING: 'กำลังส่ง',
    WAITING_CALLBACK: 'กำลังรอการติดต่อกลับ',
    PAYING: 'การจ่ายเงิน',
  },
  withdrawConfirmStatus: {
    0: '未确认',
    1: '已到账',
  },
  outboxStatus: {
    0: '未回复',
    1: '已回复',
  },
  editCheckedStatus: {
    0: '未审核',
    1: '已更改',
    2: '审核失败',
  },
  transfer: {
    status: {
      SENDING: 'กำลังส่ง',
      SUCCESS: 'สำเร็จ',
      FAIL: 'ล้มเหลว',
    },
    type: {
      deposit: 'ฝากเงิน',
      withdraw: 'ถอนเงิน',
    },
  },
  moneyChange: {
    type: {
      DEPOSIT: 'ฝากเงิน',
      WITHDRAW: 'ถอนเงิน',
      PROMO: 'โปรโมชั่น',
      TRANSFER: 'โอนเงิน',
      BET: 'เดิมพัน',
      WITHDRAW_FAIL: 'การถอนล้มเหลว',
      ADJUST: 'ปรับ',
      PAYOUT: 'การจ่ายเงิน',
      ROLLBACK: 'ย้อนกลับ',
      PATCH: 'ปะ',
      BET_N_PAYOUT: 'เดิมพันและการจ่ายเงิน',
      AFFILIATE_TRANSFER: 'การโอนพันธมิตร',
    },
    subType: {
      DEPOSIT: 'กระเป๋าเงินไปเกม',
      WITHDRAW: 'เกมไปกระเป๋าเงิน',
      TRASNFER_IN: 'โอนเงินเข้า',
      TRANSFER_OUT: 'โอนเงินออก',
      AFFILIATE_SETTLEMENT: 'การชำระบัญชีพันธมิตร',
    },
  },
  status: {
    monthSummary: {
      UNCLEAR: 'ยังไม่ลบ',
      CLEARING: 'กำลังลบ',
      CLEARED: 'ลบแล้ว',
    },
    settlement: {
      CHECKING: 'กำลังตรวจสอบ',
      PAY: 'จ่าย',
      CLEARED: 'ลบแล้ว',
    },
    member: {
      NORMAL: 'ปกติ',
      FROZEN: 'ระงับ',
    },
  },
  betStatus: {
    BET: 'เดิมพัน',
    UNSETTLED: 'ยังไม่ได้ชำระ',
    SETTLED: 'ชำระแล้ว',
    CANCEL: 'ยกเลิก',
    GIVEREWARD: 'รางวัล',
  },
  dashboard: {
    memberCount: 'จำนวนสมาชิก',
    totalFirstDeposit: 'ยอดรวมการฝากครั้งแรก',
    totalDeposit: 'ยอดฝากทั้งหมด',
    totalTransaction: 'จำนวนรายการ',
    totalTransfer: 'ยอดโอนทั้งหมด',
    totalWithdraw: 'ยอดถอนทั้งหมด',
    totalWinLoss: 'ยอดรวม ชนะ/แพ้',
    totalValidBet: 'เดิมพันที่ถูกต้องทั้งหมด',
    totalBonus: 'โบนัสรวม',
    totalProfit: 'กำไรรวม',
    returnAmount: 'จำนวนเงินคืน',
    totalActiveMember: 'สมาชิกที่ใช้งานอยู่ทั้งหมด',
    newRegisterMember: 'จำนวนสมาชิกใหม่ทั้งหมด',
    totalVisit: 'จำนวนผู้เข้าเว็บทั้งหมด',
    depositChartName: 'จำนวนเงินฝากทั้งหมด',
    depositAmount: 'จำนวนเงินฝาก',
    depositMemberCount: 'จำนวนเงินฝากของสมาชิก',
    depositTransactionCount: 'จำนวนธุรกรรมการฝากเงิน',
    depositTransactionNumChartName:
      'จำนวนเงินฝากของสมาชิก / จำนวนธุรกรรมการฝากเงิน',
    financialLevelRatio: 'อัตราส่วนระดับทางการเงิน',
    vipLevelRatio: 'อัตราส่วนระดับวีไอพี',
    paymentMethod: 'วิธีการชำระเงิน',
    ratio: 'การปันส่วน',
    fundAmount: 'เงิน',
    paymentMethodSummary: 'วิธีการชำระเงิน สรุปการฝากเงิน',
    total: 'รวมทั้งหมด',
    bet: 'การเดิมพัน',
    payout: 'การจ่ายเงิน',
    merchantDepositSummary: 'สรุปการฝากเงินของร้านค้า',
    rfdRegisterCount: 'จำนวนการลงทะเบียน',
    rfdFDepositCount: 'สถิติการฝากครั้งแรก',
    rfdReport: 'การลงทะเบียนแนวโน้มการฝากเงินครั้งแรก',
  },
  reportGame: {
    gamePlatform: 'แพลตฟอร์มเกม',
    gameMemberCount: 'จำนวนสมาชิกเดิมพันรายวัน',
    gameBetCount: 'จำนวนธุรกรรมการเดิมพันรายวัน',
    gameBetAmount: 'จำนวนเงินเดิมพันรายวัน',
    gameWinLoss: 'จำนวนการชนะ/แพ้รายวัน',
    gameMemberCountTotal: 'จำนวนสมาชิกเดิมพันทั้งหมด',
    gameBetCountTotal: 'จำนวนธุรกรรมการเดิมพันทั้งหมด',
    gameBetAmountTotal: 'จำนวนเงินเดิมพันทั้งหมด',
    gameWinLossTotal: 'จำนวนเงินชนะ/แพ้ทั้งหมด',
  },
  gameType: {
    SLOT: 'สล็อต',
    LIVE: 'สด',
    FISH: 'ตกปลา',
    SPORT: 'กีฬา',
    ESPORT: 'อีสปอร์ต',
    POKER: 'โป๊กเกอร์',
    LOTTERY: 'หวย',
    CASUAL: 'ไม่เป็นทางการ',
    COCKFIGHT: 'ไม่เป็นทางการ',
  },
  distributeStatus: {
    PENDING: 'กำลังดำเนินการ',
    DISTRIBUTED: 'แจกจ่าย',
  },
  referFriendEvent: {
    INFO: 'ข้อมูลที่สมบูรณ์',
    FIRST: 'ฝากครั้งแรก',
    SECOND: 'ฝากครั้งที่สอง',
  },
  rollover: {
    recordStatus: {
      ONGOING: 'กำลังดำเนินการอยู่',
      COMPLETED: 'สมบูรณ์แล้ว',
      VOID: 'เป็นโมฆะ',
      CANCEL: 'ยกเลิก',
    },
  },
  sitePlatform: {
    followType: 'พิมพ์',
    follow: 'ก่อนที่จะติดตาม',
    new: 'อัพเดตประเภทเกม',
  },
  promoType: {
    WELCOME: 'ยินดีต้อนรับ',
    SPORT: 'กีฬา',
    ESPORT: 'อีสปอร์ต',
    FISH: 'ตกปลา',
    LIVECASINO: 'สด',
    SLOTGAME: 'สล็อต',
    POKER: 'โป๊กเกอร์',
    DAILY: 'รายวัน',
    FTD: 'FTD',
    LOTTERY: 'ลอตเตอรี่',
    OTHER: 'อื่นๆ',
    LIMITED: 'LIMITED',
    SLOTWELCOME: 'Slot - Welcome',
    SLOTDAILY: 'Slot - Daily Weekly',
    SLOTOTHER: 'Slot - Rebate & Other',
  },
  scheduleType: {
    NORMAL: 'โดยปกติ',
    BET_RECORDS: 'ข้อมูลผลตอบแทนการเดิมพัน',
  },
  smsSend: {
    template1: 'Welcome New Member',
    template2: 'Deposit Promotion',
    template3: 'Welcome Back',
  },
  siteType: {
    main: 'MAIN',
    slot: 'SLOT',
  },
  fields: {
    account: 'บัญชี',
    accountHolder: 'ผู้ถือบัญชี',
    accountInfo: 'ข้อมูลเกี่ยวกับบัญชี',
    accountNumber: 'หมายเลขบัญชี',
    accountStatus: 'สถานะบัญชี',
    accurate: 'Accurate Search',
    action: 'การกระทำ',
    actionType: 'ประเภทการดำเนินงาน',
    activeMember: 'สมาชิกที่ใช้งานอยู่',
    activeMemberCount: 'จำนวนสมาชิกที่ใช้งานอยู่',
    activePlayer: 'ผู้เล่นที่ใช้งานอยู่',
    activeUser: 'ผู้ใช้ที่ใช้งานอยู่',
    add: 'เพิ่ม',
    addAds: 'เพิ่มโฆษณาป๊อปเอาท์',
    addAffiliate: 'เพิ่มพันธมิตร',
    addAffiliateCommission: 'เพิ่มค่าคอมมิชชั่นพันธมิตร',
    addAffiliateDomain: 'เพิ่มโดเมนพันธมิตร',
    addAnnouncement: 'เพิ่มประกาศ',
    addAnnouncementType: 'เพิ่มประเภทการประกาศ',
    addBank: 'เพิ่มธนาคาร',
    addBankCard: 'เพิ่มบัตรธนาคาร',
    addBankToPayment: 'เพิ่มธนาคารเพื่อถอน',
    addBankToWithdrawPlatform: 'เพิ่มธนาคารเพื่อถอนแพลตฟอร์ม',
    addBanner: 'เพิ่มแบนเนอร์',
    addRewardGroup: 'เพิ่มกลุ่มรางวัล',
    addCurrency: 'เพิ่มสกุลเงิน',
    addCurrencyRate: 'เพิ่มอัตราแลกเปลี่ยน',
    addDepositSetting: 'เพิ่มการตั้งค่าการฝากเงิน',
    addDomain: 'เพิ่มโดเมน',
    addEmail: 'เพิ่มอีเมล',
    addFinancialLevel: 'เพิ่มระดับทางการเงิน',
    addGame: 'เพิ่มเกม',
    addIpLabel: 'เพิ่มป้ายกำกับ IP',
    additionalTicketDetail: 'รายละเอียดตั๋วเพิ่มเติม',
    addJob: 'เพิ่มงาน',
    addLotteryResult: 'เพิ่มผลลอตเตอรี่',
    addMember: 'เพิ่มสมาชิก',
    addMemberAmountAdjust: 'เพิ่ม ปรับจำนวนสมาชิก',
    addMemberPrivilegeBlacklist: 'เพิ่มบัญชีดำสิทธิพิเศษของสมาชิก',
    addMenu: 'เพิ่มเมนู',
    addNodes: 'เพิ่มโหนด',
    addPayType: 'เพิ่มประเภทการชำระเงิน',
    addPlatform: 'เพิ่มแพลตฟอร์ม',
    addPlatformAccount: 'เพิ่มบัญชีแพลตฟอร์ม',
    addPrivilegeInfo: 'เพิ่มข้อมูลสิทธิพิเศษ',
    addRecipient: 'เพิ่มผู้รับ',
    addRemark: 'เพิ่มหมายเหตุ',
    addRiskLevel: 'เพิ่มระดับความเสี่ยง',
    addRole: 'เพิ่มกฎ',
    addSite: 'เพิ่มไซต์',
    addSitePlatform: 'เพิ่มแพลตฟอร์มไซต์',
    addSystemMessage: 'เพิ่มข้อความระบบ',
    addSyncDefault: 'การแข่งขันซิงค์ภายใน 3 วัน',
    addTelephone: 'เพิ่มหมายเลขโทรศัพท์',
    addTicket: 'เพิ่มตั๋ว',
    addUser: 'เพิ่มผู้ใช้',
    addVip: 'เพิ่มวีไอพี',
    addVipRebateRule: 'เพิ่มกฎการคืนเงินวีไอพี',
    addWithdrawPlatform: 'เพิ่มแพลตฟอร์มถอนเงิน',
    addWithdrawSetting: 'เพิ่มการตั้งค่าการถอน',
    addWithdrawalBankBlacklist: 'เพิ่มบัญชีดำธนาคารถอนเงิน',
    adjustAmount: 'ปรับจำนวนเงิน',
    adjustBy: 'ปรับโดย',
    adjustReason: 'เหตุผลของการปรับ',
    adjustTime: 'เวลาที่ปรับ',
    adjustType: 'ประเภทการปรับ',
    adjustment: 'การปรับ',
    adjustmentType: 'ประเภทการปรับ',
    affiliate: 'พันธมิตร',
    affiliateBankCard: 'ธนาคารพันธมิตร',
    affiliateBankCardRecord: 'บันทึกบัตรธนาคารพันธมิตร',
    affiliateCode: 'รหัสพันธมิตร',
    channelName: 'ชื่อช่อง',
    affiliateDomainList: 'รายการโดเมนพันธมิตร',
    affiliateFBRequestList: 'Affiliate Facebook Pixel List',
    affiliateFBRequestUrl: 'Affiliate URL',
    affiliateInfo: 'ข้อมูลพันธมิตร',
    affiliateLevel: 'ระดับพันธมิตร',
    affiliateLink: 'ลิงค์พันธมิตร',
    affiliateList: 'รายชื่อพันธมิตร',
    affiliateName: 'ชื่อของพันธมิตร',
    affiliateStatus: 'สถานะพันธมิตร',
    affiliateTransferAmount: 'จำนวนเงินโอน',
    affiliateTransferRecord: 'บันทึกการโอนพันธมิตร',
    advancedSearch: 'ค้นหาขั้นสูง',
    afterAmount: 'หลังจำนวนเงิน',
    afterBalance: 'หลังจากยอดคงเหลือ',
    afterEdit: 'หลังแก้ไข',
    amount: 'จำนวน',
    adjust: 'ปรับจำนวนเงิน',
    alias: 'นามแฝง',
    allvip: 'วีไอพีทั้งหมด',
    allfinanciallevel: 'สถานะทางการเงินทั้งหมด',
    allprofit: 'กำไรทั้งหมด',
    allreviewstatus: 'สถานะการตรวจสอบทั้งหมด',
    allmembers: 'สมาชิกทุกท่าน',
    announcementType: 'ประเภทประกาศ',
    applying: 'ส่งดำเนินการ',
    approve: 'อนุมัติ',
    approveBy: 'อนุญาตโดย',
    assigned: 'ที่ได้รับมอบหมาย',
    assignment: 'งานที่มอบหมาย',
    auditTime: 'เวลาตรวจสอบ',
    automaticPayment: 'การชำระเงินอัตโนมัติ',
    autopay: 'จ่ายอัตโนมัติ',
    automaticPay: 'อัตโนมัติ_ชำระเงิน',
    back: 'กลับ',
    balance: 'ยอดคงเหลือ',
    balanceUrl: 'Balance URL',
    bank: 'ธนาคาร',
    bankAccount: 'บัญชีธนาคาร',
    bankCard: 'บัตรเครดิตธนาคาร',
    bankCardNumber: 'หมายเลขบัตร',
    bankCode: 'รหัสธนาคาร',
    bankName: 'ชื่อธนาคาร',
    bankNumber: 'หมายเลขบัญชี',
    bankType: 'ประเภทธนาคาร',
    beanName: 'Bean Name',
    beforeAmount: 'จำนวนเงินก่อนหน้า',
    beforeBalance: 'ยอดเงินก่อนหน้า',
    beforeEdit: 'ก่อนแก้ไข',
    bet: 'เดิมพัน',
    betAmount: 'จำนวนเงินเดิมพัน',
    betId: 'รหัสเดิมพัน',
    belongType: 'ประเภทหน้าเว็บ',
    betMoneyChange: 'สมาชิกเดิมพันแลกเปลี่ยนเงิน',
    betMoreThan: 'เดิมพันมากกว่า',
    betRecords: 'บันทึกการเดิมพัน',
    betStatus: 'สถานะการเดิมพัน',
    betTime: 'เวลาเดิมพัน',
    betTotal: 'ยอดเดิมพันทั้งหมด',
    binded: 'ผูก',
    birthday: 'วันเกิด',
    bindTypeAll: 'ทั้งหมด',
    bindTypeNew: 'ใหม่',
    bindTypeRemove: 'นำออก',
    bonus: 'โบนัส',
    bonusAmount: 'จำนวนโบนัส',
    bonusReceived: 'โบนัสที่ได้รับ',
    bonusType: 'ประเภทโบนัส',
    byprofitasc: 'เรียงตามกำไร ASC',
    byprofitdesc: 'เรียงตามกำไร DESC',
    bydateasc: 'เรียงตามวันที่ ASC',
    bydatedesc: 'เรียงตามวันที่ DESC',
    callbackUrl: 'URL เรียกคืน',
    cancel: 'ยกเลิก',
    cancelDeposit: 'ยกเลิกการฝากเงิน',
    cancelRemark: 'เหตุผลที่ยกเลิก',
    cancelRolloverRecord: 'บันทึกการยกเลิกโรโอเวอร์',
    cancelAllRolloverRecord: 'ยกเลิกบันทึกแบบโรลโอเวอร์ทั้งหมด',
    cancelUpdate: 'ยกเลิกการอัพเดต',
    cancelType: 'ยกเลิกประเภท',
    cardAccount: 'บัญชีบัตร',
    cardAddress: 'ที่อยู่บัตร',
    cardNumber: 'หมายเลขบัตร',
    cardTime: 'เวลาดำเนินการ',
    cardTypeAll: 'ทั้งหมด',
    cardTypeBank: 'ธนาคาร',
    cardTypeWallet: 'อี-วอเล็ท',
    cardTypeCrypto: 'คลิปโต',
    category: 'หมวดหมู่',
    cause: 'สาเหตุ',
    changeAffiliate: 'เปลี่ยนพันธมิตร',
    check: 'ตรวจสอบ',
    checkall: 'Check All',
    checkBy: 'ตรวจสอบโดย',
    checkDate: 'ตรวจสอบวันที่',
    checkExclusiveUrl: 'ตรวจสอบ URL พิเศษ',
    checkFail: 'การตรวจสอบล้มเหลว',
    checkTime: 'เวลาตรวจสอบ',
    claimTime: 'เวลาเคลม',
    className: 'Class Name',
    clearingSum: 'หักล้างผลรวม',
    clientType: 'ประเภทลูกค้า',
    close: 'ปิด',
    code: 'รหัส',
    color: 'สี',
    commission: 'ค่าคอมมิชชั่น',
    commissionModel: 'แบบแผนค่าคอมมิชชั่น',
    commissionPercent: 'ค่าคอมมิชชั่นเป็นเปอร์เซ็น',
    commissionRate: 'อัตราค่าคอมมิชชั่น',
    commissionReport: 'รายงานค่าคอมมิชชั่น',
    commitTime: 'ภายในเวลา',
    companyProfit: 'กำไรของบริษัท',
    componentName: 'ชื่อส่วนประกอบ',
    componentPath: 'เส้นทางส่วนประกอบ',
    composeMessage: 'เขียนข้อความ',
    consumingTime: 'เวลาที่หมด',
    confirm: 'ยืนยัน',
    confirmAndImport: 'ยืนยันและนำเข้า',
    confirmAndSend: 'Confirm and Send',
    confirmNewPassword: 'ยืนยันรหัสผ่านใหม่',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    confirmPay: 'ยืนยันการชำระเงิน',
    confirmStatus: 'ยืนยันสถานะ',
    confirmBy: 'ยืนยันโดย',
    content: 'เนื้อหา',
    contentList: 'รายการหัวข้อย่อย',
    convertRate: 'อัตราการแปลง',
    copy: 'สำเนา',
    copyPayment: 'กำลังคัดลอกการชำระเงิน',
    copyWithdrawPlatform: 'กำลังคัดลอกแพลตฟอร์มถอนเงิน',
    copyTo: 'คัดลอกไปที่',
    copyToOtherLevel: 'คัดลอกไปยังระดับอื่น',
    country: 'ประเทศ',
    countryImage: 'ภาพประเทศ',
    createBy: 'สร้างโดย',
    createTime: 'สร้างเวลา',
    createVote: 'สร้างการโหวต',
    cronExpression: 'การแสดงออกของครอน',
    currency: 'สกุลเงิน',
    currencyCode: 'รหัสสกุลเงิน',
    currencyName: 'ชื่อสกุลเงิน',
    currencyRate: 'อัตราสกุลเงิน',
    currencyWallet: 'กระเป๋าเงินสกุลเงิน',
    currentPassword: 'รหัสผ่านปัจจุบัน',
    customerSupportAddress: 'ที่อยู่ฝ่ายสนับสนุนลูกค้า',
    dailyDeposit: 'ฝากรายวัน',
    dailyDepositAmount: 'จำนวนเงินฝากรายวัน',
    dailyWithdraw: 'ถอนรายวัน',
    dailyWithdrawAmount: 'จำนวนถอนรายวัน',
    dailyFailAmount: 'จำนวนความล้มเหลวรายวัน',
    dailyFailDeposit: 'เงินฝากรายวันล้มเหลว',
    dailyMemberFailDeposit: 'การฝากเงินสมาชิกรายวันล้มเหลว',
    dailyFailWithdraw: 'การถอนเงินรายวันล้มเหลว',
    dailyMemberFailWithdraw: 'การถอนเงินสมาชิกรายวันล้มเหลว',
    dailySuccessAmount: 'จำนวนความสำเร็จรายวัน',
    dailySuccessDeposit: 'การฝากเงินรายวันสำเร็จ',
    dailyMemberSuccessDeposit: 'การฝากเงินสมาชิกรายวันสำเร็จ',
    dailySuccessWithdraw: 'การถอนเงินรายวันสำเร็จ',
    dailyMemberSuccessWithdraw: 'การถอนเงินสมาชิกรายวันสำเร็จ',
    dailySuccessPercentage: 'เปอร์เซ็นต์ความสำเร็จรายวัน',
    dailyTotalAmount: 'จำนวนเงินรวมรายวัน',
    dailyTotalRedeem: 'แลกรวมรายวัน',
    dayWithdrawCount: 'จำนวนการถอนรายวัน',
    day2: 'วันที่ 2 ปลดล็อค',
    day3: 'วันที่ 3 ปลดล็อค',
    day4: 'วันที่ 4 ปลดล็อค',
    day5: 'วันที่ 5 ปลดล็อค',
    day6: 'วันที่ 6 ปลดล็อค',
    day7: 'วันที่ 7 ปลดล็อค',
    date: 'วันที่',
    data: 'ข้อมูล',
    dataBefore: 'ข้อมูลก่อนหน้า',
    dataAfter: 'หลังจากข้อมูล',
    dateReceived: 'วันที่ได้รับ',
    dateType: 'ประเภทวันที่',
    deduct: 'ลบออก',
    deductMemberAmountAdjust: 'หักยอดเงินของสมาชิก',
    defaultFinancialLevel: 'ระดับทางการเงินเริ่มต้น',
    defaultAgentFinancialLevel: 'ระดับการเงินของพันธมิตรเริ่มต้น',
    defaultRiskLevel: 'ระดับความเสี่ยงเริ่มต้น',
    defaultAgentRiskLevel: 'ระดับความเสี่ยงเริ่มต้นของพันธมิตร',
    defaultVip: 'วีไอพีเริ่มต้น',
    defaultAgentVip: 'VIP พันธมิตรเริ่มต้น',
    delete: 'ลบ',
    deleteRole: 'ลบกฎ',
    deposit: 'เงินฝาก',
    depositAmount: 'จำนวนเงินฝาก',
    depositAverage: 'เงินฝากเฉลี่ย',
    depositBettingAmount: 'จำนวนเงินฝาก/เดิมพัน',
    depositCount: 'จำนวนเงินฝาก',
    depositDate: 'วันที่ฝาก',
    deposited: 'ฝากแล้ว',
    depositExchangeRate: 'อัตราแลกเปลี่ยนเงินฝาก',
    depositMemberCount: 'จำนวนฝากเงินของสมาชิก',
    depositSerialNo: 'หมายเลขลำดับการฝากเงิน',
    depositStatus: 'สถานะการฝากเงิน',
    depositWithdrawalProfit: 'ฝากและถอนกำไร',
    describe: 'อธิบาย',
    description: 'คำอธิบาย',
    desktopImage: 'รูปภาพเดสก์ท็อป',
    desktopBackgroundImage: 'ภาพพื้นหลังเดสก์ท็อป',
    desktopBanner: 'แบนเนอร์เดสก์ท็อป',
    details: 'รายละเอียด',
    device: 'อุปกรณ์',
    dif: 'ส่วนต่างเงินฝาก',
    disable: 'ปิดการใช้งาน',
    disableAffiliate: 'ปิดการใช้งานพันธมิตร',
    disableType: 'ปิดการใช้งานประเภท',
    distributeBy: 'จัดจำหน่ายโดย',
    distributePrivilege: 'แจกสิทธิพิเศษ',
    distributeRebate: 'แจกจ่ายส่วนลด',
    distributeRefer: 'แจกจ่ายโบนัส',
    distributeTime: 'เวลาจ่าย',
    domain: 'โดเมน',
    domainCount: 'จำนวนโดเมน',
    domainList: 'รายการโดเมน',
    done: 'เสร็จแล้ว',
    doneBy: 'ผู้ใช้ปฏิบัติการ',
    downgradeToApplying: 'ปรับลดเพื่อส่งตรวจสอบ',
    downgradeToWaitPay: 'รอการชำระเงิน',
    downlineAffiliate: 'ดาวน์ไลน์พันธมิตร',
    downlineCommission: 'ค่าคอมมิชชันดาวน์ไลน์',
    downlineCommissionRate: 'อัตราค่าคอมมิชชั่นดาวน์ไลน์',
    downlineMember: 'สมาชิกดาวน์ไลน์',
    downlineProfit: 'กำไรดาวน์ไลน์',
    downloadTemplate: 'ดาวน์โหลดเทมเพลต',
    edit: 'แก้ไข',
    editAds: 'แก้ไขโฆษณาป๊อปอัพ',
    editAffiliateCommission: 'แก้ไขค่าคอมมิชชั่นพันธมิตร',
    editAffiliateDomain: 'แก้ไขโดเมนพันธมิตร',
    editAmount: 'แก้ไขจำนวนเงิน',
    editAnnouncement: 'แก้ไขประกาศ',
    editAnnouncementType: 'แก้ไขประเภทการประกาศ',
    editBank: 'แก้ไขธนาคาร',
    editBankCard: 'แก้ไขเลขที่บัญชีธนาคาร',
    editBanner: 'แก้ไขแบนเนอร์',
    editBelongType: 'แก้ไขประเภทหน้า',
    editCurrency: 'แก้ไขสกุลเงิน',
    editCurrencyRate: 'แก้ไขอัตราแลกเปลี่ยน',
    editDepositSetting: 'แก้ไขการตั้งค่าการฝากเงิน',
    editDetails: 'แก้ไขรายละเอียด',
    editEmail: 'แก้ไขอีเมล์',
    editFinancialLevel: 'แก้ไขระดับทางการเงิน',
    editGame: 'แก้ไขเกม',
    editIpLabel: 'แก้ไขป้ายกำกับ IP',
    editJob: 'แก้ไขงาน',
    editMenu: 'แก้ไขเมนู',
    editPayType: 'แก้ไขประเภทการชำระเงิน',
    editPlatform: 'แก้ไขแพลตฟอร์ม',
    editPlatformAccount: 'แก้ไขบัญชีแพลตฟอร์ม',
    editPrivilegeInfo: 'การทำบัญชีแพลตฟอร์ม',
    editRemark: 'แก้ไขหมายเหตุ',
    editRewardGroup: 'แก้ไขกลุ่มรางวัล',
    editRiskLevel: 'แก้ไขระดับความเสี่ยง',
    editRole: 'แก้ไขบทบาท',
    editSite: 'แก้ไขไซต์',
    editSitePlatform: 'แก้ไขแพลตฟอร์มไซต์',
    editTelephone: 'แก้ไขหมายเลขโทรศัพท์',
    editTimeType: 'แก้ไขประเภทเวลา',
    editType: 'แก้ไขประเภท',
    editUser: 'แก้ไขผู้ใช้',
    editVip: 'แก้ไขวีไอพี',
    editVipRebateRule: 'แก้ไขกฎการคืนเงินวีไอพี',
    editWithdrawPlatform: 'แก้ไขแพลตฟอร์มการถอน',
    editWithdrawSetting: 'แก้ไขการตั้งค่าการถอน',
    email: 'อีเมล',
    endDate: 'วันที่สิ้นสุด',
    endTime: 'เวลาสิ้นสุด',
    event: 'เหตุการณ์',
    exchangeRate: 'อัตราแลกเปลี่ยน',
    exclusiveUrlWeb: 'URL พิเศษ (เว็บ)',
    exclusiveUrlWap: 'URL พิเศษ (แอป)',
    exclusiveUrlApp: 'URL พิเศษ',
    exclusiveUrlCashier: 'URL พิเศษ',
    exclusiveUrl: 'URL พิเศษ',
    exportToExcel: 'ส่งออกไปยัง Excel',
    externalUrl: 'URL ภายนอก',
    extraParam: 'พารามิเตอร์พิเศษ',
    fail: 'ล้มเหลว',
    failReason: 'เหตุผลที่ล้มเหลว',
    fbid: 'Facebook Pixel ID',
    fbToken: 'Facebook Pixel Token',
    fdConvertRate: 'อัตราการแปลง FTD',
    features: 'คุณสมบัติ',
    feedbackType: 'ประเภทคำติชม',
    feedbackTypeAll: 'ทั้งหมด',
    feedbackTypeDeposit: 'เงินฝาก',
    feedbackTypeWithdraw: 'การถอน',
    feedbackStatus: 'สถานะข้อเสนอแนะ',
    feedbackStatusAll: 'ทั้งหมด',
    feedbackStatusPending: 'รอดำเนินการ',
    feedbackStatusSuccess: 'ความสำเร็จ',
    feedbackTime: 'เวลาตอบรับ',
    feeRate: 'อัตราค่าธรรมเนียม',
    field: 'ชื่อข้อมูล',
    finalSum: 'ผลรวมสุดท้าย',
    financialLevel: 'ระดับการเงิน',
    financeType: 'พิมพ์',
    financeRemark: 'หมายเหตุทางการเงิน',
    financeStatus: 'สถานะ',
    financePhotos: 'ภาพถ่าย',
    finishDate: 'วันที่สิ้นสุด',
    firstDeposit: 'ฝากครั้งแรก',
    firstTicket: 'ตั๋วใบแรก (เดิมพัน/ฝากเงิน)',
    firstDepositMemberCount: 'จำนวนเงินฝากครั้งแรก',
    firstDepositMember: 'สมาชิกที่ฝากเงินครั้งแรก',
    freeze: 'ระงับ',
    freezeMember: 'สมาชิกที่ีถูกระงับ',
    freezeType: 'ประเภทการระงับ',
    frequency: 'ความถี่',
    fromWallet: 'Adjust from main wallet',
    fromCommission: 'Adjust from commission',
    ftd: 'FTD',
    ftdAverage: 'ค่าเฉลี่ยการเติมเงินครั้งแรก',
    ftdAmount: 'จำนวนเงินของการเติมเงินครั้งแรก',
    ftdCount: 'จำนวนเติมเงินครั้งแรก',
    ftdTime: 'FTD Time',
    ftdTxn: 'FTD Txn',
    fundingInfo: 'ข้อมูลเงินทุน',
    gameAccountName: 'ชื่อบัญชีเกม',
    gameCode: 'รหัสเกม',
    domainEasy: 'โดเมน ง่าย',
    videoShiPin: 'Video',
    gameConsolidateReport: 'รายงานเกม',
    gameName: 'ชื่อเกม',
    gameType: 'ประเภทเกม',
    generateSummary: 'สร้างบทสรุป',
    hasPromo: 'มีโปรโมชั่น',
    hidden: 'ที่ซ่อนอยู่',
    icon: 'ไอคอน',
    id: 'ID',
    identifyCode: 'ระบุรหัส',
    import: 'นำเข้า',
    inbox: 'กล่องจดหมาย',
    innerSetting: 'การตั้งค่าล้อด้านใน',
    ip: 'Access IP',
    isDeposit: 'แสดงเงินฝาก',
    itsYou: "it's you",
    job: 'งาน',
    jobLog: 'รายการบันทึก',
    jobName: 'ชื่องาน',
    jobType: 'ประเภทงาน',
    label: 'ฉลาก',
    labelCancel: 'ยกเลิก',
    lastDepositTime: 'Last Deposit Time',
    lastLogin: 'เข้าสู่ระบบครั้งล่าสุด',
    lastLoginAddress: 'ที่อยู่เข้าสู่ระบบล่าสุด',
    lastLoginIp: 'IP เข้าสู่ระบบล่าสุด',
    lastLoginTime: 'เวลาเข้าสู่ระบบล่าสุด',
    lastMonth: 'เดือนที่แล้ว',
    last3Months: '3 เดือนที่ผ่านมา',
    last6Months: '6 เดือนที่ผ่านมา',
    lastWeek: 'อาทิตย์ที่แล้ว',
    level: 'ระดับ',
    levelUpCredit: 'เพิ่มระดับเครดิต',
    localCurrencyAmount: 'จำนวนสกุลเงินท้องถิ่น',
    lockStatus: 'สถานะล็อค',
    login: 'เข้าสู่ระบบ',
    loginAddress: 'ที่อยู่เข้าสู่ระบบ',
    loginFailedReason: 'สาเหตุการเข้าสู่ระบบล้มเหลว',
    loginInfo: 'ข้อมูลการเข้าสู่ระบบ',
    loginIp: 'เข้าสู่ระบบไอพี',
    loginName: 'ชื่อเข้าสู่ระบบ',
    loginNameSeparateComma: 'ชื่อเข้าสู่ระบบ (คั่นด้วยเครื่องหมายจุลภาค)',
    loginStatus: 'สถานะการเข้าสู่ระบบ',
    loginTime: 'เวลาเข้าสู่ระบบ',
    loginUrl: 'URL เข้าสู่ระบบ',
    loginWay: 'วิธีเข้าสู่ระบบ',
    logoutPlayer: 'ผู้เล่นออกจากระบบ',
    lotteryRecordList: 'รายการบันทึกลอตเตอรี่',
    lotteryResultList: 'รายการผลลอตเตอรี่',
    lowBalance: 'ยอดคงเหลือขั้นต่ำ',
    mallCode: 'Mall Code',
    mallExtraKey: 'Mall Extra Key',
    mallKey: 'Mall Key',
    mallName: 'Mall Name',
    manualPay: 'การชำระเงินด้วยตนเอง',
    massImport: 'Mass Import',
    maxBonus: 'โบนัสสูงสุด',
    maxDailyWithdraw: 'จำนวนเงินถอนสูงสุดต่อวัน',
    maxDailyWithdrawTimes: 'เวลาของการถอนสูงสุดรายวัน',
    maxDeposit: 'ฝากสูงสุด',
    maxTicket: 'ตั๋วสูงสุด',
    maxWithdrawAmount: 'จำนวนเงินถอนสูงสุด',
    member: 'สมาชิก',
    memberBankCard: 'สมาชิกธนาคาร',
    memberBankCardRecord: 'บัญชีธนาคารของสมาชิก',
    memberBetRecord: 'บันทึกการเดิมพันของสมาชิก',
    memberBetRecordByPlatform: 'เดิมพันบนแพลตฟอร์มสมาชิก',
    memberCommission: 'ค่าตอบแทนสมาชิก',
    memberConsolidateReport: 'รายงานการรวมสมาชิก',
    memberDetailDeposit: 'จำนวนเงินฝากทั้งหมด',
    memberDetailDepositCount: 'จำนวนเงินฝากทั้งหมด',
    memberDetailPrivilege: 'จำนวนสิทธิพิเศษทั้งหมด',
    memberDetailWithdraw: 'จำนวนเงินที่ถอนทั้งหมด',
    memberId: 'รหัสสมาชิก',
    memberInfo: 'ข้อมูลสมาชิก',
    memberList: 'รายชื่อสมาชิก',
    memberName: 'ชื่อสมาชิก',
    memberPlatformAction: 'การดำเนินการ (ลงทะเบียนด้วยตัวเอง/ลบ)',
    memberPlatformUpdate: 'ซิงค์รหัสผ่าน',
    memberPrivilegeBlacklist: 'บัญชีดำสิทธิพิเศษของสมาชิก',
    memberProfitDownlineProfitUnsettleCommission:
      'กำไรของสมาชิก / กำไรดาวน์ไลน์ / ค่าคอมมิชชั่นที่ไม่แน่นอน',
    memberReport: 'รายงานสมาชิก',
    memberType: 'ประเภทสมาชิก',
    memberWithdrawLog: 'บันทึกการถอนสมาชิก',
    menuGame: 'เมนูลำดับเกม',
    menuIcon: 'ไอคอนเมนู',
    menuTitle: 'ชื่อเมนู',
    menuType: 'ประเภทเมนู',
    menuOnlineUser: 'จำนวนผู้ออนไลน์',
    message: 'ข้อความ',
    min: 'กำไรน้อยสุด',
    max: 'กำไรสูงสุด',
    minno: 'กรุณาใส่ราคาขั้นต่ำ',
    maxno: 'กรุณาใส่ราคาสูงสุด',
    memberRemark: 'หมายเหตุสมาชิก',
    minActiveMemberCount: 'จำนวนสมาชิกที่ใช้งานขั้นต่ำ',
    minBalance: 'ยอดคงเหลือขั้นต่ำ',
    minDeposit: 'ฝากขั้นต่ำ',
    minTotalCommission: 'ค่าคอมมิชชั่นรวมขั้นต่ำ',
    minWithdrawAmount: 'จำนวนการถอนเงินขั้นต่ำ',
    mobile: 'มือถือ',
    mobileImage: 'รูปภาพบนมือถือ',
    mobileBackgroundImage: 'ภาพพื้นหลังโทรศัพท์มือถือ',
    mobileBanner: 'แบนเนอร์บนมือถือ',
    mobileMessage: 'ข้อความมือถือ',
    mobileWay: 'มือถือ',
    moneyChange: 'การแลกเงิน',
    month: 'เดือน',
    mqLogProcessWithTitle: 'ชื่อ (SummaryDoris)',
    mqLogProcessTopic: 'หัวข้อ',
    mqLogProcessTimestamp: 'การประทับเวลาปัจจุบัน',
    mqLogTimestampWithTimezone: 'การประทับเวลาของไซต์',
    name: 'ชื่อ',
    needRegister: 'ต้องการลงทะเบียน',
    netProfit: 'กำไรสุทธิ',
    netPhone: 'การตั้งค่าการโทร',
    newMember: 'สมาชิกใหม่',
    newMemberCount: 'จำนวนสมาชิกใหม่',
    newPassword: 'รหัสผ่านใหม่',
    nextActivationTime: 'เวลาเปิดใช้งานครั้งถัดไป',
    nextGetBetEndTime: 'ถัดไป รับเวลาสิ้นสุดเบต้า',
    nextGetBetIndex: 'รับดัชนีเดิมพันถัดไป',
    nextGetBetPage: 'หน้ารับเดิมพันถัดไป',
    nextGetBetStartTime: 'รับเวลาเริ่มเดิมพันถัดไป',
    nextLevel: 'ระดับถัดไป',
    nextLevelPoint: 'จุดระดับถัดไป',
    nickName: 'ชื่อเล่น',
    noData: 'ไม่มีข้อมูล',
    noDeposit: 'ไม่มีเงินฝาก',
    noOfDeduction: 'จำนวนการหักเงิน',
    noOfDepositTimes: 'จำนวนครั้งที่ฝาก',
    noOfPrivilegeClaimed: 'จำนวนสมาชิกที่ีรับสิทธิไปแล้ว',
    noOfReimbursement: 'จำนวนการเบิกจ่าย',
    noOfWinners: 'จำนวนผู้ชนะ',
    noOfWithdrawTimes: 'จำนวนครั้งที่ถอน',
    notBinded: 'ยังไม่ผูก',
    notRegister: 'ยังไม่ลงทะเบียน',
    notice: 'หมายเหตุ',
    onlyDeposit: 'แสดงข้อมูลของสมาชิกที่มีการฝากเงิน',
    onlyFake: 'แสดงเฉพาะบันทึกของผู้ที่ไม่ใช่สมาชิก',
    onlyReal: 'แสดงเฉพาะบันทึกสมาชิกเท่านั้น',
    open: 'เปิด',
    operate: 'ดำเนินงาน',
    operator: 'ผู้ดำเนินการ',
    operateTime: 'เวลาดำเนินการ',
    operationTime: 'เวลาทำการ',
    operationType: 'ประเภทการดำเนินงาน',
    orderNo: 'หมายเลขคำสั่งซื้อ',
    outerSetting: 'การตั้งค่าล้อด้านนอก',
    param: 'พารามิเตอร์',
    parentSite: 'เว็บไซต์หลัก',
    password: 'รหัสผ่าน',
    pause: 'หยุดชั่วคราว',
    pay: 'จ่าย',
    payBy: 'จ่ายโดย',
    payMessage: 'ข้อความชำระเงิน',
    payMessageMobile: 'จ่ายข้อความมือถือ',
    payResultType: 'ประเภทผลการจ่ายเงิน',
    payTime: 'เวลาชำระเงิน',
    payType: 'ประเภทการชำระเงิน',
    payTypeCode: 'รหัสประเภทการชำระเงิน',
    payTypeName: 'ชื่อประเภทการชำระเงิน',
    payment: 'การชำระเงิน',
    paymentArea: 'พื้นที่การชำระเงิน',
    paymentBy: 'ชำระเงินโดย',
    paymentCard: 'บัตรชำระเงิน',
    paymentDate: 'วันที่จ่าย',
    paymentFee: 'ค่าธรรมเนียมการชำระเงิน',
    paymentMethod: 'วิธีการชำระเงิน',
    paymentName: 'ชื่อการชำระเงิน',
    paymentOnGoing: 'กำลังดำเนินการจ่ายเงิน',
    paymentType: 'ประเภทการชำระเงิน',
    payout: 'การจ่ายเงิน',
    payoutTotal: 'ยอดจ่ายทั้งหมด',
    return_ratio: 'Ratio Return',
    return_ratio_total: 'อัตราผลตอบแทนทั้งหมด',
    pcWay: 'พีซี',
    pending: 'ระงับ',
    pendingTransaction: 'รอดำเนินการ',
    permission: 'การอนุญาต',
    permissionAssignment: 'การมอบหมายการอนุญาต',
    pgroup: 'กลุ่มสิทธิพิเศษ',
    planDays: 'วันวางแผน',
    planOdds: 'แผนราคาต่อรอง',
    planLimitMin: 'แผนขีดจำกัดขั้นต่ำ',
    planLimitMax: 'แผนขีดจำกัดสูงสุด',
    planTimeLimit: 'กำหนดเวลาตามแผน',
    platform: 'แพลตฟอร์ม',
    platformAccount: 'บัญชีแพลตฟอร์ม',
    platformId: 'รหัสแพลตฟอร์ม',
    platformBalance: 'ยอดคงเหลือของแพลตฟอร์ม',
    platformCode: 'รหัสแพลตฟอร์ม',
    platformFee: 'ค่าธรรมเนียมแพลตฟอร์ม',
    platformFundTransfer: 'การโอนเงินแพลตฟอร์ม',
    platformName: 'ชื่อแพลตฟอร์ม',
    playerName: 'ชื่อผู้เล่น',
    pleaseChoose: 'กรุณาเลือก',
    pleaseInput: 'กรุณากรอก',
    previousLevel: 'ระดับก่อนหน้า',
    previousLevelName: 'ชื่อระดับก่อนหน้า',
    privilege: 'สิทธิพิเศษ',
    privilegeCode: 'รหัสสิทธิพิเศษ',
    privilegeId: 'รหัสสิทธิ์',
    privilegeName: 'ชื่อสิทธิพิเศษ',
    privilegeRedeemed: 'แลกสิทธิ์แล้ว',
    privilegeSerialNo: 'หมายเลขลำดับสิทธิพิเศษ',
    privilegeType: 'ประเภทสิทธิพิเศษ',
    profit: 'กำไร',
    profitpositive: 'กำไรเชิงบวก',
    profitnegative: 'กำไรติดลบ',
    promo: 'โปรโมชั่น',
    promoApplication: 'แอปพลิเคชันโปรโม',
    promoCode: 'รหัสโปรโมชั่น',
    promoPages: 'หน้าโปรโมชั่น',
    promoStyle: 'สไตล์การโปรโมต',
    promoType: 'ประเภทโปรโมชั่น',
    queryNumber: 'หมายเลขแบบสอบถาม',
    queryOption: 'ตัวเลือกแบบสอบถาม',
    queryRestriction: 'ข้อจำกัดในการสืบค้น',
    randomMember: 'Random Member',
    rate: 'ประเมิน',
    realName: 'ชื่อจริง',
    englishName: 'ชื่อภาษาอังกฤษ',
    realVirtualVotes: 'โหวตจริง + โหวตเสมือนจริง',
    reason: 'เหตุผล',
    reasonType: 'ประเภทเหตุผล',
    reasonTemplate: 'แม่แบบเหตุผล',
    rebate: 'ส่วนลด',
    rebateDistributeTime: 'ระยะเวลาการกระจายส่วนลด',
    rebatePercentage: 'ส่วนลด เป็นเปอร์เซ็น',
    regEndDate: 'เวลาสิ้นสุดการลงทะเบียน',
    regStartDate: 'เวลาเริ่มลงทะเบียน',
    maxRebate: 'เงินคืนสูงสุด',
    rebateAmount: 'จำนวนเงินคืน',
    receiveTime: 'เวลารับ',
    receiveType: 'ประเภทการรับ',
    recipient: 'ผู้รับ',
    recordDate: 'วันที่บันทึก',
    recordTime: 'บันทึกเวลา',
    redeemedBy: 'แลกโดย',
    redirect: 'เปลี่ยนเส้นทาง',
    reenterPassword: 'ป้อนรหัสผ่านอีกครั้ง',
    referrer: 'ผู้อ้างอิง',
    register: 'ลงทะเบียน',
    registered: 'ลงทะเบียนแล้ว',
    registerAddress: 'ที่อยู่ที่ลงทะเบียน',
    registerCount: 'จำนวนที่ลงทะเบียน',
    registerDevice: 'ลงทะเบียนอุปกรณ์',
    registerHost: 'ลงทะเบียนโฮสต์',
    registerIp: 'ลงทะเบียนไอพี',
    registerMember: 'สมัครสมาชิก',
    registerTime: 'เวลาที่ลงทะเบียน',
    registrationDate: 'วันที่ลงทะเบียน',
    referredFriends: 'เพื่อนที่ได้รับการแนะนำ',
    referFriendRecord: 'บันทึกการแนะนำเพื่อน',
    remark: 'ข้อสังเกต',
    remove: 'ลบ',
    reply: 'ตอบ',
    requestUrl: 'URL คำขอ',
    requestParam: 'ขอพารามิเตอร์',
    responseBody: 'การตอบสนอง',
    reset: 'ตั้งค่าใหม่',
    result: 'ผลลัพธ์',
    resultDate: 'วันที่ประกาศผล',
    resultNumber: 'หมายเลขผลลัพธ์',
    resultTime: 'ผลลัพธ์เวลา',
    resume: 'ประวัติย่อ',
    revenueShare: 'ส่วนแบ่งรายได้',
    review: 'Review',
    reviewby: 'ตรวจสอบโดย',
    reviewno: 'ไม่มีความเห็น',
    reviewsuccess: 'ถูกต้อง',
    reviewstatus: 'สถานะการตรวจสอบ',
    reviewfail: 'ผิด',
    rewardGroup: 'กลุ่มรางวัล',
    rewardGroupName: 'ชื่อกลุ่ม',
    rewardGroupDownlineQuota: 'โควต้าดาวน์ไลน์',
    riskControl: 'การควบคุมความเสี่ยง',
    riskLevel: 'ระดับความเสี่ยง',
    role: 'บทบาท',
    roleList: 'รายการบทบาท',
    roleName: 'ชื่อบทบาท',
    rollover: 'โรโอเวอร์',
    rolloverAmount: 'จำนวนโรโอเวอร์',
    rolloverRecord: 'บันทึกโรโอเวอร์',
    gameTypeRollover: 'ประเภทเกมโรโอเวอร์',
    round: 'เกมฟรี',
    router: 'เราเตอร์',
    ruleParam: 'กฎพารามิเตอร์',
    ruleType: 'ประเภทกฎ',
    run: 'วิ่ง',
    save: 'บันทึก',
    saveAsNewPayment: 'บันทึกเป็นการชำระเงินใหม่',
    scheduleTime: 'ชั่วโมงการทำงาน',
    schemaName: 'Schema Name',
    search: 'ค้นหา',
    searchCondition: 'เงื่อนไขการค้นหา',
    securityQuestion: 'คำถามเพื่อความปลอดภัย',
    secondTicket: 'ตั๋วใบที่สอง (เดิมพัน/ฝาก)',
    sendTime: 'ส่งเวลา',
    sendSuccess: 'ส่งความสำเร็จ',
    sendFail: 'ส่งล้มเหลว',
    select: 'เลือก',
    selectNodeAddTo: 'เลือกโหมดที่จะเพิ่ม',
    selected: 'เลือกแล้ว',
    selectedNumber: 'หมายเลขที่เลือก',
    send: 'ส่ง',
    sequence: 'ลำดับ',
    serialNo: 'หมายเลขซีเรียล',
    settlement: 'การจัดการ',
    settleTime: 'ชำระเวลา',
    settleView: 'ดู',
    settlePay: 'จ่าย',
    settleEdit: 'แก้ไข',
    show: 'แสดง',
    showAll: 'แสดงทั้งหมด',
    sid: 'SID',
    site: 'เว็บไซต์',
    siteCode: 'รหัสไซต์',
    siteList: 'รายการไซต์',
    siteName: 'ชื่อไซต์',
    siteType: 'Site Type',
    sn: 'S/N',
    smsPhone: 'โทรศัพท์',
    smsContent: 'เนื้อหา',
    smsStatus: 'สถานะ',
    smsSendTime: 'ส่งเวลา',
    smsType: 'ประเภท',
    sorting: 'การเรียงลำดับ',
    sourceType: 'ประเภทแหล่งที่มา',
    sportBetResult: 'ผลการเดิมพันกีฬา',
    state: 'ที่ตั้ง',
    startCopy: 'เริ่มการคัดลอก',
    startUpdate: 'เริ่มต้นการปรับปรุง',
    startDate: 'วันที่เริ่มต้น',
    startTime: 'เวลาเริ่มต้น',
    status: 'สถานะ',
    stopAfterFailure: 'หยุดหลังจากความล้มเหลว',
    subject: 'หัวข้อ',
    subSiteCount: 'จำนวนไซต์ย่อย',
    subtotal: 'ผลรวมย่อย',
    success: 'สำเร็จ',
    successfullyExport: 'ส่งออกสำเร็จ',
    summaryAdjustment: 'การปรับ',
    summaryBonus: 'โบนัส',
    summaryCompanyWinLoss: 'บริษัทชนะ/แพ้',
    summaryProfit: 'กำไร',
    summaryReport: 'สรุปรายงาน',
    summaryTotalBet: 'จำนวนสมาชิกเดิมพันทั้งหมด',
    superiorAffiliateInfo: 'ข้อมูลพันธมิตรที่เหนือกว่า',
    superiorAffiliateName: 'ชื่อพันธมิตรที่เหนือกว่า',
    superiorAffiliateCode: 'รหัสพันธมิตรที่เหนือกว่า',
    superiorAffiliateLevel: 'ระดับพันธมิตรที่เหนือกว่า',
    superiorCategory: 'หมวดที่เหนือกว่า',
    supplement: 'เพิ่มเติม',
    supplementDeposit: 'การฝากเพิ่มเติม',
    supportType: 'ประเภทการสนับสนุน',
    suspend: 'ระงับ',
    switch: 'การเปลี่ยน',
    switchPayment: 'สลับการชำระเงิน',
    systemPayment: 'การชำระเงินของระบบ',
    teamName: 'ชื่อทีม',
    teamNameEn: 'ชื่อทีม ภาษาอังกฤษ',
    teamNameLocal: 'ชื่อทีมท้องถิ่น',
    telephone: 'โทรศัพท์',
    thirtyDaysdw: 'Thirty Days deposit - withdraw',
    thirdParty: 'บุคคลที่สาม',
    thirdPartyName: 'ชื่อบุคคลที่สาม',
    thirdPartyTotalBet: 'การเดิมพันทั้งหมดในเกมของบุคคลที่สาม',
    thirdPartyTotalPayout: 'เงินรางวัลรวมจากเกมของบุคคลที่สาม',
    thirdPartyTotalWin: 'กำไรรวมของเกมบุคคลที่สาม',
    thirdSerialNo: 'หมายเลขซีเรียลที่สาม',
    thirdTicket: 'ตั๋วใบที่สาม (เดิมพัน/ฝาก)',
    thisMonth: 'เดือนนี้',
    thisWeek: 'ในสัปดาห์นี้',
    ticketSetting: 'การตั้งค่าตั๋ว',
    tips: 'เคล็ดลับ',
    title: 'หัวข้อ',
    toApplying: 'สำหรับการใช่งาน',
    toBeforePaid: 'เตรียมการชำระ',
    toBePaid: 'เตรียมการชำระ',
    toChecking: 'กำลังตรวจสอบ',
    today: 'วันนี้',
    toPay: 'จ่าย',
    toPaymentOnGoing: 'เตรียมการชำระ',
    toreview: 'เพื่อตรวจสอบ',
    toSuspend: 'เพื่อระงับ',
    total: 'ทั้งหมด',
    totalAdjustment: 'การปรับทั้งหมด',
    totalBalance: 'ยอดคงเหลือทั้งหมด',
    totalBet: 'เดิมพันทั้งหมด',
    totalBetAmount: 'จำนวนเงินเดิมพันทั้งหมด',
    totalBetCount: 'จำนวนเดิมพันทั้งหมด',
    totalBetMemberCount: 'จำนวนผู้เดิมพันทั้งหมด',
    totalBetRecords: 'บันทึกการเดิมพันทั้งหมด',
    totalBonus: 'โบนัสรวม',
    totalCommission: 'ค่าคอมมิชชั่นทั้งหมด',
    totalCommissionProfit: 'กำไรค่าคอมมิชชั่นทั้งหมด',
    totalCompanyProfit: 'กำไรจริง',
    totalDeductAmount: 'จำนวนเงินที่หักทั้งหมด',
    totalDeposit: 'ยอดรวมเงินฝาก',
    totalDepositMemberCount: 'จำนวนการชาร์จทั้งหมด',
    totalDepositAmount: 'จำนวนเงินฝากทั้งหมด',
    totalDepositCount: 'จำนวนเงินฝากทั้งหมด',
    totalDepositedAmount: 'จำนวนเงินฝากทั้งหมด',
    totalDepositNumber: 'สมาชิกเงินฝากทั้งหมด',
    totalDownlineAffiliates: 'บริษัทในเครือดาวน์ไลน์ทั้งหมด',
    totalDownlineMembers: 'สมาชิกดาวน์ไลน์ทั้งหมด',
    totalFailDeposit: 'จำนวนการเติมเงินที่ล้มเหลว',
    totalFailDepositAmount: 'จำนวนเงินฝากที่ล้มเหลวทั้งหมด',
    totalFailWithdraw: 'จำนวนการถอนล้มเหลวทั้งหมด',
    totalFailWithdrawAmount: 'จำนวนการถอนล้มเหลวทั้งหมด',
    totalFirstDeposit: 'ยอดรวมการฝากครั้งแรก',
    totalMemberDepositAmount: 'จำนวนเงินที่เติมทั้งหมด',
    totalMemberDepositCount: 'จำนวนการชาร์จทั้งหมด',
    totalNoOfDeposits: 'ยอดรวมการฝากครั้งแรก',
    totalNoOfWithdrawals: 'จำนวนการถอนทั้งหมด',
    totalPayout: 'จ่ายไปทั้งหมด',
    totalPrivilegeAmount: 'จำนวนสิทธิพิเศษทั้งหมด',
    totalPrivilegeClaimAmount: 'จำนวนผู้ได้รับสิทธิทั้งหมด',
    totalPromo: 'โปรโมชั่นรวมทั้งหมด',
    totalProfit: 'กำไรทั้งหมด',
    totalRebateAmount: 'จำนวนเงินคืนทั้งหมด',
    totalRedeem: 'แลกไปทั้งหมด',
    totalRegister: 'ลงทะเบียนไปทั้งหมด',
    totalRegisterCount: 'จำนวนการลงทะเบียนทั้งหมด',
    totalReimburseAmount: 'จำนวนเงินชดเชยทั้งหมด',
    totalSuccessDeposit: 'เงินฝากความสำเร็จทั้งหมด',
    totalSuccessDepositAmount: 'จำนวนเงินฝากสำเร็จทั้งหมด',
    totalSuccessDepositPercentage: 'เปอร์เซ็นต์เงินฝากที่สำเร็จทั้งหมด',
    totalSuccessWithdrawAmount: 'จำนวนการถอนความสำเร็จทั้งหมด',
    totalTime: 'เวลาทั้งหมด',
    totalTransferIn: 'ยอดโอนเข้า',
    totalTransferOut: 'ยอดโอนออก',
    toUnderReview: 'อยู่ระหว่างการตรวจสอบ',
    totalWithdraw: 'ถอนออกทั้งหมด',
    totalWithdrawAmount: 'จำนวนเงินที่ถอนทั้งหมด',
    totalWithdrawCount: 'จำนวนการถอนทั้งหมด',
    totalWithdrawnAmount: 'จำนวนเงินที่ถอนทั้งหมด',
    transaction: 'ธุรกรรม',
    transactionStatus: 'สถานะการทำธุรกรรม',
    transactionTime: 'เวลาทำธุรกรรม',
    transactionId: 'รหัสธุรกรรม',
    transferDate: 'วันที่โอน',
    transferId: 'รหัสประจำตัวผู้โอน',
    transferIn: 'โอนเข้า',
    transferOut: 'โอนออก',
    transferTime: 'เวลาโอน',
    transferType: 'ประเภทการโอน',
    triggerType: 'ประเภททริกเกอร์',
    turnoverMultiple: 'มูลค่าการซื้อขายหลายรายการ',
    txnId: 'รหัส Txn',
    txnTime: 'ส่งเวลา',
    type: 'ประเภท',
    underReview: 'อยู่ระหว่างการตรวจสอบ',
    unlockMember: 'ปลดล็อคสมาชิก',
    unlockSetting: 'ตั้งค่าการปลดล๊อค',
    unlockUser: 'ปลดล็อคผู้ใช้',
    url: 'URL การเข้าถึง',
    unsettleCommission: 'ค่าคอมมิชชั่นที่ยังไม่ได้จัดการ',
    update: 'อัพเดท',
    updateAlias: 'อัปเดตชื่อเล่น',
    updateBy: 'อัพเดทโดย',
    updateCommissionModel: 'ปรับรูปแบบคอมมิชชั่น',
    updateCommissionRate: 'ปรับอัตราค่าคอมมิชชั่น',
    updateFinancialLevel: 'ปรับระดับการเงิน',
    updatePassword: 'อัปเดตรหัสผ่าน',
    updateRiskLevel: 'อัปเดตระดับความเสี่ยง',
    updateTime: 'เวลาที่อัปเดต',
    updateVIPLevel: 'อัพเดทระดับวีไอพี',
    upgradeToCheck: 'อัปเกรดเป็นอยู่ระหว่างการตรวจสอบ',
    upgradeToPaymentOnGoing: 'อัปเกรดเป็นการชำระเงิน',
    upLineLoginName: 'ชื่อล็อกอินอัพไลน์',
    upload: 'ที่อัพโหลด',
    upperName: 'ชื่อของระดับที่สูงกว่า',
    usdtDepositAmount: 'จำนวนเงินฝากสกุลเงินดิจิทัล',
    usdtDepositCount: 'จำนวนผู้ฝากสกุลเงินดิจิทัล',
    username: 'ชื่อผู้ใช้',
    userType: 'ประเภทผู้ใช้',
    validate: 'ตรวจสอบความถูกต้อง',
    validity: 'วันหมดอายุ',
    validBet: 'เดิมพันที่ถูกต้อง',
    value: 'ค่า',
    view: 'ดู',
    viewLog: 'ดูบันทึก',
    vipLevel: 'ระดับวีไอพี',
    vipRebateDetails: 'รายละเอียดบันทึกการคืนเงิน VIP',
    vipRebateRecord: 'บันทึกส่วนลดวีไอพี',
    virtualVotes: 'โหวตเสมือนจริง',
    visitNumber: 'จำนวนผู้เข้าเยี่ยมชม',
    voteTime: 'เวลาลงคะแนน',
    walletType: 'ประเภทกระเป๋าสตางค์',
    way: 'ทาง',
    web: 'เว็บ',
    webMessage: 'ข้อความเว็บ',
    withdraw: 'ถอน',
    withdrawableBalance: 'ยอดคงเหลือที่สามารถถอนได้',
    withdrawArea: 'พื้นที่การถอน',
    withdrawExchangeRate: 'ถอนอัตราแลกเปลี่ยน',
    withdrawalFailureCause: 'สาเหตุการถอนล้มเหลว',
    withdrawalFailureType: 'ประเภทความล้มเหลวในการถอน',
    withdrawAmount: 'จำนวนเงินการถอน',
    withdrawCard: 'ถอนบัตร',
    withdrawCode: 'ถอนรหัส',
    withdrawCount: 'จำนวนการถอน',
    withdrawDate: 'วันที่ถอนเงิน',
    withdrawMemberCount: 'จำนวนสมาชิกที่ถอน',
    withdrawName: 'ชื่อในการถอน',
    withdrawPayoutBonus: 'การถอน/การจ่ายเงิน/โบนัส/การปรับ',
    withdrawPlatform: 'ถอนแพลตฟอร์ม',
    withdrawPlatformList: 'รายการแพลตฟอร์มที่ถอน',
    withdrawPlatformName: 'ถอนชื่อแพลตฟอร์ม',
    withdrawStatus: 'สถานะการถอน',
    yesterday: 'เมื่อวาน',
    targetType: 'ประเภทการดำเนินงาน',
    targetId: 'หมายเลขปฏิบัติการ',
    targetName: 'ชื่อการดำเนินงาน',
    tigerCardType: 'ประเภทบัตร',
    tigerCardOpeType: 'ประเภทการดำเนินงาน',
    tigerCardSource: 'แหล่งที่มา/เป้าหมาย',
    tigerCardBeforeCount: 'ก่อนนับ',
    tigerCardRealCount: 'จำนวนบัตร tiger',
    tigerCardVirtualCount: 'จำนวนบัตรเสมือนจริง',
    tigerCardPeriod: 'ระยะเวลา',
    tigerCardTime: 'เวลาลอตเตอรี่',
    tigerCardBonusTime: 'เวลารับ',
    timeType: 'ประเภทเวลา',
    count: 'นับ',
    host: 'เจ้าภาพ',
    port: 'ส่วน',
    user: 'ผู้ใช้',
    from: 'จาก',
    addEmailSetting: 'เพิ่มการตั้งค่าอีเมล',
    editEmailSetting: 'แก้ไขการตั้งค่าอีเมล',
    signName: 'ลงชื่อ',
    secretId: 'ข้อมูล ไอดี',
    secretKey: 'รหัสลับ',
    appId: 'รหัสแอปพลิเคชัน',
    templateId: 'รหัสเทมเพลต',
    addSmsSetting: 'เพิ่มการตั้งค่า SMS',
    editSmsSetting: 'แก้ไขการตั้งค่า SMS',
    requestExportToExcel: 'ขอส่งออกข้อมูลเป็น Excel',
    downloadManagerRecord: 'ดาวน์โหลดบันทึกผู้จัดการ',
    downloadExportedExcel: 'ดาวน์โหลด Excel ที่ส่งออก',
    requestBy: 'คำขอโดย',
    requestTime: 'ขอเวลา',
    progress: 'ความคืบหน้า',
    download: 'ดาวน์โหลด',
    updateProgress: 'อัพเดทความคืบหน้า',
    indBet: 'วางเดิมพัน',
    indProfit: 'การเดิมพันกำไร',
    memberDepositCount: 'จำนวนเครื่องชาร์จทั้งหมด',
    indBonusAmount: 'จำนวนโบนัส',
    indRebateAmount: 'จำนวนเงินคืน',
    indAdjustAmount: 'จำนวนการปรับปรุงกองทุน',
    grossProfit: 'กำไรขั้นต้น',
    winloss: 'ชนะการสูญเสีย',
    profit2: 'Dep-With',
    whitelist: 'รายการที่อนุญาต',
    blacklist: 'บัญชีดำ',
    accessType: 'ประเภทการเข้าถึง',
    domainType: 'ประเภทชื่อโดเมน',
    valueType: 'ประเภทค่า',
    ipList: 'กลุ่มไอพี',
    ipRange: 'ช่วงที่อยู่ IP',
    countryCode: 'รหัสประเทศ',
    lower: 'ขีดจำกัดล่าง',
    upper: 'ขีด จำกัด บน',
    defaultConfigHint: 'Default',
    defaultConfigSearchBarHint: 'Search by ',
    configGroup: 'Config Group',
    configCode: 'Config Code',
    configValue: 'Config Value',
    configDescribes: 'Config Describes',
  },
  message: {
    startenddatemore3months: 'เวลาเริ่มและเสร็จสิ้นต้องไม่เกิน 3 เดือน',
    addRemarkSuccess: 'เพิ่มหมายเหตุความสำเร็จ',
    addSuccess: 'เพิ่มความสำเร็จ',
    adjustSuccess: 'ปรับจำนวนเงินสำเร็จ',
    affiliateApproved: 'พันธมิตรได้รับการอนุมัติ',
    affiliateDisabled: 'พันธมิตรปิดการใช้งาน',
    autopaySuccess: 'ความสำเร็จของการชำระเงินอัตโนมัติ',
    cancelDepositSuccess: 'ยกเลิกการฝากเงินสำเร็จ',
    cancelRolloverRecordSuccess: 'ยกเลิกความสำเร็จในการบันทึกแบบโรลโอเวอร์',
    changeAffiliateSuccess: 'เปลี่ยนความสำเร็จของพันธมิตร',
    confirmUnbindAffiliateAccesss: 'ยืนยันการยกเลิกตัวแทนระดับสูง',
    changeSuccess: 'เปลี่ยนความสำเร็จ',
    commissionPaySuccess: 'ความสำเร็จในการจ่ายค่าคอมมิชชั่น',
    confirmToAdjust:
      'คุณแน่ใจหรือว่าจะทำการปรับเปลี่ยน? ไม่สามารถทำการปรับเปลี่ยนอื่น ๆ ได้หลังจากการยืนยัน',
    confirmCopy: 'คุณต้องการคัดลอกจาก ',
    confirmCopyTo: ' ถึง ',
    confirmDelete:
      'ยืนยันว่าคุณต้องการลบข้อมูลเหล่านี้ การดำเนินการนี้ไม่สามารถยกเลิกได้',
    confirmRebate:
      'ยืนยันว่าคุณต้องการกระจายส่วนลดทั้งหมด การดำเนินการไม่สามารถยกเลิกได้',
    confirmRefer:
      'ยืนยันว่าคุณต้องการแจกจ่ายโปรโมชั่นแนะนำเพื่อนทั้งหมด การดำเนินการนี้ไม่สามารถยกเลิกได้',
    confirmReset:
      'ยืนยันว่าคุณต้องการรีเซ็ตข้อมูลเหล่านี้ การดำเนินการนี้ไม่สามารถยกเลิกได้',
    confirmSettlement: 'ยืนยันว่าคุณต้องการดำเนินการระงับข้อพิพาทต่อไปหรือไม่?',
    confirmToChecking:
      'ยืนยันว่าคุณต้องการเปลี่ยนการชำระเงินกลับเป็นการตรวจสอบหรือไม่',
    confirmToPay: 'ยืนยันว่าคุณต้องการดำเนินการชำระเงินต่อหรือไม่?',
    confirmToCheck: 'คุณต้องการอัปเดตหรือไม่?',
    confirmUpdate: 'คุณต้องการอัปเดตหรือไม่?',
    confirmUpdatePayment: 'คุณต้องการอัปเดตการชำระเงินหรือไม่?',
    copyToClipboard: ' ถูกคัดลอกไปยังคลิปบอร์ดแล้ว',
    copySuccess: 'คัดลอกสำเร็จ',
    deleteSuccess: 'ลบสำเร็จ',
    depositSuccess: 'การฝากสำเร็จ',
    editAmountSuccess: 'แก้ไขจำนวนสำเร็จ',
    editRemarkSuccess: 'แก้ไขหมายเหตุสำเร็จ',
    editSuccess: 'แก้ไขความสำเร็จ',
    editMemberDetail: 'แก้ไขรายละเอียดสมาชิก',
    enterMemberDetails: 'กรอกรายละเอียดสมาชิก',
    failedToUploadImage: 'การอัพโหลดภาพล้มเหลว',
    freezeMemberSuccess: 'ระงับสมาชิกสำเร็จ',
    found: 'ตรวจพบ ',
    importSuccess: 'การนำเข้าสำเร็จ',
    inputDigits: 'กรุณากรอกตัวเลข',
    inputPassword: 'กรุณาใส่รหัสผ่าน',
    inputPasswordAgain: 'กรุณาใส่รหัสผ่านอีกครั้ง',
    invalidFileType: 'ประเภทไฟล์ไม่ถูกต้อง ',
    length6To12: 'ความยาวควรเป็น 6 ถึง 12',
    levelUpCreditMoreThan: 'เครดิตการเลื่อนระดับจะต้องมากกว่า ',
    logoutPlayerSuccess: 'ความสำเร็จของผู้เล่นออกจากระบบ',
    maxGreaterThanMin: 'ค่าสูงสุดต้องมากกว่าค่าต่ำสุด',
    memberNotInSite: 'ไม่พบสมาชิกในเว็บไซต์นี้',
    affiliateNotInSite: 'ไม่พบสมาชิกในเว็บไซต์นี้',
    memberFreeGameLogin:
      'บัญชีผู้เล่น คั่นด้วยเครื่องหมายจุลภาคหากมีมากกว่าหนึ่งบัญชี (สูงสุด 200 บัญชี)',
    memberFreeGameRound: 'รอบเกมฟรี',
    memberFreeGameIds:
      'สามารถใช้รหัสเกมได้โดยคั่นด้วยเครื่องหมายจุลภาคหากมีมากกว่าหนึ่ง รหัส ความยาวสูงสุด (รวมเครื่องหมายจุลภาค) คือ 200',
    multipleQuerySeparatedBySpace:
      'เงื่อนไขการสืบค้นหลายรายการจะถูกคั่นด้วยช่องว่าง',
    multiwheelprizeform: 'ใส่ 0 เป็นรางวัลใหญ่',
    noAvailablePrivilege: 'ขณะนี้ยังไม่มีสิทธิพิเศษ',
    passwordLength: 'รหัสผ่านต้องไม่น้อยกว่า 6 หลักหรือมากกว่า 12 หลัก',
    paymentUpdate: 'คัดลอกไปยังวิธีหรือระดับอื่น? หากไม่ก็กดอัปเดต',
    paySuccess: 'การชำระเงินสำเร็จ',
    pleaseEnterAffiliateNameOrAffiliateCode:
      'กรุณากรอกชื่อพันธมิตรหรือรหัสพันธมิตร',
    promoDistributionSuccess: 'การแจกจ่ายโปรโมชั่นสำเร็จ',
    reenterPassword: 'กรุณากรอกรหัสผ่านอีกครั้ง',
    rebateSuccess: 'การจ่ายเงินคืน สำเร็จ',
    registerSuccess: 'ลงทะเบียนสำเร็จ',
    referSuccess: 'ความสำเร็จของโปรโมชั่นแนะนำเพื่อน',
    replySuccess: 'การตอบสำเร็จ',
    removePreviousLevel: 'กรุณาลบระดับก่อนหน้า',
    removeRemarkSuccess: 'ลบหมายเหตุสำเร็จ',
    resetSuccess: 'รีเซ็ตสำเร็จ',
    selectPreviousLevel: 'กรุณาเลือกระดับก่อนหน้า',
    selectSiteFirst: 'โปรดเลือกไซต์ก่อน',
    selectSiteAndGameTypeFirst: 'โปรดเลือกไซต์และประเภทเกมก่อน',
    selectPaymentType: 'กรุณากรอกประเภทการชำระเงิน',
    selectMobilePayment: 'กรุณาเลือกชำระเงินผ่านมือถือ',
    selectWebPayment: 'กรุณาเลือกชำระเงินทางเว็บ',
    settlementToChecking: 'อัปเดตเป็นการตรวจสอบแล้ว',
    settlementToPay: 'อัปเดตเป็นการชำระเงินแล้ว',
    settled: 'ตัดสินได้สำเร็จ',
    success: 'ความสำเร็จ',
    summarySuccess: 'สรุประยะเวลาการป้อนข้อมูลจะถูกสร้างขึ้น กรุณารอสักครู่',
    transferSuccess: 'โอนสำเร็จ',
    twoPasswordNotMatch: 'รหัสผ่านทั้งสองไม่ตรงกัน',
    unlockMemberSuccess: 'สมาชิกปลดล็อคสำเร็จ',
    unlockUserSuccess: 'ปลดล็อกความสำเร็จของผู้ใช้',
    updateBelongTypeSuccess: 'อัปเดตประเภทหน้าเว็บสำเร็จแล้ว',
    updateCommissionModelSuccess: 'อัปเดตความสำเร็จของโมเดลคอมมิชชั่น',
    updateCommissionRateSuccess: 'อัปเดตอัตราค่าคอมมิชชั่นสำเร็จ',
    updateFinancialLevelSuccess: 'อัพเดทความสำเร็จระดับการเงิน',
    updatePasswordSuccess: 'อัปเดตรหัสผ่านสำเร็จ',
    updatePayment: 'อัพเดทการชำระเงิน',
    updateProceed: 'อัปเดตโหมดเพื่อดำเนินการแก้ไขการชำระเงิน',
    updateRiskLevelSuccess: 'อัปเดตความสำเร็จระดับความเสี่ยง',
    updateSuccess: 'อัปเดต สำเร็จ',
    updateTimeTypeSuccess: 'อัปเดตประเภทเวลา สำเร็จ',
    updateToApplySuccess: 'อัปเดตเพื่อใช้ความสำเร็จ',
    updateToBeforePaidSuccess: 'อัปเดตก่อนชำระเงิน สำเร็จ',
    updateToFailSuccess: 'อัปเดตเป็นล้มเหลว สำเร็จ',
    updateToPaySuccess: 'อัปเดตเป็นกำลังชำระเงิน สำเร็จ',
    updateToSuspendSuccess: 'อัปเดตเพื่อระงับ สำเร็จ',
    updateToUnderReviewSuccess: 'อัปเดตเป็นอยู่ระหว่างตรวจสอบสำเร็จ',
    updateUserTypeSuccess: 'อัปเดตประเภทผู้ใช้งานสำเร็จ',
    updateVIPLevelSuccess: 'อัปเดตความสำเร็จระดับ VIP',
    useCommaToSeparateDomain: 'กรุณาใช้ "," เพื่อแยกชื่อโดเมนหากใส่หลายโดเมน',
    validateAdsTypeRequired: 'ต้องระบุประเภทโฆษณา',
    validateActionRequired: 'จำเป็นต้องมีการดำเนินการ',
    validateActivePlayerRequired: 'จำเป็นต้องมีผู้เล่นที่ใช้งานอยู่',
    validateAdjustAmountRequired: 'ต้องปรับจำนวนเงิน',
    validateAdjustReasonRequired: 'จำเป็นต้องปรับเหตุผล',
    validateAdjustTypeRequired: 'จำเป็นต้องปรับประเภท',
    validateAffiliateFbIdRequired: 'Facebook pixel ID is required',
    validateAffiliateFbTokenRequired: 'Facebook pixel token is required',
    validateAffiliateFbUrlRequired: 'Facebook pixel url is required',
    validateAffiliateCodeRequired: 'จำเป็นต้องมีรหัสพันธมิตร',
    validateAffiliateLevelRequired: 'จำเป็นต้องมีระดับพันธมิตร',
    validateAffiliateNameRequired: 'จำเป็นต้องระบุชื่อพันธมิตร',
    validateAliasRequired: 'ต้องมีชื่อเล่น',
    validateAlphaNumericOnly: 'กรุณากรอกอักขระและตัวเลขเท่านั้น',
    validateAmountRequired: 'ต้องระบุจำนวนเงิน',
    validateAnnouncementTypeRequired: 'ต้องระบุประเภทประกาศ',
    validateAtLeastSixChar: 'กรุณากรอกอย่างน้อย 6 ตัวอักษร',
    validateBankRequired: 'จำเป็นต้องมีธนาคาร',
    validateBankCardNumberRequired: 'ต้องระบุหมายเลขบัตรธนาคาร',
    validateBankCodeRequired: 'ต้องระบุรหัสธนาคาร',
    validateBankNameRequired: 'ต้องระบุชื่อธนาคาร',
    validateBankTypeRequired: 'ต้องระบุประเภทธนาคาร',
    validateBeanNameRequired: 'Bean Name is required',
    validateBonusAmountRequired: 'ต้องระบุจำนวนโบนัส',
    validateBonusAmountRatioRequired: 'ต้องมีอัตราส่วนจำนวนโบนัส',
    validateBonusTypeRequired: 'ต้องระบุประเภทโบนัส',
    validateCardAccountRequired: 'ต้องมีบัญชีบัตร',
    validateCardAddressRequired: 'ต้องระบุที่อยู่บัตร',
    validateCardNumberRequired: 'ต้องระบุหมายเลขบัตร',
    validateCategoryRequired: 'ต้องระบุหมวดหมู่',
    validateCauseRequired: 'ต้องมีสาเหตุ',
    validateClassNameRequired: 'ต้องระบุชื่อระดับ',
    validateCodeRequired: 'จำเป็นต้องระบุรหัส',
    validateColorRequired: 'ต้องระบุสี',
    validateCommissionRequired: 'จำเป็นต้องมีค่าคอมมิชชั่น',
    validateCommissionFormat: 'ค่าคอมมิชชันจะต้องอยู่ระหว่าง 0 ถึง 1',
    validateCommissionModelRequired: 'จำเป็นต้องมีโมเดลคอมมิชชั่น',
    validateConfirmPasswordRequired: 'ต้องยืนยันรหัสผ่าน',
    validateConfirmPasswordSize: 'ยืนยันรหัสผ่านจะต้องมีอักขระ 6-12 ตัว',
    validateContentRequired: 'จำเป็นต้องมีเนื้อหา',
    validateCopyFinancialLevel: 'โปรดเลือกอย่างน้อย 1 ระดับเพื่อคัดลอก',
    validateCountryImageRequired: 'ต้องระบุภาพประเทศ',
    validateCronExpressionFormat: 'รูปแบบ Cron Expression ไม่ถูกต้อง',
    validateCronExpressionRequired: 'จำเป็นต้องมีนิพจน์ Cron',
    validateCurrencyRequired: 'ต้องระบุสกุลเงิน',
    validateCurrencyCodeRequired: 'ต้องระบุรหัสสกุลเงิน',
    validateCurrencyNameRequired: 'ต้องระบุชื่อสกุลเงิน',
    validateCustomerSupportAddressFormat:
      'Customer Support Address JSON String is invalid',
    validateDateRequired: 'ต้องระบุวันที่',
    validateDayWithdrawCountRequired: 'ต้องนับจำนวนการถอนรายวัน',
    validateDepositExchangeRateRequired: 'จำเป็นต้องมีอัตราแลกเปลี่ยนเงินฝาก',
    validateDesktopImageRequired: 'จำเป็นต้องมีรูปภาพเดสก์ท็อป',
    validateDesktopBannerRequired: 'จำเป็นต้องมีแบนเนอร์เดสก์ท็อป',
    validateDeviceRequired: 'จำเป็นต้องมีอุปกรณ์',
    validateDomainRequired: 'จำเป็นต้องมีโดเมน',
    validateEmailRequired: 'จำเป็นต้องระบุอีเมล',
    validateEmailFormat: 'กรุณากรอกรูปแบบที่อยู่อีเมลที่ถูกต้อง',
    validateEndDateRequired: 'ต้องระบุวันที่สิ้นสุด',
    validateEndTimeRequired: 'ต้องระบุเวลาสิ้นสุด',
    validateExchangeRateRequired: 'ต้องระบุอัตราแลกเปลี่ยน',
    validateFailReasonRequired: 'ต้องระบุเหตุผลที่ล้มเหลว',
    validateFeeRateRequired: 'Fee Rate is required',
    validateFreezeTypeRequired: 'Freeze type is required',
    validateFinancialLevelRequired: 'จำเป็นต้องมีระดับทางการเงิน',
    validateFrequencyRequired: 'ต้องระบุความถี่',
    validateGameCodeRequired: 'จำเป็นต้องมีรหัสเกม',
    validateGameNameRequired: 'ต้องระบุชื่อเกม',
    validateGameTypeRequired: 'ต้องระบุประเภทเกม',
    validateIconRequired: 'จำเป็นต้องมีไอคอน',
    validateIdentifyCodeRequired: 'จำเป็นต้องระบุรหัส',
    validateIpRequired: 'จำเป็นต้องมีไอพี',
    validateJobNameRequired: 'ต้องระบุชื่องาน',
    validateLabelRequired: 'จำเป็นต้องมีป้ายกำกับ',
    validateLevelRequired: 'จำเป็นต้องมีระดับ',
    validateLevelNameRequired: 'ต้องระบุชื่อระดับ',
    validateLevelUpCreditRequired: 'จำเป็นต้องมีเครดิตการเลื่อนระดับ',
    validateLoginNameRequired: 'ต้องระบุชื่อเข้าสู่ระบบ',
    validateLoginNameSize: 'ชื่อเข้าสู่ระบบจะต้องมีอักขระ 6-12 ตัว',
    validateMaintenanceTimeRequired: 'ต้องใช้เวลาบำรุงรักษา"',
    validateMallCodeRequired: 'จำเป็นต้องมีรหัสห้าง',
    validateMallKeyRequired: 'จำเป็นต้องมีกุญแจห้าง',
    validateMallNameRequired: 'จำเป็นต้องมีชื่อห้าง',
    validateMaxDailyWithdrawRequired: 'ต้องถอนเงินสูงสุดต่อวัน',
    validateMaxDailyWithdrawNumber:
      'การถอนสูงสุดรายวันต้องไม่ต่ำกว่าหรือเท่ากับ 0',
    validateMaxDailyWithdrawGreater:
      'การถอนสูงสุดรายวันจะต้องมากกว่าจำนวนการถอนสูงสุด',
    validateMaxDailyWithdrawTimesRequired: 'มีจำกัดจำนวนการถอนต่อวัน',
    validateMaxDailyWithdrawTimesNumber:
      'จำนวนการถอนต่อวันต้องไม่น้อยกว่าหรือเท่ากับ 0',
    validateMaxDepositRequired: 'ต้องมีเงินฝากสูงสุด',
    validateMaxDepositGreater: 'เงินฝากสูงสุดจะต้องมากกว่าเงินฝากขั้นต่ำ',
    validateMaxWithdrawAmountRequired: 'ต้องระบุจำนวนเงินถอนสูงสุด',
    validateMaxWithdrawAmountNumber:
      'จำนวนเงินถอนสูงสุดต้องไม่น้อยกว่าหรือเท่ากับ 0',
    validateMaxWithdrawAmountGreater:
      'จำนวนถอนสูงสุดต้องมากกว่าจำนวนถอนขั้นต่ำ',
    validateMemberTypeRequired: 'ต้องระบุประเภทสมาชิก',
    validateMenuTitleRequired: 'ต้องระบุชื่อเมนู',
    validateMinBalanceRequired: 'ต้องมียอดคงเหลือขั้นต่ำ',
    validateMinDepositRequired: 'ต้องมีเงินฝากขั้นต่ำ',
    validateMinDepositLesser: 'เงินฝากขั้นต่ำจะต้องน้อยกว่าเงินฝากสูงสุด',
    validateMinWithdrawRequired: 'ต้องระบุจำนวนการถอนขั้นต่ำ',
    validateMinWithdrawNumber: 'การถอนขั้นต่ำต้องไม่น้อยกว่าหรือเท่ากับ 0',
    validateMinWithdrawLesser:
      'จำนวนเงินถอนขั้นต่ำจะต้องน้อยกว่าจำนวนเงินถอนสูงสุด',
    validateMobileImageRequired: 'จำเป็นต้องมีรูปภาพบนมือถือ',
    validateMobileBannerRequired: 'ต้องมีแบนเนอร์บนมือถือ',
    validateMobileMessageRequired: 'จำเป็นต้องมีข้อความมือถือ',
    validateNameRequired: 'ต้องระบุชื่อ',
    validateNeedRegisterRequired: 'จำเป็นต้องลงทะเบียน',
    validateNetProfitRequired: 'ต้องมีกำไรสุทธิ',
    validateNextActivationTimeRequired: 'ต้องใช้เวลาเปิดใช้งานครั้งถัดไป',
    validateNextGetBetEndTime: 'ต้องระบุเวลาสิ้นสุดการรับเดิมพันครั้งถัดไป',
    validateNextGetBetPage: 'จำเป็นต้องมีหน้ารับเดิมพันถัดไป',
    validateNextGetBetStartTime: 'ต้องระบุเวลาเริ่มรับเดิมพันครั้งถัดไป',
    validateNoNullValue: 'ไม่สามารถมีค่าว่างได้',
    validateNumberOnly: 'กรุณาใส่หมายเลข',
    validateNumberFourDecimalOnly:
      'กรุณากรอกตัวเลขที่มากกว่า 0 และมีทศนิยม 4 ตำแหน่งเท่านั้น',
    validateOnlyThreeChar: 'กรุณากรอก 3 ตัวอักษร',
    validateParamRequired: 'ต้องระบุพารามิเตอร์',
    validatePasswordRequired: 'ต้องการรหัสผ่าน',
    validatePasswordSize: 'รหัสผ่านจะต้องมี 6-12 ตัวอักษร',
    validatePaymentFeeFormat:
      'ค่าธรรมเนียมการชำระเงินจะต้องอยู่ระหว่าง 0 ถึง 1',
    validatePaymentNameRequired: 'ต้องระบุชื่อการชำระเงิน',
    validatePaymentTypeRequired: 'ต้องระบุประเภทการชำระเงิน',
    validatePayResultTypeRequired: 'ต้องระบุประเภทผลการจ่ายเงิน',
    validatePayTypeRequired: 'ต้องระบุประเภทการชำระเงิน',
    validatePayTypeCodeRequired: 'ต้องระบุรหัสประเภทการชำระเงิน',
    validatePayTypeNameRequired: 'ต้องระบุชื่อประเภทการชำระเงิน',
    validatePayTypeNameCodeDifferent:
      'ชื่อประเภทการชำระเงินและรหัสประเภทการชำระเงินไม่สามารถเป็นค่าเดียวกันได้',
    validatePlatformRequired: 'จำเป็นต้องมีแพลตฟอร์ม',
    validatePlatformAccountRequired: 'ต้องมีบัญชีแพลตฟอร์ม',
    validatePlatformCodeRequired: 'ต้องระบุรหัสแพลตฟอร์ม',
    validatePlatformFeeFormat: 'ค่าธรรมเนียมแพลตฟอร์มจะต้องอยู่ระหว่าง 0 ถึง 1',
    validatePlatformNameRequired: 'ต้องระบุชื่อแพลตฟอร์ม',
    validatePrivilegeRequired: 'ต้องระบุชื่อสิทธิ์',
    validatePromoCodeRequired: 'ต้องใส่รหัสที่จำเป็น',
    validatePromoTypeRequired: 'ต้องระบุประเภทโปรโมชัน',
    validateQueryNumberRequired: 'ต้องระบุหมายเลขแบบสอบถาม',
    validateQueryRestrictionRequired: 'จำเป็นต้องมีข้อจำกัดการค้นหา',
    validateRateRequired: 'ต้องระบุอัตรา',
    validateRealNameRequired: 'จำเป็นต้องระบุชื่อจริง',
    validateReasonRequired: 'ต้องระบุเหตุผล',
    validateReasonTypeRequired: 'ต้องระบุประเภทเหตุผล',
    validateRebatePercentageRequired: 'จำเป็นต้องมีเปอร์เซ็นต์ของรีเบต',
    validateMaxRebateRequired: 'จำเป็นต้องใส่รีเบตสูงสุด',
    validateRecordTimeRequired: 'ต้องระบุเวลาบันทึก',
    validateRedirectRequired: 'จำเป็นต้องมีการเปลี่ยนเส้นทาง',
    validateReenterPasswordRequired: 'กรุณากรอกรหัสผ่านอีกครั้ง',
    validateRemarkRequired: 'จำเป็นต้องมีหมายเหตุ',
    validateRequestUrlRequired: 'ต้องระบุ URL คำขอ',
    validateResultDateRequired: 'ต้องระบุวันที่ผลลัพธ์',
    validateResultNumber: 'ต้องระบุตัวเลข 3 หลัก',
    validateRebateFormat: 'ส่วนลดจะต้องอยู่ระหว่าง 0 ถึง 1',
    validateRevenueShareFormat: 'ส่วนแบ่งรายได้ต้องอยู่ระหว่าง 0 ถึง 1',
    validateRewardGroupRequired: 'ต้องมีกลุ่มรางวัล',
    validateRewardGroupNameRequired: 'ต้องระบุชื่อกลุ่มรางวัล',
    validateRewardGroupDownlineQuotaRequired: 'จำเป็นต้องมีโควต้าดาวน์ไลน์',
    validateRewardGroupDownlineQuotaFormat: 'โควต้าดาวน์ไลน์ต้องมากกว่า 0',
    validateRiskLevelRequired: 'ต้องระบุระดับความเสี่ยง',
    validateRoleRequired: 'ต้องระบุบทบาท',
    validateRoleNameRequired: 'ต้องระบุชื่อบทบาท',
    validateRolloverRequired: 'จำเป็นต้องมีโรลโอเวอร์',
    validateRoundRequired: 'จำเป็นต้องมีรอบเกมฟรี',
    validateSchemaNameRequired: 'ต้องระบุชื่อสกีมา',
    validateSequenceRequired: 'จำเป็นต้องมีลำดับ',
    validateSiteRequired: 'กรุณาใส่เว็บไซด์',
    validateSiteCodeRequired: 'กรุณาใส่รหัสเว็บไซด์',
    validateStartTimeRequired: 'กรุณาระบุเวลาเริ่มต้น',
    validateStateRequired: 'กรุณาระบุุสถานที่',
    validateStatusRequired: 'กรุณาระบุสเตตัส',
    validateStopAfterFailureRequired: 'หยุดหากล้มเหลว',
    validateSuccess: 'ตรวจสอบความสำเร็จ',
    validateSupportTypeRequired: 'จำเป็นต้องมีประเภทการสนับสนุน',
    validateTeamNameEnRequired: 'ชื่อทีม จำเป็นต้องมีภาษาอังกฤษ',
    validateTeamNameLocalRequired: 'ต้องระบุชื่อทีมในพื้นที่',
    validateTelephoneRequired: 'กรุณาระบุหมายเลขโทรศัพท์',
    validateThirdSerialNumberRequired: 'ต้องมีหมายเลขซีเรียลที่สาม',
    validateThreeDigitsNumbeRequired: 'ต้องระบุตัวเลข 3 หลัก',
    validateTitleRequired: 'กรุณาใส่หัวข้อ',
    validateTimeTypeRequired: 'กรุณาระบุประเภทของเวลา',
    validateTransactionIdRequired: 'กรุณาระบุหมายเลข ID',
    validateTransactionStatusRequired: 'กรุณาระบุสเตตัส',
    validateTriggerTypeRequired: 'กรุณาระบุประเภทของ trgger',
    validateUrlFormat: 'กรุณากรอก URL ที่ถูกต้อง',
    validateUsernameRequired: 'กรุณาใส่ยูสเซอร์เนม',
    validateUsernameSize: 'ยูสเซอร์เนมต้องมีอักษรอย่างน้อย 6-12 ตัว',
    validateUserTypeRequired: 'กรุณาระบุประเภทของยูสเซอร์',
    validateValueRequired: 'ต้องระบุค่า',
    validateVIPRequired: 'จำเป็นต้องมีวีไอพี',
    validateWalletTypeRequired: 'ต้องระบุประเภทกระเป๋าเงิน',
    validateWayRequired: 'ระบุวิธีการ',
    validateWebMessageRequired: 'จำเป็นต้องมีข้อความเว็บ',
    validateWholeNumberOnly: 'กรุณากรอกจำนวนเต็มเท่านั้น',
    validateWithdrawExchangeRateRequired: 'จำเป็นต้องถอนอัตราแลกเปลี่ยน',
    validateWithdrawPlatformNameRequired: 'จำเป็นต้องถอนชื่อแพลตฟอร์ม',
    validateTigerCardPeriodRequired: 'ระยะเวลาที่ต้องการ',
    validateTigerCardTimeRequired: 'ระบุุเวลาหวย',
    validateTigerCardBonusTimeRequired: 'ระบุเวลาที่ได้รับ',
    validateTigerSumAwardRequired: 'จำนวนโบนัสที่ได้รับทั้งหมด',
    validateVirtualCountRequired: 'ระบุจำนวนนับ',
    validateHostRequired: 'จำเป็นต้องมีโฮสต์',
    validatePortRequired: 'จำเป็นต้องมีพอร์ต',
    validateSignNameRequired: 'ต้องระบุชื่อเข้าสู่ระบบ',
    validateSecretIdRequired: 'ระบุรหัสลับ',
    validateSecretKeyRequired: 'ระบุกุญแจลับ',
    validateAppIdRequired: 'ต้องระบุรหัสแอปพลิเคชัน',
    validateTemplateIdRequired: 'ต้องระบุรหัสเทมเพลต',
    requestExportToExcelDone1: 'คำขอถูกส่งเรียบร้อยแล้ว กรุณาคลิก',
    requestExportToExcelDone2: 'เพื่อการตรวจสอบความคืบหน้าและดาวน์โหลด',
    roleMustOnlyOne: 'โปรดเลือกบทบาทที่ต้องได้รับอนุมัติ',
  },
  menu: {
    undefined: 'ไม่ได้กำหนด',
    Dashboard: 'แดชบอร์ด',
    Member: 'สมาชิก',
    'Member List': 'รายชื่อสมาชิก',
    'Member List ID': 'รายชื่อสมาชิก',
    'Member Details': 'รายละเอียดสมาชิก',
    'Member Edit Log': 'สมาชิกแก้ไขบันทึก',
    'Member Freeze Record': 'บันทึกสมาชิกที่โดนระงับ',
    'Member Amount Adjust': 'ปรับจำนวนให่สมาชิก',
    'Member Privilege Blacklist': 'สมาชิกที่ติดแบล็คลิสของโปรโมชั่น',
    'Member Bank Card History': 'ประวัติบัตรธนาคารสมาชิก',
    'Member Sms History': 'ประวัติ SMS ของสมาชิก',
    'Import Bet Records': 'นำเข้าบันทึกการเดิมพัน',
    'Member Transfer Record': 'บันทึกการโอนสมาชิก',
    'Member VIP': 'สมาชิกวีไอพี',
    'Member Bet Record': 'บันทึกการเดิมพันของสมาชิก',
    'PG Pending Records': 'PG บันทึกที่รอดำเนินการ',
    'Member Privilege': 'สิทธิพิเศษของสมาชิก',
    'Member Rollover Record': 'บันทึกการโรลโอเวอร์ของสมาชิก',
    'Member Distribute Privilege': 'สมาชิกที่ได้รับสิทธิพิเศษ',
    'Member Affiliate Change Log': 'Member Affiliate Change Log',
    'Member Group Send SMS': 'Member Group Send SMS',
    Affiliate: 'พันธมิตร',
    'Affiliate Amount Adjust': 'ปรับจำนวนให่สมาชิก',
    'Affiliate Summary': 'สรุปพันธมิตร',
    'Affiliate Monthly Settlement': 'สรุปการชำระรายเดือนของพันธมิตร',
    'Affiliate Monthly Settlement Detail':
      'รายละเอียดการชำระบัญชีรายเดือนของพันธมิตร',
    'Affiliate Settlement': 'ค่าใช่จ่ายรายเดือนของพันธมิตร',
    'Affiliate Settlement Payment': 'สรุปรายจ่ายของพันธมิตร',
    'Affiliate Domain Management': 'การจัดการโดเมนพันธมิตร',
    'Affiliate List': 'รายชื่อพันธมิตร',
    'Affiliate Details': 'รายละเอียดพันธมิตร',
    'Affiliate Facebook Pixel Management':
      'Affiliate Facebook Pixel Management',
    'Site Affiliate Commission': 'ค่าคอมมิชชั่นพันธมิตรไซต์',
    'Deposit Management': 'การจัดการเงินฝาก',
    'Online Deposit': 'การฝากเงินออนไลน์',
    'Deposit Record': 'บันทึกการฝากเงิน',
    'Withdrawal Management': 'การจัดการการถอนเงิน',
    'Withdrawal Process': 'ดำเนินการถอนเงิน',
    'Withdrawal Affiliate Process': 'ดำเนินการถอนเงินพันธมิตร',
    'Withdrawal Record': 'บันทึกการถอนเงิน',
    'Withdrawal Affiliate Record': 'บันทึกการถอนเงินพันธมิตร',
    'Withdrawal Platform Balance': 'ถอนยอดคงเหลือแพลตฟอร์ม',
    'Withdrawal Review': 'ตรวจสอบการถอนเงิน',
    'Withdrawal Bank Blacklist': 'การถอนบัญชีดำของธนาคาร',
    'Withdraw Tips Setting': 'ถอนการตั้งค่าเคล็ดลับ',
    'Reward Group': 'กลุ่มรางวัล',
    Applying: 'ส่งดำเนินการ',
    'Affiliate Applying': 'การสมัครพันธมิตร',
    'Affiliate Under review': 'พันธมิตรอยู่ระหว่างการตรวจสอบ',
    BW1: 'BW1',
    BW2: 'BW2',
    BW3: 'BW3',
    BW4: 'BW4',
    BW5: 'BW5',
    'To be affiliate paid': 'เพื่อเป็นพันธมิตรจ่าย',
    'Affiliate Payment on going': 'กำลังดำเนินการจ่ายเงินพันธมิตร',
    'Crypto Affiliate Payment on going': 'กำลังจ่ายคลิปโตพันธมิตร',
    'Ewallet Affiliate Payment on going':
      'กำลังดำเนินการชำระเงินพันธมิตรแบบอีวอลเลท',
    'Alipay Affiliate Payment on going':
      'กำลังดำเนินการชำระเงินพันธมิตรแบบอารีเพ',
    'Affiliate Automatic Payment': 'การชำระเงินอัตโนมัติของพันธมิตร',
    'Affiliate Suspend': 'พันธมิตรที่ถูกระงับ',
    'Under review': 'อยู่ระหว่างการตรวจสอบ',
    'To be paid': 'เตรียมจ่าย',
    'Normal Payment': 'การจ่ายปกติ',
    'Crypto Paying': 'การจ่ายแบบคลิปโต',
    'Ewallet Paying': 'การจ่ายแบบอีวอลเลท',
    'Alipay Paying': 'การจ่ายแบบอารีเพล',
    'Automatic Paying': 'การจ่ายแบบอัตโนมัติ',
    Suspend: 'ระงับ',
    Promotion: 'โปรโมชั่น',
    'Privilege Info': 'ข้อมูลสิทธิพิเศษ',
    'Home Banner': 'แบนเนอร์หน้าแรก',
    'Promotion pages': 'หน้าโปรโมชั่น',
    'Edit Promo': 'แก้ไขโปรโมชั่น',
    'Multi Wheel': 'วงล้อ',
    'Multi Wheel Member List': 'รายชื่อสมาชิกลุ้นวงล้อ',
    'Multi Wheel Setting': 'การตั้งค่าวงล้อ',
    'Team Votes': 'ทีมโหวด',
    'Team Votes Records': 'บันทึกทีมโหวด',
    'Team Votes Settings': 'ตั้งค่าทีมโหวด',
    'Tiger Card': 'การ์ดไทเกอร์',
    'Telephone Numbers': 'หมายเลขโทรศัพท์',
    'Ads Popout Page': 'หน้าป๊อปเอาท์โฆษณา',
    'Add Ads Popout': 'เพิ่มโฆษณาป๊อปเอาท์',
    'Edit Ads Popout': 'แก้ไขป๊อปเอาท์โฆษณา',
    'Red Packet Rain List': 'Red Packet Rain List',
    'Member Free Game': 'Member Free Game',
    Lottery: 'หวย',
    'Lottery Record List': 'บันทึกรายการหวย',
    'Lottery Result List': 'บันทึกการผลรางวัลหวย',
    'Vietnam Lottery Record List': 'บันทึกรายการหวย',
    'Vietnam Lottery Result List': 'บันทึกการผลรางวัลหวย',
    'VIP Rebate Record': 'บันทึกรีเบทวีไอพี',
    'Bet Rebate Record': 'บันทึกการคืนเงินส่วนลดจากการพนัน',
    'Refer Friend': 'บันทึกแนะนำเพื่อน',
    'Refer Friend Rebate Record': 'บันทึกการคืนเงินส่วนลดจากการแนะนำเพื่อน',
    Gift: 'การจัดการของขวัญ',
    'Gift Info': 'ข้อมูลของขวัญ',
    'Gift Record': 'บันทึกของขวัญ',
    'Promo Application': 'แอปพลิเคชันโปรโม',
    'Site Management': 'การจัดการเว็บไซด์',
    Message: 'ข้อความ',
    Announcement: 'ประกาศ',
    'Announcement Type': 'ประเภทการประกาศ',
    'VIP Rebate': 'เงินคืน VIP',
    VIP: 'VIP',
    Game: 'เกม',
    'Game Tag': 'แท็กเกม',
    'Financial Level': 'ระดับการเงิน',
    'Risk Level': 'ระดับความเสี่ยง',
    'IP Label': 'ฉลากไอพี',
    'Site Platform Management': 'การจัดการแพลตฟอร์มไซต์',
    Permission: 'การอนุญาต',
    Menu: 'เมนู',
    Roles: 'กฎ',
    User: 'ยูสเซอร์',
    Report: 'รายงาน',
    'Privilege Record': 'บันทึกสิทธิพิเศษ',
    'Summary Report': 'สรุปรายงาน',
    'Privilege Report': 'รายงานสิทธิพิเศษ',
    'Deposit Report': 'รายงานการฝากเงิน',
    'Withdraw Report': 'รายงานการถอน',
    'Finance Report': 'รายงานทางการเงิน',
    'Affiliate Report': 'รายงานพันธมิตร',
    'Affiliate Deposit Members': 'พันธมิตรที่ี่มีการฝากเงิน',
    'Platform Game Report': 'รายงานเกมแพลตฟอร์ม',
    'Member Report': 'รายงานสมาชิก',
    'Deposit Member Detail': 'รายละเอียดการฝากเงินสมาชิก',
    System: 'ระบบ',
    'Operator Log': 'บันทึกของผู้ปฏิบัติงาน',
    'Member Platform': 'แพลตฟอร์มสมาชิก',
    Site: 'เว็บไซด์',
    Config: 'การกำหนดค่า',
    Currency: 'สกุลเงิน',
    Platform: 'แพลตฟอร์ม',
    'Site Platform': 'แพลตฟอร์มไซต์',
    Schedule: 'กำหนดการ',
    'Platform Account': 'บัญชีแพลตฟอร์ม',
    'Data Dictionary': 'พจนานุกรมข้อมูล',
    'Manual Summary': 'สรุปแบบแมนนวล',
    'Affiliate Manual Summary': 'สรุปคู่มือพันธมิตร',
    'Payment Management': 'การจัดการการชำระเงิน',
    'Payment Display List': 'รายการแสดงการชำระเงิน',
    'Bank Info': 'ข้อมูลธนาคาร',
    'Bank Card': 'บัตรธนาคาร',
    'Payment Type': 'ประเภทการชำระเงิน',
    Payment: 'การชำระเงิน',
    'Add Payment': 'เพิ่มการชำระเงิน',
    'Edit Payment': 'แก้ไขการชำระเงิน',
    CurrencyRate: 'อัตราสกุลเงิน',
    'Payment Display': 'การแสดงการชำระเงิน',
    'Withdraw Platform': 'แพลตฟอร์มการถอน',
    'Withdraw Setting': 'การตั้งค่าการถอน',
    'Site Withdraw Platform': 'แพลตฟอร์มการถอนเว็บไซต์',
    'Deposit Setting': 'ตั้้งค่าการเติมเงิน',
    'Withdraw Display': 'แสดงผลการถอน',
    'Payment Bank Management': 'การจัดการธนาคารการชำระเงิน',
    'Withdraw Platform Bank Management': 'ถอนการจัดการแพลตฟอร์มธนาคาร',
    'Summary Register Report': 'รายงานสรุปการลงทะเบียน',
    'Summary Fdp Report': 'สรุปรายงานการเติมเงินครั้งแรก',
    'Summary Deposit Report': 'สรุปรายงานการเติมเงิน',
    'Summary Withdraw Report': 'สรุปรายงานการถอนเงิน',
    'Summary Active Report': 'สรุปรายงานที่ใช้งานอยู่',
    'Summary Member Report': 'สรุปรายงานสมาชิก',
    'Finance Feedback': 'ผลตอบรับทางการเงิน',
    'Email Setting': 'ตั้งค่าอีเมล์',
    'SMS Setting': 'ตั้งค่า SMS',
    DownloadManager: 'ตัวจัดการการดาวน์โหลด',
    GoogleAuth: 'Google อัตโนมัติ',
    'User Login Log': 'บันทึกการเข้าสู่ระบบของผู้ใช้',
    'User Action Log': 'บันทึกการดำเนินการของผู้ใช้',
    'MQ Log': 'บันทึก MQ',
    'System Config': 'การกำหนดค่าระบบ',
    'Promotion Coupon': 'คูปอง',
    'Affiliate Change Log': 'บันทึกการเปลี่ยนแปลงผู้ปกครองของ Affiliate',
    'Media Display Settings': 'การตั้งค่าการแสดงสื่อ',
    'Vip Report': 'รายงานวีไอพี',
    'System Value Rules': 'กฎการตั้งค่า',
    'Sites Config': 'การตั้งค่าเว็บไซต์',
  },
  google: {
    google_auth_menu: 'ตัวรับรองความถูกต้องของกูเกิ้ล',
    download_install: 'โหลดแอพ',
    add_key: 'เพิ่มคีย์ความปลอดภัย',
    bind_verify: 'ผูก Google Auth',
    popup_title: 'ตัวรับรองความถูกต้องของ Google',
    popup_msg:
      'Google Authenticator คือเครื่องมือรหัสผ่านแบบไดนามิกที่จะรีเฟรชอัตโนมัติทุกๆ 30 วินาที เมื่อดำเนินการที่ละเอียดอ่อน เช่น การโอนเงิน ถอนเงิน หรือปรับการตั้งค่าความปลอดภัยในแบ็กเอนด์ของพร็อกซี คุณจะต้องป้อนรหัสยืนยันตัวตน 6 หลักนี้สำหรับการตรวจสอบสิทธิ์',
    popup_hint: 'เครื่องยืนยันตัวตนจะต้องใช้ร่วมกับบัญชีพร็อกซี',
    i_know: 'รับทราบ',
    download_way: 'วิธีการดาวน์โหลด：',
    download_through_way: '1. ผ่านทางลิงค์ดาวน์โหลด：',
    ios_download: 'ดาวน์โหลด IOS',
    android_download: 'ดาวน์โหลดแอนดรอย',
    ios_scan_download: 'สแกนดาวน์โหลด IOS',
    android_scan_download: 'สแกนดาวน์โหลด Android',
    if_cannot_download_you_can:
      '2. หากคุณไม่สามารถดาวน์โหลดได้ คุณสามารถค้นหาได้ใน Apple App Store"',
    or_search_in_google: '", หรือค้นหาใน Android App Store.',
    download_and_install: '" ดาวน์โหลดและติดตั้ง。',
    next_step: 'ถัดไป',
    keyin_pass: 'ป้อนรหัสผ่าน & ถัดไป',
    keyin_your_password: 'กรอกรหัสผ่านเข้าสู่ระบบ',
    tips_installed:
      'เคล็ดลับด่วน: เมื่อดาวน์โหลดและติดตั้งแล้ว เพียงคลิก "ถัดไป" เพื่อดำเนินการต่อ',
    qr_code: 'คิวอาร์โค้ด',
    secret_key: 'รหัสลับ',
    add_step: 'ขั้นตอนในการเพิ่ม',
    add_step_desc:
      'เปิด Google Authenticator คลิกที่เครื่องหมาย "+" ที่มุมล่างขวา เลือก "ป้อนรหัสด้วยตนเอง" และป้อนบัญชีใดก็ได้เพื่อผูกเข้ากับรหัสลับที่กล่าวมาข้างต้น (การสแกนรหัส QR สามารถเพิ่มได้โดยอัตโนมัติ)',
    check_example: 'ดูตัวอย่าง',
    back: 'กลับ',
    small_tips: 'เคล็ดลับด่วน:',
    small_tips_info_1:
      '1. หากคุณทำโทรศัพท์หายหรือถอนการติดตั้งแอปการตรวจสอบสิทธิ์ รหัสนี้สามารถช่วยคุณกู้คืนเครื่องตรวจสอบสิทธิ์ได้ กรุณาเก็บไว้อย่างปลอดภัย.',
    small_tips_info_2:
      '2. เพื่อความปลอดภัยของบัญชีของคุณ โปรดอย่าทำเครื่องหมายบัญชีพร็อกซีและที่อยู่แบ็กเอนด์พร็อกซีในระหว่างกระบวนการผูกมัด.',
    keyin_6_digit_google: 'กรุณากรอกรหัสรับรองความถูกต้อง 6 หลักของ Google',
    auth_code: 'เครื่องรับรองความถูกต้องของ Google',
    bind_now: 'ผูกตอนนี้',
    please_enter_password: 'กรุณาใส่รหัสผ่านของคุณ',
    added_success: 'เพิ่มรหัส Google Authenticator สำเร็จแล้ว',
    if_not_google_auth_then:
      'หากคุณไม่ได้ผูก Google Authenticator ก็ไม่จำเป็นต้องกรอก',
    google_auth_code: 'กรอกรหัส Google Authenticator 6 หลัก',
    register_count: 'จำนวนผู้ลงทะเบียนวันนี้',
    register_limit: 'จำนวนที่สามารถลงทะเบียนวันนี้',
  },
  loginDevice: {
    WEB: 'WEB',
    H5: 'H5',
    ANDROID: 'ANDROID',
    IOS: 'IOS',
  },
  result: {
    win: 'WIN',
    draw: 'DRAW',
    loss: 'LOSS',
  },
  freeze: {
    NORMAL: 'Normal',
    TEMPORARY: 'Temporary',
    PERMANENT: 'Permanent',
    UNFREEZE: 'Unfreeze',
  },
  domainType: {
    LANDING: 'หน้า Landing Page',
    DOWNLOAD: 'หน้าดาวน์โหลด',
    AFFILIATE: 'การแสดง',
    HOME: 'หน้าแรก',
  },
  error: {
    400: 'คำขอไม่ถูกต้อง',
    401: 'คำขอที่ไม่ได้รับอนุญาต',
    403: 'ต้องห้าม',
    404: 'ไม่พบข้อมูล',
    405: 'วิธีการไม่ได้รับอนุญาต',
    500: 'ระบบขัดข้อง',
    501: 'รหัสคำขอซ้ำ',
    502: 'ไม่พบเซิร์ฟเวอร์',
    504: 'คำขอบ่อยเกินไป',
    601: 'ข้อผิดพลาดในการตรวจสอบโทเค็น',
    602: 'โทเค็นหมดอายุแล้ว',
    603: 'โทเค็นสูนหาย',
    604: 'บัญชีเข้าสู่ระบบแล้ว',
    605: 'บัญชีออกจากระบบแล้ว',
    606: 'บทบาทที่เลือกได้รับการมอบหมายให้กับผู้ใช้แล้ว โปรดยกเลิกการมอบหมายบทบาทนี้ก่อนที่จะลองอีกครั้ง',
    607: 'ไซต์ต้องไม่ว่างเปล่าเมื่อคลาสผู้ใช้ไม่ใช่ผู้ดูแลไซต์',
    700: 'รหัสผ่านเก่าและใหม่ต้องไม่เหมือนกัน',
    701: 'มีพารามิเตอร์อยู่แล้ว',
    707: 'ยอดคงเหลือไม่เพียงพอ',
    800: 'ข้อผิดพลาดในการยืนยัน Captcha',
    801: 'รหัสแคปต์ชาหมดอายุแล้ว',
    900: 'ไม่พบรายละเอียดสมาชิก',
    901: 'ไม่พบบันทึกพันธมิตร',
    922: 'บัญชีของคุณยังไม่ได้เชื่อมต่อกับ Vcall',
    1000: 'การเข้าสู่ระบบล้มเหลว ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
    1001: 'การเข้าสู่ระบบล้มเหลว บัญชีถูกปิดใช้งาน',
    1005: 'การเข้าสู่ระบบล้มเหลว บัญชีถูกล็อค โปรดลองอีกครั้งในวันพรุ่งนี้',
    1006: 'การเข้าสู่ระบบล้มเหลว ไม่มีบัญชีอยู่',
    1007: 'ส่งคำสั่งถอนเงินแล้ว โปรดลองอีกครั้งในอีก 20 นาที',
    1008: 'คุณได้ผูกมัด Google Authenticator แล้ว โปรดป้อนรหัสยืนยัน Google',
    1009: 'ป้อนรหัสยืนยัน Google ไม่ถูกต้อง',
    1100: 'ผู้ดูแลระบบถูกจำกัดไม่สามารถเข้าสู่ระบบได้',
    1101: 'Login account does not belongs to this site',
    1102: 'บัญชีเข้าสู่ระบบไม่ได้เป็นของเว็บไซต์นี้',
    9000: 'ไม่สามารถสร้างงานที่กำหนดเวลาไว้ได้',
    9001: 'ไม่สามารถอัปเดตงานที่กำหนดเวลาไว้ได้',
    9002: 'ไม่สามารถลบงานที่กำหนดเวลาไว้',
    10009: 'ป้อนเงื่อนไขการค้นหาอย่างน้อย 1 รายการ',
    10010: 'มีชื่อประเภทการประกาศของระบบอยู่แล้ว',
    10011: 'มีชื่อประกาศของระบบอยู่แล้ว',
    10012: 'ไม่มีประเภทการประกาศของระบบ',
    10013: 'มีการใช้ประเภทประกาศโดยประกาศที่มีอยู่ กรุณาดำเนินการลบประกาศที่มีอยู่',
    10020: 'มีชื่อแบนเนอร์อยู่แล้ว',
    10021: 'ไม่พบรหัสแบนเนอร์',
    10030: 'มีชื่อโปรโมชันอยู่แล้ว',
    10031: 'ไม่พบรหัสส่งเสริมการขาย',
    10032: 'ประเภทไฟล์รูปภาพไม่ถูกต้อง',
    10033: 'การอัปโหลดแบนเนอร์รูปภาพล้มเหลว',
    10034: 'การอัปโหลดรูปภาพโปรโมชั่นล้มเหลว',
    1004: 'คุณได้เพิ่ม Google Authenticator แล้ว กรุณากรอกรหัส Google Auth ของคุณ',
    10040: 'มีชื่อระดับความเสี่ยงของระบบอยู่แล้ว',
    10041: 'มีสีระดับความเสี่ยงของระบบอยู่แล้ว',
    10050: 'มีป้ายกำกับ IP ของระบบอยู่แล้ว',
    10051: 'ไม่มีป้ายกำกับ IP ของระบบ',
    10110: 'แพลตฟอร์มดึงข้อมูลข้อผิดพลาดธุรกรรมที่ค้างอยู่',
    10111: 'การอัปเดตแพลตฟอร์มมีข้อผิดพลาดในการทำธุรกรรมที่รอดำเนินการ',
    11000: 'ไม่มีพันธมิตรอยู่',
    11006: 'ค่าคอมมิชชันพันธมิตรไซต์มีอยู่แล้ว',
    11007: 'ไม่มีค่าคอมมิชชันพันธมิตรไซต์',
    11008: 'ไม่มีบันทึก Affiliate',
    11009: 'ค่าคอมมิชชั่นพันธมิตรจะต้องมากกว่าค่าคอมมิชชั่นพันธมิตรย่อย',
    11010: 'พันธมิตรผู้อ้างอิงไม่สามารถมีพันธมิตรดาวน์ไลน์ได้',
    12000: 'มีชื่อข้อมูลสิทธิพิเศษของระบบอยู่แล้ว',
    12001: 'มีรหัสข้อมูลสิทธิพิเศษของระบบอยู่แล้ว',
    12002: 'กรุณาใส่กลุ่มสิทธิ์',
    12010: 'มีชื่อสกุลเงินอยู่แล้ว',
    12011: 'อัตราสกุลเงินที่กำหนดไว้แล้วสำหรับไซต์นี้',
    12020: 'สถานะของบันทึกปัจจุบันไม่ได้กำลังส่ง',
    12030: 'ชื่อไซต์มีอยู่แล้ว',
    12031: 'รหัสไซต์มีอยู่แล้ว',
    12032: 'ไซต์นี้เป็นไซต์แม่',
    12033: 'ไม่สามารถเปลี่ยนแปลงไซต์แม่ได้',
    12034: 'ชื่อการชำระเงินมีอยู่แล้ว',
    12035: 'รหัสการชำระเงินอยู่แล้ว',
    12036: 'ไม่มีข้อมูลสิทธิพิเศษของระบบ',
    12037: 'ไม่มีรหัสการชำระเงินของระบบ',
    12038: 'จำนวนเงินมากกว่าโบนัสสูงสุด',
    12039: 'สิทธิของสมาชิกอยู่ในบัญชีดำแล้ว',
    12040: 'ไม่มีชื่อการชำระเงินของระบบ',
    12041: 'Mall Name already exist',
    12042: 'Mall Code already exist',
    12050: 'ธนาคารไม่มีอยู่ในระบบ',
    12051: 'ชื่อธนาคารมีอยู่แล้วในระบบ',
    12052: 'รหััสธนาคารมีอยู่แล้วในระบบ',
    12053: 'มีรหัสระบุบัตรธนาคารของระบบอยู่',
    12054: 'บัตรธนาคารของระบบมีอยู่แล้ว',
    14000: 'ชื่อเข้าสู่ระบบมีอยู่แล้ว',
    14001: 'มีโทรศัพท์อยู่แล้ว',
    14002: 'มีอีเมลอยู่แล้ว',
    14003: 'ไม่มีรหัสพันธมิตร',
    14004: 'รหัส Affiliate ไม่ได้อยู่ในไซต์นี้',
    14005: 'ค่าคอมมิชชั่นพันธมิตรจะต้องน้อยกว่าค่าคอมมิชชั่นพันธมิตรที่เหนือกว่า',
    14006: 'รายได้ของพันธมิตรจะต้องน้อยกว่ารายได้ของพันธมิตรที่เหนือกว่า',
    14007: 'สถานะการลงทะเบียนพันธมิตรยังอยู่ระหว่างการพิจารณา',
    14008: 'ค่าคอมมิชชั่นพันธมิตรต้องน้อยกว่าค่าคอมมิชชั่นพันธมิตรที่เหนือ',
    14009: 'รายได้ของพันธมิตรต้องน้อยกว่ารายได้ของพันธมิตรที่เหนือ',
    14100: 'พันธมิตรนี้ไม่มีสมาชิกดาวน์ไลน์นี้',
    14101: 'พันธมิตรนี้ไม่มีสิทธิ์ในการดำเนินการนี้',
    14110: 'แพลตฟอร์มและไซต์ที่มีชื่อเดียวกันมีอยู่แล้ว',
    14111: 'สถานะต้องเป็นปิดเป็นระยะเวลาหนึ่งเดือนก่อนที่จะลบ',
    14112: 'สถานะของแพลตฟอร์มไซต์นี้ในสถานะปิดไม่ถึงหนึ่งเดือน กรุณาลบหลังจากหนึ่งเดือน',
    14120: 'ระดับทางการเงิน, สกุลเงิน, ไซต์, และอุปกรณ์ที่มีชื่อเดียวกันมีอยู่แล้ว',
    14121: 'จำนวนถอนขั้นต่ำต้องไม่น้อยกว่า 0',
    14122: 'จำนวนเงินถอนสูงสุดต้องไม่น้อยกว่า 0',
    14123: 'เวลาถอนสูงสุดต้องไม่น้อยกว่า 0',
    14124: 'จำนวนถอนสูงสุดรายวันต้องไม่น้อยกว่า 0',
    14125: 'จำนวนถอนขั้นต่ำต้องไม่มากกว่าจำนวนถอนสูงสุด',
    14126: 'จำนวนเงินถอนสูงสุดต้องไม่น้อยกว่าจำนวนถอนขั้นต่ำ',
    14127: 'จำนวนถอนสูงสุดต้องต่อครั้งต้องไม่เกินจำนวนถอนสูงสุดรายวัน',
    14130: 'ไม่มีบัญชีแพลตฟอร์ม',
    14131: 'บัญชีแพลตฟอร์มถูกใช้โดยแพลตฟอร์มไซต์อื่น',
    15000: 'ชื่อ VIP มีอยู่แล้วบนเว็บไซต์',
    15001: 'VIP ไม่มีอยู่',
    15002: 'VIP ถูกใช้โดย VIP ที่มีอยู่แล้ว ไม่สามารถลบได้',
    15003: 'กฎการคืนเงิน VIP มีอยู่สำหรับระดับ VIP และประเภทเกมที่กำหนด',
    15004: 'ไม่มีกฎการคืนเงิน VIP',
    15005: 'การคืนเงิน VIP ถูกจ่ายไปแล้ว',
    15010: 'มีชื่อระดับทางการเงินอยู่แล้วบนไซต์',
    15012: 'ไม่มีระดับทางการเงิน',
    15020: 'ไม่มีบันทึกการฝากเงิน',
    15021: 'ระดับทางการเงิน, ประเภทการจ่ายเงิน, ไซต์, และวิธีที่มีชื่อเดียวกันมีอยู่แล้ว',
    15030: 'ไม่สามารถประมวลผลข้อมูล JSON',
    15040: 'ถอน ชื่อที่แสดง มีอยู่แล้ว',
    15041: 'ไม่มีการแสดงการถอนเงิน',
    15050: 'มีธนาคารอยู่แล้วในการชำระเงินที่กำหนด',
    15051: 'มีรหัสอยู่แล้วในการชำระเงินที่กำหนด',
    15060: 'ไม่มีรหัสแพลตฟอร์มการถอน',
    15061: 'มีธนาคารอยู่แล้วในแพลตฟอร์มการถอนที่กำหนด',
    15062: 'มีรหัสอยู่แล้วในแพลตฟอร์มการถอนที่กำหนด',
    15063: 'ไม่มีบันทึกการถอนเงิน',
    15064: 'ไม่มีแพลตฟอร์มการถอนเงิน',
    15065: 'ไม่มีบัตรนี้',
    15066: 'ชื่อแพลตฟอร์มการถอนเงินมีอยู่แล้ว',
    16000: 'ต้องระบุหมายเลขหลัก 3',
    16001: 'หมายเลขควรเป็นตัวเลข 3 หลัก',
    16002: 'ประกาศผลรางวัลสลากกินแบ่งสำหรับวันที่เลือก',
    16010: 'ทีมภาษาอังกฤษมีชื่ออยู่แล้ว',
    16011: 'ไม่มีทีมนี้',
    17010: 'ไม่มีบันทึกการลงคะแนน',
    17011: 'บันทึกการลงคะแนนเสียงถูกยกเลิกแล้ว',
    18000: 'โดเมนมีอยู่แล้ว',
    18001: 'ไม่มีโดเมนของ Affiliate นี้',
    18002: 'Facebook Pixel does not exist',
    18003: 'This affiliate already set facebook pixel',
    19000: 'ธนาคารอยู่ในบัญชีดำแล้ว',
    19101: 'มีชื่อโฆษณาอยู่แล้ว',
    19102: 'ไม่พบรหัสโฆษณา',
    19103: 'โฆษณาสำหรับไซต์เปิดอยู่แล้ว โปรดปิดโฆษณาที่มีอยู่',
    20000: 'การตั้งค่า SMS มีอยู่แล้วบนเว็บไซต์',
    20100: 'การตั้งค่าอีเมลมีอยู่แล้วบนเว็บไซต์',
    20201: 'ประเภทไม่ถูกต้อง',
    20202: 'กรุณาใส่ ‘正常’ / ‘冻结’ ',
    20203: 'ข้อผิดพลาดรูปแบบวันเกิด (ปปปป-ดด-วว)',
    20204: 'ไม่พบระดับความเสี่ยง โปรดระบุระดับความเสี่ยงที่ถูกต้อง(เช่น：1)',
    20205: 'ไม่พบระดับทางการเงิน โปรดใส่ระดับทางการเงินที่ถูกต้อง (เช่น：1)',
    20206: 'กรุณาใส่ ’测试‘ / ’普通‘ / ’三方‘ / ’代理‘',
    20400: 'ไม่มีบันทึกแบบโรลโอเวอร์',
    21000: 'ไม่มีเกมนี้',
    21100: 'ตั๋วสำหรับสมาชิกคนนี้ได้ถึงขีดจำกัดตั๋วสูงสุดแล้ว',
    21101: 'ไม่พบลูกกลิ้งหลายล้อสำหรับไซต์นี้',
    30000: 'การอัปเดตสถานะธุรกรรม PG ล้มเหลว',
  },
}
