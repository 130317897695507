<template>
  <div class="roles-main">
    <div class="header-container" style="margin-bottom: 40px">
      <div class="search">
        <el-select
          v-model="request.siteId"
          size="small"
          :placeholder="t('fields.site')"
          class="filter-item"
          style="width: 100px;"
          default-first-option
          @focus="loadSites"
          @change="changeSite"
        >
          <el-option
            v-for="item in siteList.list"
            :key="item.id"
            :label="item.siteName"
            :value="item.id"
          />
        </el-select>
        <el-input
          v-model="request.memberName"
          size="small"
          style="width: 150px;margin-left: 5px"
          :placeholder="t('fields.memberName')"
        />
        <el-input
          v-model="request.telephone"
          size="small"
          style="width: 150px;margin-left: 5px"
          :placeholder="t('fields.telephone')"
        />
        <el-select
          clearable
          v-model="request.freezeType"
          size="small"
          :placeholder="t('fields.freezeType')"
          class="filter-item"
          style="width: 150px;margin-left: 5px"
        >
          <el-option
            v-for="item in freezeType.list"
            :key="item.key"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <el-input
          v-model="request.createBy"
          size="small"
          style="width: 150px;margin-left: 5px"
          :placeholder="t('fields.operator')"
        />
        <el-date-picker
          style="margin-left: 5px"
          format="DD/MM/YYYY"
          value-format="YYYY-MM-DD"
          v-model="request.createTime"
          size="small"
          type="daterange"
          range-separator=":"
          :start-placeholder="t('fields.startDate')"
          :end-placeholder="t('fields.endDate')"
          :shortcuts="shortcuts"
          :disabled-date="disabledDate"
          :editable="false"
          :clearable="false"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadFreezeRecords"
        >
          {{ t('fields.search') }}
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="warning"
          @click="resetQuery()"
        >
          {{ t('fields.reset') }}
        </el-button>
      </div>
    </div>
    <el-table
      :data="page.records"
      v-loading="page.loading"
      ref="table"
      row-key="id"
      size="small"
      highlight-current-row
      style="width: 100%;margin: 20px;"
      :empty-text="t('fields.noData')"
    >
      <el-table-column
        prop="memberName"
        :label="t('fields.memberName')"
        width="250"
      />
      <el-table-column
        prop="freezeType"
        :label="t('fields.freezeType')"
        width="250"
      >
        <template #default="scope">
          <el-tag
            v-if="scope.row.freezeType === 'NORMAL'"
            type="warning"
            size="mini"
          >
            {{ t('freeze.NORMAL') }}
          </el-tag>
          <el-tag
            v-else-if="scope.row.freezeType === 'TEMPORARY'"
            type="danger"
            size="mini"
          >
            {{ t('freeze.TEMPORARY') }}
          </el-tag>
          <el-tag
            v-else-if="scope.row.freezeType === 'PERMANENT'"
            type="danger"
            size="mini"
            effect="dark"
          >
            {{ t('freeze.PERMANENT') }}
          </el-tag>
          <el-tag
            v-else-if="scope.row.freezeType === 'UNFREEZE'"
            type="success"
            size="mini"
          >
            {{ t('freeze.UNFREEZE') }}
          </el-tag>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="reason" :label="t('fields.reason')" width="250" />
      <el-table-column
        prop="createTime"
        :label="t('fields.createTime')"
        width="250"
      >
        <template #default="scope">
          <span v-if="scope.row.createTime === null">-</span>
          <!-- eslint-disable -->
          <span
            v-if="scope.row.createTime !== null"
            v-formatter="{
              data: scope.row.createTime,
              timeZone: timeZone,
              type: 'date',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column prop="remark" :label="t('fields.remark')" width="250" />
      <el-table-column prop="createBy" :label="t('fields.operator')" />
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import moment from 'moment'
import { onMounted, reactive } from 'vue'
import { getFreezeRecords } from '../../../api/freeze'
import { useI18n } from 'vue-i18n'
import { getSiteListSimple } from '../../../api/site'
import { getShortcuts } from '@/utils/datetime'
import { formatInputTimeZone } from '@/utils/format-timeZone'

const { t } = useI18n()
const page = reactive({
  pages: 0,
  records: [],
  loading: false,
})

const shortcuts = getShortcuts(t)

const startDate = new Date()
startDate.setDate(startDate.getDate() - 2)
const defaultStartDate = convertDate(startDate)
const defaultEndDate = convertDate(new Date())
let timeZone = null

const request = reactive({
  size: 30,
  current: 1,
  memberName: null,
  freezeType: null,
  createBy: null,
  siteId: 0,
  createTime: [defaultStartDate, defaultEndDate],
})

const siteList = reactive({
  list: [],
})

const freezeType = reactive({
  list: [
    { key: 1, name: t('freeze.NORMAL'), value: 'NORMAL' },
    { key: 2, name: t('freeze.TEMPORARY'), value: 'TEMPORARY' },
    { key: 3, name: t('freeze.PERMANENT'), value: 'PERMANENT' },
    { key: 4, name: t('freeze.UNFREEZE'), value: 'UNFREEZE' },
  ],
})

function resetQuery() {
  request.memberName = null
  request.freezeType = null
  request.createBy = null
  request.createTime = []
  request.siteId = siteList.list[0].id
}

async function loadSites() {
  const { data: site } = await getSiteListSimple()
  siteList.list = site
}

function convertDate(date) {
  return moment(date).format('YYYY-MM-DD')
}

function disabledDate(time) {
  return (
    time.getTime() <
      moment(new Date())
        .subtract(2, 'months')
        .startOf('month')
        .format('x') || time.getTime() > new Date().getTime()
  )
}

async function loadFreezeRecords() {
  page.loading = true
  const requestCopy = { ...request }
  const query = {}
  Object.entries(requestCopy).forEach(([key, value]) => {
    if (value) {
      query[key] = value
    }
  })
  timeZone = siteList.list.find(e => e.id === request.siteId).timeZone
  if (request.createTime.length === 2) {
    query.createTime = JSON.parse(JSON.stringify(request.createTime))
    query.createTime[0] = formatInputTimeZone(
      query.createTime[0],
      timeZone,
      'start'
    )
    query.createTime[1] = formatInputTimeZone(
      query.createTime[1],
      timeZone,
      'end'
    )
    query.createTime = query.createTime.join(',')
  }
  const { data: ret } = await getFreezeRecords(query)
  page.pages = ret.pages
  page.records = ret.records
  page.loading = false
}

function changePage(page) {
  request.current = page
  loadFreezeRecords()
}

onMounted(async () => {
  await loadSites()
  request.siteId = siteList.list[0].id
  loadFreezeRecords()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}
</style>
