export const UserActionTypes = {
  ACTION_LOGIN: 'ACTION_LOGIN',
  ACTION_RESET_TOKEN: 'ACTION_RESET_TOKEN',
  ACTION_GET_USER_INFO: 'ACTION_GET_USER_INFO',
  ACTION_LOGOUT: 'ACTION_LOGOUT',
  ACTION_UPDATE_LOGIN: 'ACTION_UPDATE_LOGIN',
  ACTION_CHANGE_SITE_ID: 'ACTION_CHANGE_SITE_ID',
  ACTION_UPDATE_TOKEN: 'ACTION_UPDATE_TOKEN',
}

export const ADMIN = {
  name: 'ADMIN',
  value: 'ADMIN',
}

export const MANAGER = {
  name: 'System Manager',
  value: 'MANAGER',
}

export const TENANT = {
  name: 'Site Manager',
  value: 'TENANT',
}

export const SUB_TENANT = {
  name: 'Subsite Manager',
  value: 'SUB_TENANT',
}
